import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup, FormControlName, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { maxLength8 } from '../custom-form-validation';

@Component({
  selector: 'b-form-input-field',
  templateUrl: './form-input-field.component.html',
  styleUrls: ['./form-input-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormInputFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() translateHint;
  @Input() controlName: string;
  @Input() formName: string;
  @Input() arrayName: string;
  @Input() type = 'text';
  @Input() translatePlaceholder;
  @Input() showError = true;
  @Input() disabled = false;
  @Input() mask;
  @Input() fg: FormGroup; // form group will be preferred way going forward, facility form needs to be changed
  @Input() showMaskTyped = false;
  @Input() step: string;
  @Input() maxLength;
  @Input() suffix = '';
  @Input() prefix = '';
  @Input() dropSpecialCharacters = true;
  @Input() tip: string = null;
  @Input() maxNumber: number;
  @Input() pattern: string;
  @Input() inputId: string;
  @Input() currency = false;
  @Input() useProfileItemDosage = false;
  @Input() useWholeNumber = false;
  @Input() allowNegativeNumbers = false;
  @Input() readonly = false

  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();

  errorMessage: string;

  formControl: AbstractControl;

  constructor(
    private form: FormGroupDirective,
    public translate: TranslateService) {
  }

  ngOnInit() {
    if (this.useProfileItemDosage) {
    }
    if (!this.mask && this.type === 'number') {
      this.type = null;
      this.mask = '0*';
    }

    if (this.arrayName) {
      this.formControl = this.form.form.get(this.arrayName).get(this.formName.toString()).get(this.controlName);
    } else if (this.fg) {
      if (this.fg instanceof FormArray) {
        this.formControl = this.fg.get([Number(this.controlName)]);
      } else {
        this.formControl = this.fg.get(this.controlName);
      }
    } else {
      this.formControl = this.form.form.get(this.formName).get(this.controlName);
    }
  }

  getPlaceholder() {
    if (this.translatePlaceholder) {
      return this.translate.instant('TEXT.' + this.translatePlaceholder);
    }
    if (this.placeholder) {
      return this.placeholder
    }
    return null;
  }

  getHint() {
    if (this.translateHint) {
      return this.translate.instant('TEXT.' + this.translateHint);
    }
    if (this.hint) {
      return this.hint
    }
    return null;
  }


  getErrorMessage() {
    const errorType = Object.keys(this.formControl.errors)[0];

    if (errorType === 'min') {
      return 'Must be greater than ' + (this.formControl.errors.min.min - 1);
    }
    else if (errorType === 'mask') {
      return 'Please enter a number formatted with ' + this.mask.length + " digits"
    }
    return this.translate.instant('ERROR.' + errorType, { value: '4' });
  }

}
