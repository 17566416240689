import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'm-packing-validation-dialog',
  templateUrl: './packing-validation-dialog.component.html',
  styleUrls: ['./packing-validation-dialog.component.scss']
})
export class PackingValidationDialogComponent {
  report: any;
  messages: string[];

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PackingValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
    var report = JSON.parse(config.report);

    if(Array.isArray(report)) {
      this.messages = report;
    } else {
      this.report = report;
    }
    console.log("report === ", this.report)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onExport(exportType: number) {
    console.log("rep ==  ", this.report.missingBarcodes);
    if (exportType === 1) {
      // missingUnitCosts: []
      // maximumDrugsPerBlister: []
      // customMaximumPacks: []
      // invalidPackedAlone: null

      var doc = new jsPDF('p', 'pt');

      var hasStarted = false;

      if (this.report.missingBarcodes.length > 0) {
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Missing Barcodes', 40, 40);
        doc.setFontSize(11);

        doc.text('The following drugs require a barcode.', 40, 70);

        var columns = {
          drugName: 'Drug',
          packSize: 'Pack Size',
        }

        autoTable(doc, {
          startY: 80,
          head: [columns],
          body: this.report.missingBarcodes,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.invalidConsumables.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Invalid Consumables', 40, 40);
        doc.setFontSize(11);

        doc.text('The following patient/tray consumable types are invalid. Please go to Profile > Tray', 40, 70);

        var invalidConsumableColumns = {
          name: 'Patient',
          packName: 'Tray',
          cardFormatName: 'Current Consumable'
        }

        autoTable(doc, {
          startY: 80,
          head: [invalidConsumableColumns],
          body: this.report.invalidConsumables,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.maximumDrugsPerPack.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Maximum Drugs Per Pack', 40, 40);
        doc.setFontSize(11);

        doc.text('The following patient/trays exceed the maximum number of drugs per tray', 40, 70);

        var rows = this.report.maximumDrugsPerPack.map(m => ({ ...m, name: m.firstName + ' ' + m.lastName }));

        var maximumDrugsPerPackColumns = {
          name: 'Patient',
          packName: 'Tray',
          drugCount: 'Number of Meds',
          maximumPerPack: 'Max Number of Meds'
        }

        autoTable(doc, {
          startY: 80,
          head: [maximumDrugsPerPackColumns],
          body: rows,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.maximumDrugsPerBlister.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Maximum Drugs Per Blister', 40, 40);
        doc.setFontSize(11);

        doc.text('The following patient/trays exceed the maximum number of drugs per blister', 40, 70);

        var rows = this.report.maximumDrugsPerBlister;

        var maximumDrugsPerBlisterColumns = {
          patient: 'Patient',
          packName: 'Tray',
          slotNumber: 'Slot',
          rowNumber: 'Row',
          drugCount: 'Meds/Blister',
          maxPerBlister: 'Max Meds/Blister'
        }

        autoTable(doc, {
          startY: 80,
          head: [maximumDrugsPerBlisterColumns],
          body: rows,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.customMaximumPacks.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Custom Dosage - Max Number of Lines', 40, 40);
        doc.setFontSize(11);

        doc.text('A custom dosage has caused the following patient/trays to exceed the maximum number of lines', 40, 70);
        doc.text('available on the label.', 40, 80);

        var rows = this.report.customMaximumPacks;

        var customMaximumPacksColumns = {
          patient: 'Patient',
          packName: 'Tray',
          linesRequired: 'Lines Required',
          maximumLines: 'Maximum Lines',
        }

        autoTable(doc, {
          startY: 90,
          head: [customMaximumPacksColumns],
          body: rows,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.invalidPackedAlone.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Invalid Packed Alone', 40, 40);
        doc.setFontSize(11);

        doc.text('The following patient/trays contain drugs that should be packed alone. Please go to Profile > Tray.', 40, 70);

        var rows = this.report.invalidPackedAlone;

        var invalidPackedAloneColumns = {
          patient: 'Patient',
          packName: 'Tray',
        }

        autoTable(doc, {
          startY: 80,
          head: [invalidPackedAloneColumns],
          body: rows,
          rowPageBreak: 'avoid',
        });
      }

      if (this.report.missingUnitCosts.length > 0) {
        if(hasStarted)  {
          doc.addPage();
        }
        hasStarted = true;

        doc.setFontSize(22);
        doc.text('Missing Unit Costs', 40, 40);
        doc.setFontSize(11);

        doc.text('The following drugs require a unit cost.', 40, 70);

        var rows = this.report.missingUnitCosts.map(u => ({drug: u[0].ContainerBrandName, packSize: u[0].PackSize}));

        var missingUnitCostsColumns = {
          drug: 'Drug',
          packSize: 'Pack Size',
        }

        autoTable(doc, {
          startY: 80,
          head: [missingUnitCostsColumns],
          body: rows,
          rowPageBreak: 'avoid',
        });
      }
      doc.save('Validation_Report.pdf');
    }

    else if (exportType === 2) {
      // let tableRows = [];

      // for (var i = 0; i < this.reportTables.length; i++) {
      //   let table = this.reportTables[i];
      //   let reportRows = table.rows;

      //   var tableColumns = this.selectedReport.columns.map(c => ('"' + this.translate.instant('TEXT.' + c.header) + '"'));;

      //   tableRows.push([table.header]);

      //   tableRows.push(tableColumns);
      //   for (var j = 0; j < reportRows.length; j++) {
      //     let newRow = [];
      //     let row = reportRows[j];

      //     for (var k = 0; k < this.selectedReport.columns.length; k++) {
      //       let column = this.selectedReport.columns[k];
      //       newRow.push('"' + row[column.key].display + '"')
      //     }

      //     tableRows.push(newRow);
      //   }

      //   tableRows.push([""]);
      //   tableRows.push([""]);

      //   console.log("NEW TABLE");
      //   console.log("COLUMNS == ", tableColumns);
      //   console.log("ROWS == ", tableRows);

      //   // tableRows = [...tableColumns, ...tableRows];
      // }

      //     let csvContent = "data:text/csv;charset=utf-8," + tableRows.map(row => row.map(value => value = ('"' + value + '"'))).join("\n");


      // saveAs(csvContent, this.selectedReport.name.replace(" ", "_") + ".csv");
    }
  }
}

