import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { config } from '../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardInitalised = new Subject<any>();
  menuStatsUpdated = new BehaviorSubject<any>({});
  notesUpdated = new Subject<any>();
  notesLoading = new Subject<any>();
  noteEntitySelected = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  initialiseDashboard = (): Observable<any> => this.http.get(config.apiUrl + 'dashboard')
  // initialiseDashboard(callback) {
  //   this.http.get<any>(config.apiUrl + 'dashboard').subscribe(data => {
  //     this.dashboardInitalised.next(data)
  //     callback()
  //   });
  // }

  updateMenuStats() {
    this.http.get<any>(config.apiUrl + 'dashboard/menuStats').subscribe(data => {
      this.menuStatsUpdated.next(data)
    });
  }

  updatePackingStats = (values): Observable<any> => this.http.post(config.apiUrl + 'dashboard/packingStats', values)

  updateAudits = (range): Observable<any> => this.http.get(config.apiUrl + 'dashboard/audit/' + range)

  find = (searchText): Observable<any> => this.http.get(config.apiUrl + 'dashboard/find/' + searchText)

}