import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  constructor() { }

  export = (headers: any[], fields: any[], data: any[], filename: string) => {
    // let csv = columns.map(column => column.map(value => value = ('"' + value + '"'))).join('\r\n')

    const csv = `${[headers.map(h => `"${h}"`).join(','), ...data.map(res => fields.map(f => `"${res[f]}"`).join(','))].join('\n')}`;
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
    saveAs(blob, `${filename}.csv`)
  }
}
