import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { PageEvent, Sort } from "@angular/material";
import moment from "moment";

@Component({
	selector: "m-data-table",
	template: "",
	styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit {
	allData: any[] = []; // The entire data source sorted by sort key
	filteredData: any[] = []; // The entire data source filtered depending on filter state
	displayData: any[] = []; // The selection of data source to display in the table depending on paging state

	pageEvent: PageEvent;
	pageIndex = 0;
	length: number = 0;
	pageSize = 10;

	loading = false;

	usePaging = true;

	filters: any = {};
	sortKey = '';
	sortDirection = 'asc';

	constructor(public cdRef: ChangeDetectorRef) {}

	ngOnInit() {}

	public onPage(event?: PageEvent) {
		this.pageIndex = event.pageIndex;
		this.pageSize = event.pageSize;
		this.prepareData();
		return event;
	}

	public prepareData() {
		if (this.usePaging) {			
			this.displayData = this.filteredData.slice(
				this.pageIndex * this.pageSize,
				this.pageIndex * this.pageSize + this.pageSize
			);
		} else {
			this.displayData = this.allData.slice();
		}
		if (!this.cdRef["destroyed"]) {
			this.cdRef.detectChanges();
		}
	}

	// table sort

	sortData(sort: Sort) {
		if (sort) {
			this.sortKey = sort.active;
			this.sortDirection = sort.direction;
		}

		this.allData = this.allData.sort((a, b) => {
			return this.compare(a[this.sortKey], b[this.sortKey], this.sortDirection === 'asc');
		});
		this.filterData();
	}

	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	onFilter(value, key) {
		if (value == null) {
			console.log('null')
			delete this.filters[key];
		}
		else {
			if (typeof value === "string" || value instanceof String) {
				console.log('string')
				if (value === "") {
					delete this.filters[key];
				} else {
					this.filters[key] = value;
				}
			} else if (moment.isDate(value) || moment.isMoment(value)) {				
				this.filters[key] = value;
			} else if (typeof value === 'boolean' || value instanceof Boolean) {			
				this.filters[key] = value;
			} else if (typeof value === 'number' || value instanceof Number) {
				
				this.filters[key] = value;
			} else {				
				if (value.find((v) => v === -1) || value.length === 0) {
					delete this.filters[key];
				} else {
					this.filters[key] = value;
				}
			}
		}

		this.filterData();
	}

	filterData() {
		this.filteredData = [...this.allData];
		Object.keys(this.filters).map((filterName) => {
      const filterValue = this.filters[filterName];
      const type = moment.isDate(filterValue) || moment.isMoment(filterValue) ? 'date' : typeof filterValue;
      switch (type) {
        case "string":
          this.filteredData = this.filteredData.filter((m) => m[filterName] && m[filterName].toLowerCase().includes(filterValue.toLowerCase()));
          break;
				case "boolean":
        case "number":
          this.filteredData = this.filteredData.filter((m) => m[filterName] === filterValue);
          break;
        case 'date':
          this.filteredData = this.filteredData.filter(m => moment(filterValue).startOf('day').isSame( moment(m[filterName]).startOf('day')))
        default:
          break;
      }
    });
		this.prepareData();
	}
	
}
