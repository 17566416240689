import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config/app.config';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient, public fb: FormBuilder) { }

  submit = (values) => this.http.post<any>(config.apiUrl + 'support/send', values)

  getSupportForm = () => this.fb.group({
    name: new FormControl('', Validators.required),
    contact: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^\\S+@\\S+\\.\\S+$')])),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required)
  })

}
