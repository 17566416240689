import { Component, OnInit, ViewContainerRef, ViewChild, Inject, ComponentRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'm-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef }) vcRef: ViewContainerRef;
  componentRef: ComponentRef<any>;
  form: FormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
  }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.config.component);
    this.componentRef = this.vcRef.createComponent(factory);
    // Input to dynamic component
    //this.componentRef.instance.inputVarHere = [1, 2, 3];
    // Handles output event, just emit your output here

    this.form = this.componentRef.instance.form;

    console.log("form == ", this.form)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
