import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PatientsService } from '../../../../core/services/patients.service';
import { TranslateService } from '@ngx-translate/core';
import { NoteService } from '../../../../core/services/note.service';
import { EntityService } from '../../../../core/services/entity.service';
import { UserService } from '../../../../core/services/user.service';
import { Storage } from 'aws-amplify';
import { ChangeRequestService } from '../../../../core/services/change-request.service';
import { Subject } from 'rxjs';
import { LoadingDialogService, LoadingDialogState } from '../../../../core/services/loading-dialog.service';
import { FormPhotoInputFieldComponent } from '../../form/form-photo-input-field/form-photo-input-field.component';
const uuidv1 = require('uuid/v1');

@Component({
  selector: 'm-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss']
})
export class NoteFormComponent implements OnInit {
  @ViewChild('fileInput') fileInput: FormPhotoInputFieldComponent;
  loading = false;
  uploading = false;
  progressChanged = new Subject<any>();


  progress = 0;

  form: FormGroup;
  contact: any;
  file: any;

  entityOptions = [];
  entityName: string;

  isNew = true;
  isChangeRequest = false;

  note: any;

  title: string;

  //@Output() onFormSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<NoteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
    private service: NoteService,
    private entityService: EntityService,
    private changeRequestService: ChangeRequestService,
    public translate: TranslateService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingDialogService
  ) {
    this.config = config;
  }

  ngOnInit() {
    this.progressChanged.subscribe(prog => {
      this.progress = prog;
      this.cdRef.detectChanges();
    })

    if (this.config.note) {
      this.isNew = false;
      this.entityName = this.config.entityName;
      this.note = this.config.note;
    }
    if (this.config.entity) {
      this.entityOptions = [{ name: this.config.entity.name, value: this.config.entity.value }];
    }

    this.isChangeRequest = this.config.isChangeRequest;

    this.form = new FormGroup({
      text: new FormControl(this.note ? this.note.text : null, Validators.required),
      entityId: new FormControl(this.config.entity ? this.config.entity.value : null),
    })

    if (this.isChangeRequest) {
      this.title = 'Change Request'
      this.form.controls.entityId.setValidators([Validators.required]);

    } else {
      this.title = this.isNew ? 'New Note' : 'Edit Note';
    }

    if (!this.isNew || this.config.entity) {
      this.form.controls.entityId.disable();
    }

    if (this.config.entity) {
      this.form.controls.entityId.setValue(this.config.entity.value);
    }
  }

  searchTextChanged(text: string) {
    if (text) {
      this.entityService.autocomplete({
        text,
        patientOnly: this.isChangeRequest
      }).subscribe(entities => {
        this.entityOptions = entities.map(entity => ({ name: entity.name, value: entity.entityId }))

      })
    } else {
      this.entityOptions = [];
    }
  }

  autoCompleteDisplay = entityId => {
    if (entityId) {
      this.entityName = this.entityOptions.find(entity => entity.value === entityId).name;
      return this.entityName;
    } else {
      return '';
    }
  };

  mcAutoCompleteDisplay = mcId => '';

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileChange(file) {
    this.file = file;
  }

  submitNote() {
    if (this.form.invalid) {
      (<any>Object).values(this.form.controls).forEach(control => control.markAsTouched())
    } else {
      if (this.file) {
        this.uploadNoteFile();
      } else {
        if (this.isChangeRequest) {
          this.saveChangeRequest();
        } else {
          this.saveNote(null);
        }
      }

    }
  }

  uploadNoteFile() {
    this.uploading = true;
    const fileId = uuidv1()
    var sub = this.progressChanged;

    Storage.put(fileId + '.pdf', this.file, {
      contentType: 'application/pdf',
      progressCallback(progress) {
        var percentage = progress.loaded / progress.total;
        sub.next(percentage * 100);
      },
    })
      .then(result => {
        if (this.isChangeRequest) {
          this.saveChangeRequest(fileId);
        } else {
          this.saveNote(fileId)
        }
      })
      .catch(err => {
        this.loading = false;
        this.uploading = false;
        this.file = null;
        this.fileInput.reset();
        this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', 'There was an error saving the attachment.  Please try the upload again.  If the issue persists please contact support.');
      });

  }

  saveNote(fileId?: string) {
    this.uploading = false;
    this.loading = true;

    let fileName: string;
    if (this.entityName) {
      fileName = this.entityName.split(' ').join('_') + '_' + Math.floor(Date.now() / 1000).toString() + '.pdf'
    } else {
    //   fileName = this.userService.getSession().pharmacyName.split(' ').join('_') + '_' + Math.floor(Date.now() / 1000).toString() + '.pdf'
    }

    var values = {
      ...this.form.getRawValue(),
      noteId: this.note ? this.note.noteId : undefined,
      fileId,
      fileName
    }
    this.service.saveNote(values).subscribe((note: any) => {
      if (note.error) {
        this.loading = false;
        this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', note.message);
      } else {
        this.service.onNoteUpdated.next(note);
        this.dialogRef.close();
      }
    })
  }

  saveChangeRequest(fileId?: string) {
    this.uploading = false;
    this.loading = true;
    let fileName: string;
    if (this.entityName) {
      fileName = this.entityName.split(' ').join('_') + '_' + Math.floor(Date.now() / 1000).toString() + '.pdf'
    } else {
    //   fileName = this.userService.getSession().pharmacyName.split(' ').join('_') + '_' + Math.floor(Date.now() / 1000).toString() + '.pdf'
    }

    var values = {
      ...this.form.value,
      noteId: this.note ? this.note.noteId : undefined,
      fileId,
      fileName
    }

    this.changeRequestService.createChangeRequest(values).subscribe((change: any) => {
      if (change.error) {
        this.loading = false;
        this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', change.message);
      } else {
        this.loading = false;
        this.changeRequestService.changeRequestSaved.next();
        this.dialogRef.close();
      }
    })
  }
}
