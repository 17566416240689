import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'b-image',
  templateUrl: './b-image.component.html',
  styleUrls: ['./b-image.component.scss']
})
export class BImageComponent implements OnInit, OnChanges {
  @Input() photoId;
  @Input() size = 'small'
  @Input() folder = 'photo'
  imgUrl: string;

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUrl();
  }

  getUrl() {
    Storage.get(this.folder + '/' + this.size + '/' + this.photoId + '.png', { level: 'public' })
    .then ( (result) => { 
      console.log('img result', result); 
      this.imgUrl = result.toString();
      this.cdRef.detectChanges();
    })
    .catch( (err) => { 
      console.log(err);
    });
  }
}
