import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'b-form-date-field',
  templateUrl: './form-date-field.component.html',
  styleUrls: ['./form-date-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormDateFieldComponent implements OnInit {

  @Input() placeholder: string;
  @Input() hint: string;
  @Input() controlName: string;
  @Input() formName: string;
  @Input() arrayName: string;
  @Input() type = 'text';
  @Input() translatePlaceholder;
  @Input() min: Date;
  @Input() required = false;

  @Input() fg: FormGroup;

  @Input() disabled = false;

  errorMessage: string;

  formControl;

  constructor(
    private form: FormGroupDirective,
    public translate: TranslateService) {
  }

  ngOnInit() {
    if (this.arrayName) {
      this.formControl = this.form.form.get(this.arrayName).get(this.formName.toString()).get(this.controlName);
    } else {
      if (this.fg) {
        this.formControl = this.fg.controls[this.controlName];
      } else {
        this.formControl = this.form.form.get(this.formName).get(this.controlName);
      }
    }
  }

  getPlaceholder() {
    if (this.translatePlaceholder) {
      return this.translate.instant('TEXT.' + this.translatePlaceholder);
    }
    if (this.placeholder) {
      return this.placeholder
    }
    return null;
  }

  // getErrorMessage() {
  //   const errorType =  Object.keys(this.formControl.errors)[0];
  //   return this.translate.instant('ERROR.' + errorType, {value: '4'});

  // }

  getErrorMessage(pickerInput: string): string {
    if (this.formControl.errors.matDatepickerMin) {
      return 'Must be after ' + moment(this.min).format('DD/MM/YYYY')
    }

    if (this.formControl.errors.reportEndDate) {
      return 'Must be after start date'
    }
    if (this.required &&!pickerInput || this.required && pickerInput === '') {
      return "Value is required";
    }
    return this.isMyDateFormat(pickerInput);
  }

  isMyDateFormat(date: string): string {
    // const da = date.split('/');
    // if (da.length !== 3 || da[0].length !== 2 || da[1].length !== 2 || da[2].length !== 2) {
    //   return 'Invalid date, use format DD-MM-YYYY';
    // }

    // if (date.length !== 10) {
    //   return 'Invalid date, use format DD-MM-YYYY';
    // } else {
    // }

    if (!(moment(date,['DD/MM/YYYY']).isValid())) {
      return 'Invalid date, use format DD-MM-YYYY';
    }
    const errorType = Object.keys(this.formControl.errors)[0];
    return this.translate.instant('ERROR.' + errorType, { value: '4' });
  }
}

