import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { config } from 'aws-sdk/global';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'm-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss']
})
export class ImageCropDialogComponent implements OnInit{
  imageSrc: any;
  croppedImage: any;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
    ) {
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("CROP EVENT ++ ", event);
    this.croppedImage = event;
  }

  ngOnInit() {
    this.imageSrc = this.config.imageSrc;
    console.log("CONFIG SRC === ", this.imageSrc)
  }

  imageLoaded(event){
    console.log("EV2 == ", event)
  }
  cropperReady(event){
    console.log("EV3 == ", event)
  }
  loadImageFailed(event){
    console.log("EV4 == ", event)
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  onSave() {
    this.dialogRef.close({ image:  this.croppedImage});
  }
}
