import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserIdleDialogComponent } from './user-idle-dialog.component';
import { MatButtonModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { FormComponentModule } from '../../form/form-component.module';

@NgModule({
  imports: [
    CommonModule,
    FormComponentModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    UserIdleDialogComponent
  ],
  entryComponents: [
    UserIdleDialogComponent,
  ],
  exports: [
    UserIdleDialogComponent
  ]
})
export class UserIdleDialogModule { }
