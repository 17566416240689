import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { RoleDetailComponent } from "./pages/role-detail/role-detail.component";
import { RoleListComponent } from "./pages/role-list/role-list.component";
import {
  MatButtonModule,
  MatTableModule,
  MatIconModule,
  MatSortModule,
  MatPaginatorModule,
  MatChipsModule,
  MatTabsModule,
  MatBadgeModule,
  MatRadioModule,
} from "@angular/material";
import { PortletModule } from "../../partials/content/general/portlet/portlet.module";
import { DataTableModule } from "../../shared/dataTable/data-table.module";
import { FormComponentModule } from "../../shared/form/form-component.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { RoleRoutingModule } from "./role-routing.module";
import { SwitchModule } from "../../shared/switch/switch.module";
import { RoleSelectorComponent } from './pages/role-selector/role-selector.component';
import { CoreModule } from "../../../core/core.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { FormsModule } from "@angular/forms";
// import { RoleEditComponent } from './components/role-edit/role-edit.component';
// import { RoleNewComponent } from './pages/role-new/role-new.component';

@NgModule({
  imports: [
    RoleRoutingModule,
    CommonModule,
    CoreModule,
    FormsModule,
    MatRadioModule,
    NgxPermissionsModule,
    PortletModule,
    DataTableModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    FormComponentModule,
    MatChipsModule,
    MatTabsModule,
    PerfectScrollbarModule,
    MatBadgeModule,
    SwitchModule
  ],
  declarations: [
    RoleListComponent,
    RoleSelectorComponent,
    // RoleDetailComponent,
    // RoleEditComponent,
    // RoleNewComponent
  ],
})
export class RoleModule { }
