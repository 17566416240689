import { DrugClasses } from './drug-classes/drug-classes';
import { DrugSubclasses } from './drug-classes/drug-subclasses';

export const options = {
  TRAINING_STATUSES: [
    { value: 1, name: 'Published' },
    { value: 2, name: 'Draft' }
  ],
  INFOBLAST_CATEGORIES: [
    { value: 1, name: 'Prescriber' },
    { value: 2, name: 'Education' },
    { value: 3, name: 'Medicine' },
  ],
  INFOBLAST_STATUSES: [
    { value: 1, name: 'Published' },
    { value: 2, name: 'Draft' }
  ],
  SCOOP_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Complete' }
  ],
  USER_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' }
  ],
  USER_DISPLAY_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' },
    { value: 3, name: 'Pending' },
    { value: 4, name: 'Pending' },
    { value: 5, name: 'Pending' },
    { value: 6, name: 'Pending Confirmation' }
  ],
  RESIDENT_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' },
    { value: 3, name: 'Withdrew Consent' },
    { value: 4, name: 'Deceased' },
    { value: 5, name: 'In Hospital' },
    { value: 6, name: 'Moved' },
  ],
  DELIVERY_METHODS: [
    { value: 1, name: 'Email' },
    { value: 2, name: 'Fax' }
  ],
  FOLLOWUP_TYPES: [
    { value: 0, name: 'None' },
    { value: 1, name: 'First' },
    { value: 2, name: 'Second' },
  ],
  DELIVERY_TARGETS: [
    { value: 1, name: 'Doctor' },
    { value: 2, name: 'Practice Manager' },
    { value: 3, name: 'Both' },
  ],
  REFERRAL_LETTER_TEMPLATE: [
    { value: 1, name: 'Multiple Patients' },
    { value: 2, name: 'Single Patient' }
  ],
  GENDERS: [
    { value: 1, name: 'Unknown' },
    { value: 2, name: 'Male' },
    { value: 3, name: 'Female' },
    { value: 4, name: 'Other' }
  ],
  MEDEREV_USER_TYPES: [
    { value: 1, name: 'Mederev Staff' },
    { value: 2, name: 'Mederev Pharmacist' },
    { value: 3, name: 'Super User' },
  ],
  ALL_USER_TYPES: [
    { value: 1, name: 'Mederev Staff' },
    { value: 2, name: 'Mederev Pharmacist' },
    { value: 3, name: 'Super User' },
    { value: 10, name: 'Facility Manager' },
    { value: 11, name: 'Facility Clinical Manager' },
    { value: 12, name: 'Facility Staff' },
    { value: 13, name: 'Facility Receptionist' },
    { value: 14, name: 'Clinical Educator/Quality User' },
    { value: 15, name: 'Facility Admin' },
    { value: 20, name: 'Practice Manager' },
    { value: 21, name: 'Practice Receptionist' },
    { value: 22, name: 'Practice Doctor' },
  ],
  PRACTICE_USER_TYPES: [
    { value: 20, name: 'Practice Manager' },
    { value: 21, name: 'Practice Receptionist' },
    // { value: 22, name: 'Practice Doctor' }
  ],
  FACILITY_USER_TYPES: [
    { value: 15, name: 'Facility Admin' }, // was Facility Admin
    { value: 10, name: 'Facility Manager' },
    { value: 11, name: 'Facility Clinical Manager' },
    { value: 12, name: 'Facility Staff' },
    { value: 13, name: 'Facility Receptionist' },
    { value: 14, name: 'Clinical Educator' }
  ],
  FACILITY_USER_TYPES_LIMITED: [
    { value: 12, name: 'Facility Staff' },
    { value: 13, name: 'Facility Receptionist' },
  ],
  FACILITY_NOTE_TYPES: [
    { value: 1, name: 'Note' },
    { value: 2, name: 'MAC Minutes' },
    { value: 3, name: 'Action Plan' },
    { value: 4, name: 'Other Document' }
  ],
  STATES: [
    { value: 'VIC', name: 'VIC' },
    { value: 'NSW', name: 'NSW' },
    { value: 'TAS', name: 'TAS' },
    { value: 'QLD', name: 'QLD' },
    { value: 'SA', name: 'SA' },
    { value: 'NT', name: 'NT' },
    { value: 'WA', name: 'WA' },
    { value: 'ACT', name: 'ACT' },
  ],
  FACILITY_TYPES: [
    { value: 1, name: 'Nursing home' },
    { value: 2, name: 'Hospital' },
    { value: 3, name: 'Community' },
    { value: 4, name: 'Prison' },
    { value: 5, name: 'Special Accomodation' },
  ],
  DAYS: [
    { value: 1, name: 'Monday' },
    { value: 2, name: 'Tuesday' },
    { value: 4, name: 'Wednesday' },
    { value: 8, name: 'Thursday' },
    { value: 16, name: 'Friday' },
    { value: 32, name: 'Saturday' },
    { value: 64, name: 'Sunday' },
  ],

  PROFILE_DRUG_DAYS: [
    { value: 1, name: 'Monday' },
    { value: 2, name: 'Tuesday' },
    { value: 3, name: 'Wednesday' },
    { value: 4, name: 'Thursday' },
    { value: 5, name: 'Friday' },
    { value: 6, name: 'Saturday' },
    { value: 0, name: 'Sunday' },
  ],

  MOMENT_DAYS: [
    { value: 1, name: 'Monday' },
    { value: 2, name: 'Tuesday' },
    { value: 3, name: 'Wednesday' },
    { value: 4, name: 'Thursday' },
    { value: 5, name: 'Friday' },
    { value: 6, name: 'Saturday' },
    { value: 7, name: 'Sunday' },
  ],
  FILL_TYPES: [
    { value: 1, name: '7 Day Multidose' },
    { value: 2, name: '28 Day Multidose ' },
    { value: 4, name: '7 Day Unitdose' },
    { value: 6, name: '14 Day Multidose' },
    { value: 7, name: '7 Day Multidose Bottom Up' },
  ],
  CARD_FORMAT_TYPES: [
    { value: 1, name: 'Bifold 7x4' },
    { value: 2, name: 'Trifold 7x4' },
    { value: 3, name: 'Bifold Upside Down 7x4' },
    { value: 4, name: 'Sachet' },
  ],
  CARD_FORMATS: [
    { value: 1, name: 'Standard (Bifold) heat seal with out foil ' },
    { value: 2, name: 'Standard (Bifold) heat seal with foil' },
    { value: 3, name: 'Standard (Bifold) heat seal with foil (Bifold Upside Down 7x4)' },
    { value: 4, name: 'Standard (Bifold) cold seal' },
    { value: 5, name: 'Info (Trifold) cold seal' },
    { value: 6, name: 'Info (Trifold) heat seal with foil' },
    { value: 7, name: 'Info (Trifold) heat seal without foil ' },
    { value: 8, name: 'Info (Trifold) heat seal perforated without foil' },
    { value: 9, name: 'Barrys Card' },
    { value: 10, name: 'JVM Sachet' },
  ],
  LABEL_FORMATS: [
    { value: 1, name: 'Entries Required' }
  ],
  PACKING_RUN_ORDERS: [
    { value: 1, name: 'Facility/Unit Name, Patient Surname' },
    { value: 2, name: 'Facility/Unit Name, Room Number' },
    { value: 3, name: 'Patient Surname' },
    { value: 4, name: 'Optimized' },
  ],
  PACKING_CYCLES_WEEK: [
    { value: 1, name: '1 (x 7 days)' },
    { value: 2, name: '2 (x 7 days)' },
    { value: 3, name: '3 (x 7 days)' },
    { value: 4, name: '4 (x 7 days)' },
    { value: 5, name: '5 (x 7 days)' },
    { value: 6, name: '6 (x 7 days)' },
  ],
  PACKING_CYCLES_FORTNIGHT: [
    { value: 1, name: '1 (x 14 days)' },
    { value: 2, name: '2 (x 14 days)' },
    { value: 3, name: '3 (x 14 days)' },
    { value: 4, name: '4 (x 14 days)' },
    { value: 5, name: '5 (x 14 days)' },
    { value: 6, name: '6 (x 14 days)' },
  ],

  PACKING_CYCLES_MONTH: [
    { value: 1, name: '1 (x 28 days)' },
    { value: 2, name: '2 (x 28 days)' },
    { value: 3, name: '3 (x 28 days)' },
    { value: 4, name: '4 (x 28 days)' },
    { value: 5, name: '5 (x 28 days)' },
    { value: 6, name: '6 (x 28 days)' },
  ],

  PACKING_MANIFEST_ORDERS: [
    { value: 1, name: 'Patient Surname' },
    { value: 2, name: 'Unit, Room, Bed' },
  ],
  STARTING_SLOTS: [
    { value: "1", name: '1' },
    { value: "2", name: '2' },
    { value: "3", name: '3' },
    { value: "4", name: '4' },
  ],
  BED_TYPES: [
    { value: 1, name: 'High Care' },
    { value: 2, name: 'Low Care' },
  ],
  PROFILE_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' },
    { value: 3, name: 'Hospital' },
    { value: 4, name: 'Deceased' },
    { value: 5, name: 'Respite' },
    { value: 6, name: 'On Holiday' },
    { value: 7, name: 'Moved Away' },
    { value: 8, name: 'Late Payment' },
    { value: 9, name: 'Non Pickup' },
    { value: 10, name: 'Unreviewed' },
    { value: 11, name: 'Casual' },
  ],

  HUB_FORMULARY_STATUSES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' },
  ],

  TITLE_TYPES: [
    { value: 0, name: 'Unknown' },
    { value: 1, name: 'Mr' },
    { value: 2, name: 'Dr' },
    { value: 3, name: 'Mrs' },
    { value: 4, name: 'Ms' },
    { value: 5, name: 'Miss' },
  ],
  SEXES: [
    { value: 0, name: 'Unknown' },
    { value: 1, name: 'Male' },
    { value: 2, name: 'Female' },
  ],
  SELECTIONS: [
    { value: 0, name: 'No Selection' },
    { value: 1, name: 'Yes' },
    { value: 2, name: 'No' },
  ],
  MONTHS: [
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
    { value: 4, name: '4' },
    { value: 5, name: '5' },
    { value: 6, name: '6' },
    { value: 7, name: '7' },
    { value: 8, name: '8' },
    { value: 9, name: '9' },
    { value: 10, name: '10' },
    { value: 11, name: '11' },
    { value: 12, name: '12' },
  ],
  UNKNOWN: [
    { value: 0, name: 'Unknown Options' }
  ],
  HEALTH_PROFESSIONAL_TYPES: [
    { value: 1, name: 'General Practitioner' },
    { value: 2, name: 'Surgeon' },
    { value: 3, name: 'Specialist' },
    { value: 4, name: 'Physiotherapist' },
    { value: 5, name: 'Dentist' },
    { value: 6, name: 'Podiatrist' },
    { value: 7, name: 'Acupuncturist' },
    { value: 8, name: 'Dietitian' },
    { value: 9, name: 'Chiropractor' },
    { value: 10, name: 'District Nurse' },
    { value: 11, name: 'Optometrist' },
    { value: 12, name: 'Other' },
    { value: 14, name: 'Nurse Practitioner' },
  ],
  ALLERGY_TYPES: [
    { value: 0, name: 'None' },
    { value: 1, name: 'Adverse drug reaction' },
    { value: 2, name: 'Allergic reaction' },
    { value: 3, name: 'Drug interaction' },
    { value: 4, name: 'Food intolerance' },
    { value: 5, name: 'Medication side-effect' },
    { value: 6, name: 'Non-allergic reaction' },
    { value: 7, name: 'Toxicity' },
  ],
  CARD_COLOURS: [
    { value: 1, name: 'Blue' },
    { value: 2, name: 'Green' },
    { value: 3, name: 'Mustard' },
    { value: 4, name: 'Orange' },
    { value: 5, name: 'Pink' },
    { value: 6, name: 'Purple' },
    { value: 7, name: 'White' },
    { value: 8, name: 'Yellow' },
    { value: 9, name: 'Red' },
  ],
  PACK_RUN_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Packed' },
    { value: 3, name: 'Checked' },
    { value: 4, name: 'Delivered' },
    { value: 5, name: 'Voided' },
    { value: 6, name: 'Downloaded' },
    { value: 10, name: 'Delivered to spoke' },
  ],
  PACK_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Packed' },
    { value: 3, name: 'Checked' },
    { value: 4, name: 'Delivered' },
    { value: 5, name: 'Voided' },
    { value: 6, name: 'Downloaded' },
    { value: 7, name: 'Ready for Delivery' },
    { value: 8, name: 'Dispatched' },
    { value: 9, name: 'Received' },
    { value: 10, name: 'Delivered to spoke' },
    { value: 11, name: 'Packing' },
    { value: 12, name: 'Returned' },
  ],

  SCRIPT_TYPES: [
    { value: 0, name: 'Select' },
    { value: 1, name: 'Last Repeat - Authority' },
    { value: 2, name: 'Last Repeat' },
    { value: 3, name: 'Owing' },
    { value: 4, name: 'Owing - Authority' },
    { value: 5, name: 'New Script Required' },
    { value: 6, name: 'New Authority Script Required' },
    { value: 7, name: 'No Scripts Left' },
  ],
  DISPENSE_SCRIPT_TYPES: [
    { value: 1, name: 'Owing' },
    { value: 2, name: '1 Repeat' },
    { value: 3, name: 'No Repeats' },
  ],

  DRUG_TYPES: [
    { value: 1, name: 'Standard' },
    { value: 2, name: 'Custom' },
  ],
  SUPPLY_METHODS: [
    { value: 1, name: 'Deliver' },
    { value: 2, name: 'Pickup' },
    { value: 3, name: 'Pickup (3rd Party)' },
  ],
  RELATIONSHIP_TYPES: [
    { value: 3, name: 'Father' },
    { value: 4, name: 'Father in law' },
    { value: 5, name: 'Mother' },
    { value: 6, name: 'Mother in law' },
    { value: 7, name: 'Brother' },
    { value: 8, name: 'Brother in law' },
    { value: 9, name: 'Sister' },
    { value: 10, name: 'Sister in law' },
    { value: 11, name: 'Son' },
    { value: 12, name: 'Son in law' },
    { value: 13, name: 'Daughter' },
    { value: 14, name: 'Niece' },
    { value: 15, name: 'Nephew' },
    { value: 16, name: 'Uncle' },
    { value: 17, name: 'Aunt' },
    { value: 18, name: 'Husband' },
    { value: 19, name: 'Wife' },
    { value: 20, name: 'Friend' },
    { value: 21, name: 'De facto' },
    { value: 22, name: 'Carer' },
    { value: 23, name: 'None' },
    { value: 24, name: 'Daughter in law' },
    { value: 25, name: 'Grand daughter' },
    { value: 26, name: 'Grand son' },
    { value: 27, name: 'Case Worker' },
    { value: 28, name: 'Interpreter' },
  ],
  DOSAGE_TYPES: [
    { value: 1, name: 'Daily' },
    { value: 2, name: 'Set Days' },
    { value: 3, name: 'Every x Days' },
    { value: 4, name: 'Alternate' },
    { value: 5, name: 'Monthly (28 Days)' },
    { value: 6, name: 'Dosage days' },
    { value: 7, name: 'Custom pattern' },
    { value: 8, name: 'Every x Months' },
    { value: 9, name: 'Monthly (Set Day)' },
    { value: 10, name: 'Monthly (Set Date)' },
    { value: 11, name: 'Monthly Except (Set Day)' },
    { value: 12, name: 'Monthly Except (Set Date)' },
    { value: 13, name: 'Monthly (Set Dates)' },
  ],
  PACK_CATEGORIES: [
    { value: 1, name: 'Regular' },
    { value: 2, name: 'Short term' },
    { value: 4, name: 'S8' },
    { value: 8, name: 'S11' },
    { value: 16, name: 'Variable dose' },
    { value: 32, name: 'Insulin' },
    { value: 64, name: 'Nutritional suppliment' },
  ],
  MONTH_DAYS: [
    { value: 1, name: '1st' },
    { value: 2, name: '2nd' },
    { value: 3, name: '3rd' },
    { value: 4, name: '4th' },
    { value: 5, name: '5th' },
    { value: 6, name: '6th' },
    { value: 7, name: '7th' },
    { value: 8, name: '8th' },
    { value: 9, name: '9th' },
    { value: 10, name: '10th' },
    { value: 11, name: '11th' },
    { value: 12, name: '12th' },
    { value: 13, name: '13th' },
    { value: 14, name: '14th' },
    { value: 15, name: '15th' },
    { value: 16, name: '16th' },
    { value: 17, name: '17th' },
    { value: 18, name: '18th' },
    { value: 19, name: '19th' },
    { value: 20, name: '20th' },
    { value: 21, name: '21th' },
    { value: 22, name: '22th' },
    { value: 23, name: '23th' },
    { value: 24, name: '24th' },
    { value: 25, name: '25th' },
    { value: 26, name: '26th' },
    { value: 27, name: '27th' },
    { value: 28, name: '28th' },
  ],
  UNIT_OF_MEASURES: [
    { value: '6c56a5cf-5d90-4fd3-ab23-01b1ff033079', name: 'Patch' },
    { value: '49335303-05ed-4b4a-8e16-06c1bd1e1978', name: 'Prefilled Pen' },
    { value: '5d122029-704e-49a0-bdaf-129d91bb3d40', name: 'Nebule' },
    { value: '8b40d8df-ac9d-4585-8c18-156f5064d4fc', name: 'Diagnostic strip' },
    { value: '0c688a97-8f25-4a23-b104-1600ea50d4d9', name: 'Roll' },
    { value: 'f6a002e1-3394-4b8f-9e99-19432c813756', name: 'Microgram (mcg)' },
    { value: 'cedebeed-acba-4149-9dbe-1ad324ceb12e', name: 'Ampoule' },
    { value: '0dd64539-672f-4cf3-b6c4-1b4f3a9b89e9', name: 'Tablespoon' },
    { value: '537211b0-28b6-4430-a6ac-1ba5ad40ba94', name: 'Pack' },
    { value: 'bad57220-4aa6-4c27-8037-1db0f8503a12', name: 'Square cm' },
    { value: '1045dbf5-baf3-474f-91ce-1dc7534d28fa', name: 'Unit dose' },
    { value: 'fa931417-bc86-4e01-bee4-1f387938f113', name: 'Metered dose' },
    { value: 'ed044efc-f9d2-4cf3-a6da-203a017b034d', name: 'Millilitre (ml)' },
    { value: '878a87ce-164f-410b-a4f9-251140fda27b', name: 'Inhalation' },
    { value: '6631c452-e1ba-47c2-afd8-26ce96936449', name: 'Milligram (mg)' },
    { value: '0cc18a78-8d3e-4e4e-bfe4-369d53b453a6', name: 'Capsule' },
    { value: 'd0c1e8c0-b7e3-4129-92c5-383ac35a4e63', name: 'Strip' },
    { value: 'e14d0747-ba7a-4716-ac46-394a88f08091', name: 'Lozenge' },
    { value: '815955fe-3ffe-47eb-b042-3e6ad5f48995', name: 'Pad' },
    { value: 'd642d753-8a13-4e3b-a7a4-3fa90fc91ea9', name: 'Dressing' },
    { value: '992b1a21-ebeb-43b1-b84a-4201cc8d8560', name: 'Unit' },
    { value: 'e0235fb2-f656-44d6-b702-45b29b4bc401', name: 'Dose' },
    { value: '7931afce-72c6-486b-b1b0-4dd2fe1fecd2', name: 'mmHg' },
    { value: '77d54f53-9bd7-4557-a8c0-50572c5eabe0', name: 'Centimetre (cm)' },
    { value: '540c3a6e-6ef5-4e43-b109-54c0d814bced', name: 'Vial' },
    { value: '70e80316-2002-4c73-8c3f-5c0878c0834b', name: 'Applicator' },
    { value: '45f18be4-66bb-4de8-89d7-6063b1800f57', name: 'Drop' },
    { value: 'c002d71a-a07d-4462-bc21-67e24032326e', name: 'Wafer' },
    { value: '71365665-6192-44f7-b489-6af4e6a1bc9c', name: 'Gram (g)' },
    { value: 'd245cdb1-e498-4539-9c8b-7200311e3975', name: 'Teaspoon' },
    { value: 'e2ca21b8-dbee-4006-b318-7591a88715bb', name: 'Sachet' },
    { value: 'ad64fccc-fcc8-4246-9d6a-76439daeef8e', name: 'Application' },
    { value: 'b700a39d-de15-4ed9-9f18-7ebc968fe938', name: 'Film' },
    { value: '6db9ca7e-763c-4959-8d88-81818eaf399e', name: 'Cartridge' },
    { value: '900aabf3-b062-4f9b-bc6f-831e0f6fb4b5', name: 'Enema' },
    { value: 'ff2e5ce2-78d4-4e24-8d76-8e671b52c755', name: 'Tablet' },
    { value: 'dd3f8a61-26cd-4e2a-b386-972d32836944', name: 'Measure' },
    { value: '29e1190f-c2f9-473f-9e36-a8f9f56eec24', name: 'Millimetre (mm)' },
    { value: 'f5ff1033-4d8c-4663-aa5f-ac70fa659420', name: 'Implant' },
    { value: 'da352457-d7a7-4316-ab6f-af0e93518bcb', name: 'International unit (IU)' },
    { value: 'b97cacf6-482b-452e-8eaa-b625e56b3a53', name: 'Litre (L)' },
    { value: '8e3a1ecf-b0da-43f7-a69a-b67679207512', name: 'Pessary' },
    { value: '28953c27-4543-4002-9935-bb28c0b43feb', name: 'mmol/L' },
    { value: '8bfa5941-44cb-4d5c-afc7-bcc4a87e96d6', name: 'Prefilled syringe' },
    { value: 'dc28b159-9012-4b99-a3ce-bd466aee29d0', name: 'Kilogram (kg)' },
    { value: 'b0625807-fb24-4e2b-a311-c2d915816c64', name: 'Suppository' },
    { value: '2e396299-8484-4c5f-862a-cbf992f89542', name: 'Scoop' },
    { value: '9fb27211-66bc-4c2c-9959-d39dbc51243a', name: 'Sheet' },
    { value: '0c0b6226-71e5-4208-8c01-d720105a46b5', name: 'Actuation' },
    { value: 'a661d00b-54f8-45a3-926c-d83c6b46dd01', name: 'Syringe' },
    { value: '3c441ea5-1b92-4edb-ac92-e4cb89a9c343', name: 'Stick' },
    { value: '98d85592-e8b4-4af8-b3b8-e5a9e0dcda2d', name: 'Litres/Min' },
    { value: '45631e4c-9795-4f79-a60f-f14e961d924a', name: 'Spray' },
    { value: 'd1fead7b-710f-4a7b-964f-f17a7af54ee7', name: 'Blister' },
    { value: '2214a46e-be89-4f6b-933b-f448a685ec44', name: 'Tube' },
    { value: 'aed9322d-42fd-49af-a8e0-f461e58a527d', name: 'Pastille' },
    { value: '2d8624db-168b-4b74-b03d-f4cf0d2e93cc', name: 'Rope' },
  ],
  ITEM_ROUTES: [
    { value: '58d3d60b-236a-4f23-9190-0379f07553e8', name: 'Epidural' },
    { value: '8850fe0f-7d26-469f-bdd1-03d9c4c45565', name: 'Transmucosal' },
    { value: '4737a80d-b7a6-4fd7-ac23-0548668b55c1', name: 'Intraepidermal' },
    { value: '22bdda52-1b9c-4c9a-a16a-22cd56f8d5b9', name: 'Intramuscular' },
    { value: '4880f0ff-9af2-419c-b025-3363d4611bb3', name: 'Percutaneous' },
    { value: '58b33785-2696-4dd0-90c6-4eb21e019950', name: 'Rectal' },
    { value: '6f657882-4024-44f3-892b-67ea2bf685ca', name: 'Sublingual' },
    { value: 'c97b5482-b9a0-40b5-ac75-6ef06c83633c', name: 'Ophthalmic' },
    { value: 'a2b1b7f0-0743-4f72-bb30-8569061e7b35', name: 'Transdermal' },
    { value: '031ac29d-d486-45e3-867a-887dafb79ede', name: 'Nasal' },
    { value: 'f5db6899-2eb5-48a2-9a33-8ab4f68a670f', name: 'Otic' },
    { value: '6c261d07-b1a3-4a54-a34b-9a5cf9424fa7', name: 'Nasogastric' },
    { value: 'f67d45fd-6a47-4e01-a94f-9e503e425db0', name: 'Subcutaneous' },
    { value: '70a5fd97-d202-4fbf-98d5-9f8c22e17030', name: 'Inhalation' },
    { value: '51264fbd-2314-44f3-92c7-a0f562f30a9d', name: 'Intraocular' },
    { value: '70e53297-05e1-4a8e-81cb-a19e7961c0b4', name: 'Intravenous' },
    { value: 'bc01b34e-a992-4774-9f5d-b08ec0f62bb7', name: 'Intradermal' },
    { value: '04c8750c-fa3c-4431-9742-b6ccff77a0fd', name: 'Oral application' },
    { value: '8264540c-e8d6-4d99-aafe-bd6e0bafa9ad', name: 'Infiltration' },
    { value: 'da21bacd-ea04-4df2-8dcc-c146c9d3c718', name: 'Intraarticular' },
    { value: '3353ebc9-8c51-411f-8e5f-d43fb7944c96', name: 'Vaginal' },
    { value: '2f999bf8-8ca1-46aa-bbd3-e778e3d2d48a', name: 'Oral' },
    { value: '0a7371d8-1492-4c96-9823-e90272f11807', name: 'Urethral' },
    { value: '03667acc-5c29-44fe-ab61-f93ac989796f', name: 'Topical' },
  ],
  DRUG_STATUSES: [
    { value: 'Active', name: 'Active' },
    { value: 'Suspended', name: 'Suspended' },
    { value: 'Deleted', name: 'Deleted' }
  ],
  PACKING_TYPES: [
    { value: 1, name: 'Manual' },
    { value: 2, name: 'Automation' },
    { value: 3, name: 'Guided Fill' }
  ],
  PROFILE_ITEM_STATUS: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Pending Changes' },
    { value: 3, name: 'Pending Add' },
    { value: 4, name: 'Pending Delete' }
  ],
  DRUG_CLASSES: DrugClasses,
  DRUG_SUBCLASSES: DrugSubclasses,
  PACKING_OPTIONS: [
    { value: 1, name: 'Cannot be packed' },
    { value: 2, name: 'Manual packing' },
    { value: 3, name: 'Automated packing' },
  ],
  DEBLISTER_ORIENTATIONS: [
    { value: 1, name: 'None' },
    { value: 2, name: 'Portrait' },
    { value: 3, name: 'Landscape' },
  ],
  DEBLISTER_TENSIONS: [
    { value: 1, name: 'None' },
    { value: 2, name: '1' },
    { value: 3, name: '2' },
    { value: 4, name: '3' },
  ],
  REPORT_TYPES: [
    { name: 'Patient Reports', value: "PATIENT" },
    { name: 'Profile Reports', value: "PROFILE" },
    { name: 'Drug Usage Reports', value: "DRUG_USAGE" },
    { name: 'Drug Shelf Labels', value: "DRUG_SHELF_LABELS" },
    { name: 'Incidents', value: "INCIDENTS" },
    { name: '6 CPA DAA CLAIM', value: "DAA_CLAIM" },
    { name: 'Compact Charts', value: "COMPACT_CHARTS" },
  ],

  PRINT_OPTIONS: [
    { value: 1, name: 'Packed' },
    { value: 2, name: 'Non Packed' },
    { value: 3, name: 'PRN' },
    { value: 4, name: 'Seperate Warfarin Chart' },
    { value: 5, name: 'Nurse Initiated/telephone orders' },
  ],

  REPORT_ORDER_OPTIONS: [
    { value: 5, name: 'Delivery Method' },
    { value: 1, name: 'Doctor' },
    { value: 2, name: 'Last Profile Change Date' },
    { value: 3, name: 'Surname' },
  ],

  REPORT_DRUG_ORDER_OPTIONS: [
    { value: 1, name: 'Patient Surname' },
    { value: 2, name: 'Doctor' },
    { value: 3, name: 'Patient Group' },
    { value: 4, name: 'Start Date' },
  ],

  DRUG_USAGE_ORDER_OPTIONS: [
    { value: 1, name: 'Drug name' },
    { value: 2, name: 'Drug usage by patient' },
    { value: 3, name: 'Drug usage by pill' },
    { value: 4, name: 'Drug usage by generic' },
    { value: 5, name: 'Drug usage by packing' },
  ],

  CHART_SECTION_OPTIONS: [
    { value: 3, name: 'Variable Dose' },
    { value: 4, name: 'Short Term' },
    { value: 5, name: 'Nutritional Supplements' },
    { value: 6, name: 'Regular' },
    { value: 7, name: 'PRN' },
    { value: 8, name: 'Insulin' },
    { value: 9, name: 'Insulin PRN' },
  ],
  EXPIRY_DATE_CALCULATION_OPTIONS: [
    { value: 'fixed', name: 'Fixed' },
    { value: 'dynamic', name: 'Dynamic' },
  ],
  INCIDENT_CAUSES: [
    { value: 1, name: 'Prescribing' },
    { value: 2, name: 'Dispensing' },
    { value: 3, name: 'Profiling' },
    { value: 4, name: 'Packing' },
    { value: 5, name: 'Delivery' },
    { value: 6, name: 'Administration' },
  ],
  INCIDENT_TYPES: [
    { value: 1, name: 'Wrong medication' },
    { value: 2, name: 'Wrong indication' },
    { value: 3, name: 'Wrong dose' },
    { value: 4, name: 'Wrong route' },
    { value: 5, name: 'Wrong time' },
    { value: 6, name: 'Wrong resident' },
    { value: 7, name: 'Missed interaction' },
    { value: 8, name: 'Error in charting' },
    { value: 9, name: 'Change not communicated' },
  ],
  INCIDENT_STATUS_TYPES: [
    { value: 1, name: 'Open' },
    { value: 2, name: 'Closed' },
    { value: 3, name: 'Investigate' },
    { value: 4, name: 'Review' },
  ],
  ORDER_STATUS_TYPES: [
    { value: 1, name: 'Approved' },
    { value: 2, name: 'Rejected' },
    { value: 3, name: 'Voided' },
    { value: 4, name: 'Pending' },
  ],
  ORDER_TYPES: [
    { value: 2, name: 'General' },
    { value: 1, name: 'Medication' },
  ],

  GENERAL_ORDER_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Processed' },
    { value: 3, name: 'Voided' },
  ],

  CHANGE_REQUEST_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Processed' },
    { value: 3, name: 'Voided' },
  ],
  ITEM_TYPES: [
    { value: 1000, name: "PRN" },
    { value: 2000, name: "Non Packed" },
    { value: 3000, name: "Non Packed PRN" },
    { value: 32, name: "Insulin" },
    { value: 64, name: "Nutritional supplement" },
    { value: 4, name: "S8" },
    { value: 8, name: "S11" },
    { value: 2, name: "Short Term" },
    { value: 16, name: "Variable dose" },
  ],
  CUSTOM_PATTERN_WEEKS: [
    { value: 1, name: '1 Week' },
    { value: 2, name: '2 Week' },
    { value: 3, name: '3 Week' },
    { value: 4, name: '4 Week' },
  ],
  NOTE_TYPES: [
    { value: 1, name: 'General' },
    { value: 2, name: 'Profile' },
    { value: 3, name: 'Change Request' },
  ],
  PRODUCT_TYPES: [
    { value: 1, name: 'Starter' },
    { value: 2, name: 'Basic' },
    { value: 3, name: 'Professional' },
    { value: 4, name: 'Integrated' },
    { value: 5, name: 'Consumables Only' },
    { value: 6, name: 'Max' },
  ],
  MACHINE_TYPES: [
    { value: 1, name: 'OD 400' },
    { value: 2, name: 'JVM' },
    { value: 3, name: 'ATP' },
    { value: 4, name: 'JVM-2' },
    { value: 5, name: 'VBM' },
  ],

  PATIENT_MATCH_OPTIONS: [
    { value: -1, name: 'All' },
    { value: 'Linked', name: 'Linked' },
    { value: 'Matched', name: 'Matched' },
    { value: 'Partial Match', name: 'Partial Match' },
    { value: 'Not Matched', name: 'Not Matched' },
    { value: 'Duplicate', name: 'Duplicate' },
  ],

  DISPENSE_VENDORS: [
    { value: 'ca07c2f9-24f0-40b4-b702-1b1f95147bed', name: 'Dispense Works' },
    { value: '12d5d488-d096-427a-894d-2b56292063fc', name: 'LOTS' },
    { value: '68404af8-9ac1-439e-90bb-6532cc029e65', name: 'Fred NXT' },
    { value: '07c4d1d4-c774-454d-a68b-83619cccdc8b', name: 'Aquarius' },
    { value: '0f379f5d-e701-485a-ac77-9c5856d63699', name: 'Mountaintop Systems' },
    { value: '595e44dc-b008-4a85-9515-9df1a68ac39d', name: 'Simple' },
    { value: '719a20d8-8b70-484e-b94d-d9d4e757fdf0', name: 'Minfos' },
    { value: 'f918fd1f-425a-4d07-86e4-e8740214770d', name: 'Z Software' },
    { value: '51b2769c-5fb5-43c7-977c-cd2d3f962366', name: 'Fred Classic' },
    { value: '02a82eec-9a3f-4cf5-aca4-220ecbb280ba', name: 'Corum Clear' },
  ],

  PRESCRIPTION_REPORT_TYPES: [
    { value: 1, name: 'By Doctor' },
    { value: 2, name: 'By Patient Group' },
  ],

  POLICY_PUBLISH_TYPES: [
    { value: 1, name: 'Publish' },
    { value: 2, name: 'Draft' }
  ],

  RESIDENT_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Inactive' }
  ],

  SCOOP_STATUS_TYPES: [
    { value: 1, name: 'Active' },
    { value: 2, name: 'Complete' }
  ],

  SCOOP_STATUS_TYPES_ALL: [
    { value: null, name: 'All' },
    { value: 1, name: 'Active' },
    { value: 2, name: 'Complete' }
  ],

  INFOBLAST_STATUS_TYPES: [
    { value: 1, name: 'Published' },
    { value: 2, name: 'Draft' }
  ],

  POLICY_STATUS_TYPES: [
    { value: 1, name: 'Published' },
    { value: 2, name: 'Draft' }
  ],

  TRAINING_STATUS_TYPES: [
    { value: 1, name: 'Assigned' },
    { value: 2, name: 'Attempted' },
    { value: 3, name: 'Completed' },
    { value: 4, name: 'All' }
  ],

  MEDICATION_REVIEW_STATUS_TYPES: [
    { name: 'Yet to Scoop', value: 0 },
    { name: 'Referral Sent', value: 1 },
    { name: 'Signed Referral Uploaded', value: 2 },
    { name: 'Status Consent Updated', value: 3 },
    { name: 'RMMR Uploaded', value: 4 },
    { name: 'Doctor Notes Added', value: 5 },
    { name: 'Followup Updated', value: 6 },
  ],

  RESIDENT_NO_REVIEW_REASONS: [
    { name: 'No Meds', value: 'No Meds' },
    { name: 'Min Meds', value: 'Min Meds' },
    { name: 'No Medicare/DVA', value: 'No Medicare/DVA' },
    { name: 'Respite', value: 'Respite' },
    { name: 'Consent decline', value: 'Consent decline' },
    { name: 'Other', value: 'Other' }
  ]
}