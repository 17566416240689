/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:260e98bf-55b3-4185-9d8e-f9a255c4faeb",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_WDTMe5KSL",
    "aws_user_pools_web_client_id": "32lns2k1ul04c7qce1pkpbv63j",
    "oauth": {},
    "aws_user_files_s3_bucket": "mederev183314-uat",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
