import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesTableComponent } from './notes-table/notes-table.component';

import { NoteFormComponent } from './note-form/note-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponentModule } from '../form/form-component.module';
import { MatInputModule, MatAutocompleteModule, MatButtonModule, MatTableModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatPaginatorModule } from '@angular/material';
import { PortletModule } from '../../partials/content/general/portlet/portlet.module';
import { CoreModule } from '../../../core/core.module';
import { DataTableModule } from '../dataTable/data-table.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormComponentModule,
    MatInputModule,
    MatAutocompleteModule,
    PortletModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    CoreModule,
    DataTableModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule
  ],
  declarations: [NoteFormComponent, NotesTableComponent],
  entryComponents: [
    NoteFormComponent
  ], 
  exports: [
    NoteFormComponent,
    NotesTableComponent
  ],
})export class NotesModule { }
