import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  InitEdit = (roleId) => this.http.get<any>(config.apiUrl + 'role/view/edit/initialise')

  getRoles = () => this.http.get<any>(config.apiUrl + 'role/view/list/grid')

  update = (values) => this.http.post<any>(config.apiUrl + 'role/view/edit/save', values)

  create = (values) => this.http.post<any>(config.apiUrl + 'role/view/create/save', values)

}
