import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarAlertComponent } from './snack-bar-alert.component';
import { MatIconModule, MatButtonModule, MatSnackBarModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule
  ],
  declarations: [SnackBarAlertComponent]
})
export class SnackBarAlertModule { }
