import { Injectable } from '@angular/core';
import { config } from '../../config/app.config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(private http: HttpClient) {
  }

  autocomplete = (values): Observable<any> => this.http.post(config.apiUrl + 'dashboard/search', values)
}
