import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { MenuConfig } from '../../config/menu';
import { DashboardService } from './dashboard.service';
import { UserService } from './user.service';
import { FacilityService } from '../../content/pages/facility/facility.service'
@Injectable({
	providedIn: 'root'
})
export class MenuConfigService implements OnInit {
	public configModel: MenuConfig = new MenuConfig(null);
	public onMenuUpdated$: BehaviorSubject<MenuConfig> = new BehaviorSubject(
		this.configModel
	);
	menuHasChanged: any = false;

	ordersCount: number = 0;
	changeRequestsCount: number = 0;
	daaCount: number = 0;
	profileChangeCount = 0;
	isHub = false;

	constructor(
		private router: Router,
		private dashboardService: DashboardService,
		private userService: UserService,
		private facility: FacilityService
	) {
		this.resetModel(this.userService.getSession());
		this.userService.sessionChanged.subscribe(session => {
			this.resetModel(session);
		})

		// this.userName = 'User Name';// userService.getSession() ? userService.getSession().currentUserName : '';
		// this.pharmacyName = 'Pharmacy Name';// userService.getSession().pharmacyName;

		// dashboardService.updateMenuStats();

		// if(userService.getSession()) {
		// 	// this.isHub = userService.getSession().isHub
		// }
		// userService.sessionChanged.subscribe(session => {
		// 	this.userName = session.currentUserName;
		// 	this.pharmacyName = session.pharmacyName;
		// 	this.isHub = session.isHub
		// 	this.resetModel();
		// })

		dashboardService.menuStatsUpdated.subscribe(values => {
			if (values) {
				this.ordersCount = values.orders;
				this.changeRequestsCount = values.changeRequests;
				this.daaCount = values.daaClaims;
				this.profileChangeCount = values.profileChanges;
			}
		})

		router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe((event: any) => {
				// if (this.userService.getSession()) {
				// 	if(event.url.match(/\//ig) && event.url !== '/' && !event.url.includes('maintenance')) {
				// 		// dashboardService.updateMenuStats();
				// 		if (this.menuHasChanged) {
				// 			this.resetModel();
				// 		}
				// 	}

				// }
			});
	}

	ngOnInit() {
	}

	setModel(menuModel: MenuConfig) {
		console.log('set model === ', this)
		this.configModel = Object.assign(this.configModel, menuModel);
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = true;
	}

	resetModel(session) {
		console.log('session in reset model = ', this.userService.getSession())
		this.configModel = new MenuConfig(session);
		console.log('new config model === ', this.configModel);
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = false;
	}
}
