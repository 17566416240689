// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';
import { UserService } from '../core/services/user.service';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
  public config: any = {};

  constructor(
    private session?: any,
  ) {

    this.config = {
      header: {
        self: {},
        items: [

          {
            title: session ? session.currentFacilityName : '',
            icon: 'flaticon-home',
          },
          {
            title: session ? session.currentUsername : '',
            icon: 'flaticon-user',
            page: 'userProfile'
          },
        ]
      },
      aside: {
        self: {},
        items: [
          {
            title: 'Facilities',
            root: true,
            icon: 'fa fa-home',
            page: '/facility',
            roles: ['listFacility']
          },
          {
            title: 'Scoops',
            root: true,
            icon: 'fa fa-clipboard-list',
            page: '/scoop',
            roles: ['listScoop']
          },
		  {
            title: 'Claims',
            root: true,
            icon: 'fa fa-clipboard-list',
            page: '/claim',
            roles: ['viewClaim'],
			tab: true
          },
          {
            divider: true,
            roles: ['listFacility', 'listScoop']
          },
          {
            title: session ? session.currentFacilityName : '',
            root: true,
            page: `/facility/${session ? session.currentFacilityId : ''}/dashboard`,
            icon: 'fa fa-home',
            roles: ['menuFacility']
            // tab: true
          },
          {
            title: 'Residents',
            root: true,
            page: `/facility/${session ? session.currentFacilityId : ''}/residents`,
            icon: 'fa fa-users',
            roles: ['viewResident'],
            tab: true
          },
          {
            title: 'Doctors',
            root: true,
            page: `/facility/${session ? session.currentFacilityId : ''}/doctors`,
            icon: 'fa fa-briefcase-medical',
            roles: ['viewDoctor'],
            tab: true
          },
          {
            title: 'Staff',
            root: true,
            page: `/facility/${session ? session.currentFacilityId : ''}/staff`,
            icon: 'fa fa-user-md',
            roles: ['viewRacfStaff', 'viewStaffLimited'],
            tab: true
          },

          {
            divider: true,
            roles: ['menuFacility', 'viewResident', 'viewDoctor', 'viewRacf'],
          },
          {
            title: 'Policies & Procedures',
            root: true,
            icon: 'fa fa-clipboard-list',
            page: '/education/policy-procedures',
            roles: ['viewTraining', 'editTraining', 'assignTraining', 'viewTrainingReport']
          },
          {
            title: 'Compulsory Competencies',
            root: true,
            icon: 'fa fa-notes-medical',
            page: '/education/compulsory-competencies',
            roles: ['editTraining', 'assignTraining', 'viewTrainingReport']
          },
          {
            title: 'Practical Competencies',
            root: true,
            icon: 'fa fa-user-shield',
            page: '/education/practical-compentencies',
            roles: ['viewPracticalCompetency']
          },
          // {
          // 	title: 'Clinical Training',
          // 	root: true,
          // 	icon: 'fa fa-user-md',
          // 	page: '/education/clinical-training',
          // 	roles: ['viewTraining']
          // },
          {
            title: 'Infoblast',
            root: true,
            icon: 'fa fa-envelope',
            page: '/infoblast',
            roles: ['viewInfoblast']
            // badge: { type: 'm-badge--accent', value: 3 },
          },
          {
            divider: true,
            roles: ['editTraining', 'assignTraining', 'viewTrainingReport', 'viewTraining', 'viewInfoblast']
          },
          {
            title: 'GP Practices',
            root: true,
            icon: 'fa fa-hospital',
            page: '/practice',
            roles: ['listPractice']
          },
          {
            title: 'Users',
            root: true,
            icon: 'fa fa-user',
            page: '/user',
            roles: ['viewUser']
          },
          // {
          // 	title: 'Medegroup',
          // 	root: true,
          // 	icon: 'fa fa-users-cog',
          // 	page: '/medegroup',
          // 	// roles: [sidebarRoles.patientMenuViewPatientList]
          // },
          {
            divider: true,
            roles: ['listPractice', 'viewUser']
          },
          {
            title: 'Training Reports',
            root: true,
            icon: 'fa fa-file-alt',
            page: '/report/training',
            roles: ['viewTrainingReport']
          },
          {
            title: 'RMMR Reports',
            root: true,
            icon: 'fa fa-file-alt',
            page: '/report/review',
            roles: ['viewRmmrReport']
          },
          {
            title: 'Training',
            root: true,
            icon: 'fa fa-file-alt',
            page: `/user/${session ? session.currentUserId : ''}`,
            roles: ['viewSelfTraining'],
          },
          {
            divider: true,
            roles: ['viewTrainingReport', 'viewRmmrReport', 'viewSelfTraining']
          },
          {
            title: 'Logout',
            root: true,
            icon: 'fa fa-power-off',
            page: '/logout',
            // roles: [sidebarRoles.patientMenuViewPatientList]
          }
        ]
      }
    };
  }
}
