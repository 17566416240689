/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { LoadingDialogService, LoadingDialogState } from '../../../../core/services/loading-dialog.service';

export class Address {
  street: string;
  postcode: string;
  city: string;
  stateName: string;
  stateId: number;

  constructor(address_components: google.maps.GeocoderAddressComponent[]) {
    this.street = '';

    address_components.map((component: google.maps.GeocoderAddressComponent) => {
      if (component.types.includes('subpremise') || component.types.includes('street_number') || component.types.includes('route')) {
        if (!component.types.includes('street_number')) {
          this.street += ' ';
        }

        this.street += (component.short_name);

        if (component.types.includes('subpremise') && !component.short_name.includes('/')) {
          this.street += '/';
        }
      };
      if (component.types.includes('locality')) {
        this.city = component.short_name;
      };
      if (component.types.includes('administrative_area_level_1')) {
        this.stateName = component.short_name;
      };
      if (component.types.includes('postal_code')) {
        this.postcode = component.short_name;
      }
    })
    this.street = this.street.trim();
    this.setStateId();
  }

  setStateId() {
    switch (this.stateName) {
      case 'VIC':
        this.stateId = 1;
        break;
      case 'NSW':
        this.stateId = 2;
        break;
      case 'TAS':
        this.stateId = 3;
        break;
      case 'QLD':
        this.stateId = 4;
        break;
      case 'SA':
        this.stateId = 5;
        break;
      case 'NT':
        this.stateId = 6;
        break;
      case 'WA':
        this.stateId = 7;
        break;
      case 'ACT':
        this.stateId = 8;
        break;
      default:
        break;
    }
  }
}

@Component({
  selector: 'b-form-address-search',
  templateUrl: './form-address-search.component.html',
  styleUrls: ['./form-address-search.component.scss']
})
export class FormAddressSearchComponent implements OnInit, AfterViewInit {
  @Input() placeholder = 'Search for an address...';
  @Output() addressChanged: EventEmitter<Address> = new EventEmitter();
  @Input() initialValue;

  @ViewChild('addressInput') addressInput: any;

  autocompleteInput: string;

  constructor(
    private loadingService: LoadingDialogService
  ) { }

  ngOnInit() {
    if (this.initialValue) this.autocompleteInput = this.initialValue;
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement,
      {
        componentRestrictions: { country: 'AU' },
        types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      console.log("got place === ", place)
      if (!place.address_components) {
        this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', 'There was a problem fetching this address...');
      }
      else {
        this.addressChanged.emit(new Address(place.address_components));
      }
    });
  }

}
