import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class ChangeRequestService {
  private changeRequests: any;
  changeRequestsChanged = new Subject<Array<any>>();
  changeRequestSaved = new Subject<any>();

  constructor(private http: HttpClient) { }

  getChangeRequests = (filters) => this.http.post(config.apiUrl + 'changeRequest/', filters)

  createChangeRequest = (values) => this.http.post(config.apiUrl + 'changeRequest/create', values)

  processCheck = (id): Observable<any> => this.http.get(config.apiUrl + 'changeRequest/processCheck/' + id)

  process = (id): Observable<any> => this.http.get(config.apiUrl + 'changeRequest/process/' + id)

  void = (id): Observable<any> => this.http.get(config.apiUrl + 'changeRequest/void/' + id)

}
