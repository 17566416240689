import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

@Component({
  selector: "b-support-form",
  templateUrl: "./support-form.component.html",
  styleUrls: ["./support-form.component.scss"],
})
export class SupportFormComponent implements OnInit {
  @Input() supportForm: FormGroup
  loading = false

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }
}
