import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableHeaderComponent } from './data-table-header/data-table-header.component';
import { InputHeaderCellComponent } from './input-header-cell/input-header-cell.component';
import { SelectHeaderCellComponent } from './select-header-cell/select-header-cell.component';
import { MatIconModule, MatTableModule, MatInputModule, MatSelectModule, MatSortModule, MatDatepickerModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { SubtitileCellComponent } from './subtitile-cell/subtitile-cell.component';
import { DateHeaderCellComponent } from './date-header-cell/date-header-cell.component';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    MatSortModule
  ],
  declarations: [
    InputHeaderCellComponent,
    SelectHeaderCellComponent,
    DataTableComponent,
    DataTableHeaderComponent,
    DateHeaderCellComponent,
    SubtitileCellComponent
  ],
  exports: [
    InputHeaderCellComponent,
    SelectHeaderCellComponent,
    DataTableComponent,
    DataTableHeaderComponent,
    SubtitileCellComponent,
    DateHeaderCellComponent
  ]
})
export class DataTableModule { }
