import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { config } from '../../../config/app.config';
import { LoadingDialogService, LoadingDialogState } from '../../../core/services/loading-dialog.service';
import { UserService } from '../../../core/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  amplifyStateSubject$ = new BehaviorSubject<any>(null)
  user: any
  action: string

  constructor(private loadingService: LoadingDialogService, private userService: UserService, private router: Router, private location: Location) {
    this.amplifyStateSubject$.subscribe(authState => {
      if (authState) {
        console.log("Handling auto sign in")
        console.log('auth change === ', authState);
        if (authState.state == 'requireNewPassword') {
          this.action = "requireNewPassword";
          this.user = authState.user;
          this.loadingService.showCompletion(LoadingDialogState.Success, 'New Password Required!', 'OK', 'You are required to provide a new password')
          // this.cdRef.detectChanges();
        }
        else if (authState.state == 'confirmSignUp') {
          this.action = 'confirmSignup';
          this.user = authState.user.username;
          this.loadingService.showCompletion(LoadingDialogState.Success, 'Verify Signup', 'OK', 'Please check your email for a verification code.');
          // this.cdRef.markForCheck();
        }
        else if (authState.user && authState.user.attributes && authState.user.attributes.email_verified === false) {
          this.action = "validate";
          this.user = authState.user;
          this.loadingService.close.next();
          // this.cdRef.detectChanges();
        }
        else if (authState.state != 'signedOut') {
          this.userService.loadPermissions();
          if (this.location.path() == '')
            this.userService.redirectUser()
        }
        else {
          this.router.navigate(['/login'])
        }
      }
    })
  }
}
