export const DrugSubclasses = [
  {value: 1, name: "Hyperacidity, reflux and ulcers", classId: 1},
  {value: 2, name: "Antispasmodics and motility agents", classId: 1},
  {value: 3, name: "Laxatives", classId: 1},
  {value: 4, name: "Antidiarrhoeals", classId: 1},
  {value: 5, name: "Digestive supplements and cholelitholytics", classId: 1},
  {value: 6, name: "Topical anorectal medication", classId: 1},
  {value: 7, name: "Antihypertensive agents", classId: 2},
  {value: 8, name: "Beta-adrenergic blocking agents", classId: 2},
  {value: 9, name: "Diuretics", classId: 2},
  {value: 10, name: "Antiarrhythmic agents", classId: 2},
  {value: 11, name: "Antiangina agents", classId: 2},
  {value: 12, name: "Hypolipidaemic agents", classId: 2},
  {value: 13, name: "Cardiac inotropic agents", classId: 2},
  {value: 14, name: "Adrenergic stimulants, vasopressor agents", classId: 2},
  {value: 15, name: "Peripheral vasodilators", classId: 2},
  {value: 16, name: "Antimigraine preparations", classId: 2},
  {value: 17, name: "Anticoagulants, antithrombotics", classId: 2},
  {value: 18, name: "Haemostatic agents", classId: 2},
  {value: 19, name: "Fibrinolytic agents", classId: 2},
  {value: 20, name: "Other cardiovascular agents", classId: 2},
  {value: 21, name: "Sedatives, hypnotics", classId: 3},
  {value: 22, name: "Antianxiety agents", classId: 3},
  {value: 23, name: "Antipsychotic agents", classId: 3},
  {value: 24, name: "Antidepressants", classId: 3},
  {value: 25, name: "Other central nervous system agents", classId: 3},
  {value: 26, name: "Movement disorders", classId: 3},
  {value: 27, name: "Anticonvulsants", classId: 3},
  {value: 28, name: "Antiemetics, antinauseants", classId: 3},
  {value: 29, name: "Narcotic analgesics", classId: 4},
  {value: 30, name: "Simple analgesics and antipyretics", classId: 4},
  {value: 31, name: "Combination simple analgesics", classId: 4},
  {value: 32, name: "Nonsteroidal anti-inflammatory agents", classId: 5},
  {value: 33, name: "Antirheumatoid agents", classId: 5},
  {value: 34, name: "Muscle relaxants", classId: 5},
  {value: 35, name: "Rubefacients, topical analgesics/NSAIDs", classId: 5},
  {value: 36, name: "Agents used in gout and hyperuricaemia", classId: 5},
  {value: 37, name: "Neuromuscular agents", classId: 5},
  {value: 38, name: "Adrenal steroid hormones", classId: 6},
  {value: 39, name: "Gonadal hormones", classId: 6},
  {value: 40, name: "Pituitary hormones", classId: 6},
  {value: 41, name: "Insulin preparations", classId: 6},
  {value: 42, name: "Hypoglycaemic agents", classId: 6},
  {value: 43, name: "Thyroid hormones and antithyroid agents", classId: 6},
  {value: 44, name: "Agents affecting calcium and bone metabolism", classId: 6},
  {value: 45, name: "Anabolic agents", classId: 6},
  {value: 46, name: "Haemopoietic agents", classId: 6},
  {value: 47, name: "Other endocrine and metabolic agents", classId: 6},
  {value: 48, name: "Urinary antiseptics, alkalinisers and acidifiers", classId: 7},
  {value: 49, name: "Bladder function disorders", classId: 7},
  {value: 50, name: "Agents acting on the uterus", classId: 7},
  {value: 51, name: "Topical vaginal medication", classId: 7},
  {value: 52, name: "Erectile dysfunction agents", classId: 7},
  {value: 53, name: "Penicillins", classId: 8},
  {value: 54, name: "Cephalosporins", classId: 8},
  {value: 55, name: "Tetracyclines", classId: 8},
  {value: 56, name: "Macrolides", classId: 8},
  {value: 57, name: "Quinolones", classId: 8},
  {value: 58, name: "Aminoglycosides", classId: 8},
  {value: 59, name: "Other antibiotics and anti-infectives", classId: 8},
  {value: 60, name: "Antifungal agents", classId: 8},
  {value: 61, name: "Antiviral agents", classId: 8},
  {value: 62, name: "Antituberculotics and antileprotics", classId: 8},
  {value: 63, name: "Antimalarials", classId: 8},
  {value: 64, name: "Anthelmintics", classId: 8},
  {value: 65, name: "Alkylating agents", classId: 9},
  {value: 66, name: "Antimetabolites", classId: 9},
  {value: 67, name: "Vinca alkaloids", classId: 9},
  {value: 68, name: "Antibiotic cytotoxics", classId: 9},
  {value: 69, name: "Hormonal antineoplastic agents", classId: 9},
  {value: 70, name: "Other antineoplastic agents", classId: 9},
  {value: 71, name: "Noncytotoxic and supportive therapy", classId: 9},
  {value: 72, name: "Vaccines", classId: 10},
  {value: 73, name: "Immunoglobulins", classId: 10},
  {value: 74, name: "Immunomodifiers", classId: 10},
  {value: 75, name: "Antisera, antivenoms", classId: 10},
  {value: 76, name: "Expectorants, antitussives, mucolytics, decongestants", classId: 11},
  {value: 77, name: "Bronchospasm relaxants", classId: 11},
  {value: 78, name: "Bronchodilator aerosols and inhalations", classId: 11},
  {value: 79, name: "Preventive aerosols and inhalations", classId: 11},
  {value: 80, name: "Other respiratory agents", classId: 11},
  {value: 81, name: "Antihistamines", classId: 12},
  {value: 82, name: "Antiallergy preparations", classId: 12},
  {value: 83, name: "Topical otic medication", classId: 13},
  {value: 84, name: "Topical nasopharyngeal medication", classId: 13},
  {value: 85, name: "Topical oropharyngeal medication", classId: 13},
  {value: 86, name: "Topical ocular anti-infective preparations", classId: 14},
  {value: 87, name: "Topical ocular steroid preparations", classId: 14},
  {value: 88, name: "Glaucoma preparations", classId: 14},
  {value: 89, name: "Mydriatics, cycloplegics, miotics", classId: 14},
  {value: 90, name: "Ocular decongestants, anaesthetics, anti-inflammatories", classId: 14},
  {value: 91, name: "Ocular lubricants", classId: 14},
  {value: 92, name: "Other ophthalmic medication", classId: 14},
  {value: 93, name: "Emollients, antipruritics and protective preparations", classId: 15},
  {value: 94, name: "Psoriasis, seborrhoea and ichthyosis", classId: 15},
  {value: 95, name: "Acne, keratolytics and cleansers", classId: 15},
  {value: 96, name: "Wart and corn removers", classId: 15},
  {value: 97, name: "Topical corticosteroids", classId: 15},
  {value: 98, name: "Topical antibiotics", classId: 15},
  {value: 99, name: "Topical antifungals", classId: 15},
  {value: 100, name: "Topical antiparasitics", classId: 15},
  {value: 101, name: "Topical antiseptics, anti-infectives", classId: 15},
  {value: 102, name: "Leg ulcer preparations", classId: 15},
  {value: 103, name: "Other dermatological preparations", classId: 15},
  {value: 104, name: "Anaesthetics - local and general", classId: 16},
  {value: 105, name: "Neuromuscular blocking agents", classId: 16},
  {value: 106, name: "Cholinergic and anticholinergic agents", classId: 16},
  {value: 107, name: "Plasma volume expanders", classId: 16},
  {value: 108, name: "Surgical antiseptics and applications", classId: 16},
  {value: 109, name: "Radiographic agents and bowel preparations", classId: 17},
  {value: 110, name: "Agents for urinalysis", classId: 17},
  {value: 111, name: "Ovulation, pregnancy detection agents", classId: 17},
  {value: 112, name: "Other diagnostic agents", classId: 17},
  {value: 113, name: "Combined oral contraceptive agents", classId: 18},
  {value: 114, name: "Progestogen only contraceptive agents", classId: 18},
  {value: 115, name: "Spermicidal contraceptives", classId: 18},
  {value: 116, name: "Contraceptive devices", classId: 18},
  {value: 117, name: "Infant formulas", classId: 19},
  {value: 118, name: "Supplemental and enteral nutrition", classId: 19},
  {value: 119, name: "Parenteral vitamins, minerals and nutrition", classId: 19},
  {value: 120, name: "Oral and parenteral electrolytes", classId: 19},
  {value: 121, name: "Anorectics and weight reducing agents", classId: 19},
  {value: 122, name: "Detoxifying agents, antidotes", classId: 20},
  {value: 123, name: "Agents used in drug dependence", classId: 20},
  {value: 124, name: "Vitamins (single agents)", classId: 21},
  {value: 125, name: "Minerals", classId: 21},
  {value: 126, name: "Fatty acid supplements", classId: 21},
  {value: 127, name: "Fat soluble vitamins", classId: 21},
  {value: 128, name: "B Group vitamins with other agents", classId: 21},
  {value: 129, name: "Multivitamins and minerals", classId: 21},
  {value: 130, name: "Tonics", classId: 21},
  {value: 131, name: "Children's vitamins", classId: 21},
  {value: 132, name: "Iron", classId: 21},
  {value: 133, name: "Herbal gastrointestinal preparations", classId: 22},
  {value: 134, name: "Herbal circulatory system preparations", classId: 22},
  {value: 135, name: "Herbal nervous system preparations", classId: 22},
  {value: 136, name: "Herbal analgesics and anti-inflammatories", classId: 22},
  {value: 137, name: "Women's supplements", classId: 22},
  {value: 138, name: "Men's supplements", classId: 22},
  {value: 139, name: "Herbal respiratory preparations", classId: 22},
  {value: 140, name: "Herbal skin preparations", classId: 22},
  {value: 141, name: "Herbal urinary tract preparations", classId: 22},
  {value: 142, name: "General well-being, multiple use preparations, others", classId: 22},
]