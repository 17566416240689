import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { LoadingDialogState } from '../../../../../core/services/loading-dialog.service';
import { UserService } from '../../../../../core/services/user.service';
import { ConfirmDialogComponent } from '../../../../shared/dialog/confirm-dialog/confirm-dialog.component';
import { SupportService } from '../../support.service';

@Component({
  selector: 'm-support-detail',
  templateUrl: './support-detail.component.html',
  styleUrls: ['./support-detail.component.scss']
})
export class SupportDetailComponent implements OnInit {
  supportForm: FormGroup

  constructor(public service: SupportService, public dialog: MatDialog, private userService: UserService) { }

  ngOnInit() {
    this.supportForm = this.service.getSupportForm()
  }

  submit = () => {
    this.service.submit(this.supportForm.value).subscribe(() => {
      let message = 'Your message has been sent to servicedesk@mederev.com.au'
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Thank you for your enquiry',
          // state: LoadingDialogState.Success,
          message,
          confirmButtonText: "OK",
          confirmButtonColour: "primary",
          // cancelButtonText: "NO",
          // cancelButtonColour: "warn",
        },
        width: '500px',
      })
      dialogRef.afterClosed().subscribe(result => {
        this.supportForm.reset()
      });
    })
  }

}
