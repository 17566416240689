import { Component, OnInit, Input } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-form-checkbox-field',
  templateUrl: './form-checkbox-field.component.html',
  styleUrls: ['./form-checkbox-field.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class FormCheckboxFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() formName: string;
  @Input() translatePlaceholder;
  @Input() disabled = false;
  @Input() divide = false;
  @Input() type = 'switch';
  @Input() fg: FormGroup; // form group will be preferred way going forward, facility form needs to be changed
  @Input() position = 'left';
  @Input() color = 'accent';
  
  constructor(
    private formGroupDirective: FormGroupDirective,
    public translate: TranslateService) { 
  }

  getPlaceholder(){
    if (this.translatePlaceholder){
      return this.translate.instant('TEXT.' + this.translatePlaceholder);
    }
    if (this.placeholder) {
      return this.placeholder
    }
    return null;
  }
  
  ngOnInit() {
  }

}
