import {
	Component,
	OnInit,
	HostBinding,
	HostListener,
	Input,
	ChangeDetectionStrategy,
	ViewEncapsulation,
	ChangeDetectorRef,
	Renderer2,
	ViewChild,
	ElementRef
} from '@angular/core';
import { DashboardService } from '../../../../../core/services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from 'aws-amplify';
import { Router } from '@angular/router';
import { BatchService } from '../../../../../core/services/batch.service';
import { MatTabGroup, MatDialog } from '@angular/material';
import { PackingValidationDialogComponent } from '../../../../shared/dialog/packing-validation-dialog/packing-validation-dialog.component';
import { TimeagoIntl } from 'ngx-timeago';
import {strings as englishStrings} from 'ngx-timeago/language-strings/en';
import { DateConvertPipe } from '../../../../../core/pipes/date-convert.pipe';
 

@Component({
	selector: 'm-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [DateConvertPipe]
})
export class NotificationComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right 	m-dropdown--mobile-full-width';

	// @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'hover';
	// @HostBinding('attr.m-dropdown-persistent') attrDropdownPersistent = 'true';

	@Input() animateShake: any;
	@Input() animateBlink: any;

	allNotifications: any[] = [];
	notifications: any[] = [];
	packRuns: any[] = [];
	reports: any[] = [];
	alerts: any[] = [];

	ordersCount: number = 0;
	changeRequestsCount: number = 0;
	daaCount: number = 0;
	profileChangesCount: number = 0;

	selectedIndex = 0;

	active = false;

	loading: boolean;

	@ViewChild('dropdownWrapper') dropdownWrapper: ElementRef;
	@ViewChild('tabGroup') tabGroup: MatTabGroup;

	constructor(
		private renderer: Renderer2,
		dashboardService: DashboardService,
		public translate: TranslateService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private service: BatchService,
		public dialog: MatDialog,
		intl: TimeagoIntl,
		private dateConvertPipe: DateConvertPipe
	) {
		// animate icon shake and dot blink
    intl.strings = englishStrings;
    intl.changes.next();

		dashboardService.menuStatsUpdated.subscribe(
			values => {
				this.allNotifications = values.notifications;

				this.ordersCount = values.orders;
				this.changeRequestsCount = values.changeRequests;
				this.daaCount = values.daaClaims;
				this.profileChangesCount = values.profileChanges;

				// this.onTabChange(0);
			}
		)

		this.renderer.listen('window', 'click', (e) => {
			if(this.active) {
				let element: any = e.target;	
				if (!this.dropdownWrapper.nativeElement.contains(element) && e.target.id !== 'delete_notif') {	
					if (this.active) {
						console.log("setting inactive")
						this.active = false
						this.cdRef.detectChanges();
						// this.toggleActive();
					}
				} else {
				}
			}
		});
	}

	prepareNotifications() {
		this.packRuns = [];
		this.alerts = [];
		this.reports = [];

		this.allNotifications.map(notif => {
			if (notif.type === 'packrun') {
				this.packRuns.push(notif);
			} else if (notif.type === 'fredScriptSync') {
				this.alerts.push(notif);
			}
			else {
				console.log("Notif === ", notif)
				this.reports.push(notif);
			}
		})
		this.cdRef.detectChanges();
	}

	toggleActive() {
		if (!this.active) {
			setTimeout(() => {
				this.active = true;
				this.loading = true;

				this.cdRef.detectChanges();

				this.service.getBatches().subscribe((batches: any[]) => {
					if (this.tabGroup) {
						this.allNotifications = batches;
						this.prepareNotifications();
						this.onTabChange(0);
						this.tabGroup.selectedIndex = 0;	
						this.loading = false;
						this.cdRef.detectChanges();	
					}
				});
			}, 100);
		}
	}

	onNotificationClick(notif) {
		if (notif.type === 'packrun' && notif.status === 'Completed') {
			this.active = false;
			this.router.navigate(['packing/' + notif.id + '/1']);
		} else if (notif.type === 'fredScriptSync' && notif.status === 'Completed') {
			this.active = false;
			this.router.navigate(['integration/scripts']);
		} else if (notif.type === 'packrun' && notif.status === 'Failed') {
			var dialogRef = this.dialog.open(PackingValidationDialogComponent, {
				data: {report: notif.packRunValidationReport},
				width: '50vw'
			})	
		} else if (notif.type === 'dispense-log' && notif.status.toLowerCase() === 'completed'){
			this.router.navigate(['/VPC/' + notif.id]);
			this.active = false;
			this.cdRef.detectChanges();
		} else {
			Storage.get(notif.id + '.pdf', { level: 'public' })
				.then((result) => {
					window.open(result.toString(), '_blank');
				})
				.catch((err) => {

				});
		}

		this.cdRef.detectChanges();
	}

	ngOnInit(): void { }

	getPackrunInfo(notif) {
		var info = notif.facilityName + ' pack run starting on ' + this.dateConvertPipe.transform(notif.packRunStartDate, 'date');
		if (notif.status === 'Completed') {
			info += (' for ' + notif.patientCount + ' patient' + ((notif.patientCount > 1) ? 's' : '')) 
		}
		return info;
	}

	removeNotification(event, notification) {
		event.preventDefault();
		notification.isDeleting = true;
		this.cdRef.detectChanges();
		this.service.deleteBatch(notification.batchId).subscribe((notifs: any[]) => {
			this.allNotifications = notifs;
			this.prepareNotifications();
			this.onTabChange(this.selectedIndex);
		})
	}

	onTabChange(index) {
		if (this.tabGroup) {
			this.selectedIndex = index;
			this.tabGroup.selectedIndex = index;
	
			if (this.selectedIndex === 0) {
				this.notifications = this.packRuns;
			} else if (this.selectedIndex === 1) {
				this.notifications = this.reports;
			} else if (this.selectedIndex === 2) {
				this.notifications = this.alerts;
			}
	
			this.cdRef.detectChanges();
		}
	}

}
