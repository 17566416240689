import { Component, OnInit, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoadingDialogService } from '../../../../core/services/loading-dialog.service';

@Component({
  selector: 'b-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent  {
  progress: string = null;
  buttonColor = '#7ac043';

  constructor(
    private cdRef: ChangeDetectorRef,
    private service: LoadingDialogService,
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
    console.log("got config == ", config)
    this.config = config;
    dialogRef.disableClose = this.config.loading;
    if(this.config.state) {
      this.updateButtonColor();
    }
  }

  onNoClick(): void {
    this.service.onCloseClick.next();
    this.dialogRef.close();
  }

  updateConfig(config) {
    this.config = config;
    if(this.config.state) {
      this.updateButtonColor();
    }
    this.cdRef.detectChanges();
  }

  updateButtonColor() {
    switch (this.config.state) {
      case 0:
        this.buttonColor = '#7ac043';
        break;
      case 1:
        this.buttonColor = '#f4516c';
        break;
      case 2:
        this.buttonColor = '#F0AD4E';
        break;
      case 3:
        this.buttonColor = '#36a3f7';
        break;

      default:
        this.buttonColor = '#7ac043';
        break;
    }
  }

  onConfirmClick () {
    this.service.onConfirmClick.next();
    this.dialogRef.close();
  }
}