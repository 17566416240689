import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputFieldComponent } from './form-input-field/form-input-field.component';
import { FormSelectFieldComponent } from './form-select-field/form-select-field.component';
import { FormDateFieldComponent } from './form-date-field/form-date-field.component';
import { FormTextareaFieldComponent } from './form-textarea-field/form-textarea-field.component';
import { FormCheckboxFieldComponent } from './form-checkbox-field/form-checkbox-field.component';
import { HeaderSubtitleComponent } from './header-subtitle/header-subtitle.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgxFileDropModule } from 'ngx-file-drop';

import {
	MatInputModule,
	MatSortModule,
	MatProgressSpinnerModule,
	MatTableModule,
	MatPaginatorModule,
	MatSelectModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatIconModule,
	MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatSlideToggleModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteModule,
} from '@angular/material';

import { FormsModule, ReactiveFormsModule, FormGroupDirective } from '@angular/forms';
import { FormPasswordValidatorComponent } from './form-password-validator/form-password-validator.component';
import { FormPhotoInputFieldComponent } from './form-photo-input-field/form-photo-input-field.component';
import {NgxMaskModule} from 'ngx-mask'
import { NgxCurrencyModule } from 'ngx-currency';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CoreModule } from '../../../core/core.module';
import { FormAddressSearchComponent } from './form-address-search/form-address-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormDaterangeFieldComponent } from './form-daterange-field/form-daterange-field.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormSearchDropdownComponent } from './form-search-dropdown/form-search-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
		ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PerfectScrollbarModule,
    MatCardModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    NgxMaskModule,
    NgxCurrencyModule,
    CoreModule,
    MatAutocompleteModule,
	TranslateModule,
	NgxDaterangepickerMd
  ],
  declarations: [
    FormInputFieldComponent,
    FormSelectFieldComponent,
    FormDateFieldComponent,
    FormTextareaFieldComponent,
    FormCheckboxFieldComponent,
    HeaderSubtitleComponent,
    FormPasswordValidatorComponent,
    FormPhotoInputFieldComponent,
    FormAddressSearchComponent,
    FormDaterangeFieldComponent,
    FormSearchDropdownComponent
  ],
  exports: [
    FormInputFieldComponent,
    FormSelectFieldComponent,
    FormDateFieldComponent,
    FormTextareaFieldComponent,
    FormCheckboxFieldComponent,
    HeaderSubtitleComponent,
    FormPasswordValidatorComponent,
    FormPhotoInputFieldComponent,
    FormAddressSearchComponent,
    ReactiveFormsModule,
    FormDaterangeFieldComponent
  ],
  providers: [
    FormGroupDirective,
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

  ]

})
export class FormComponentModule { }
