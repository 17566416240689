import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatButtonModule,
  MatTableModule,
  MatIconModule,
  MatSortModule,
  MatPaginatorModule,
  MatChipsModule,
  MatTabsModule,
  MatBadgeModule,
  MatRadioModule,
} from "@angular/material";
import { PortletModule } from "../../partials/content/general/portlet/portlet.module";
import { DataTableModule } from "../../shared/dataTable/data-table.module";
import { FormComponentModule } from "../../shared/form/form-component.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SwitchModule } from "../../shared/switch/switch.module";
import { CoreModule } from "../../../core/core.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { FormsModule } from "@angular/forms";
import { SupportRoutingModule } from "./support-routing.module";
import { SupportDetailComponent } from "./pages/support-detail/support-detail.component";
import { SupportFormComponent } from "./components/support-form/support-form.component";

@NgModule({
  imports: [
    SupportRoutingModule,
    CommonModule,
    CoreModule,
    FormsModule,
    MatRadioModule,
    NgxPermissionsModule,
    PortletModule,
    DataTableModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    FormComponentModule,
    MatChipsModule,
    MatTabsModule,
    PerfectScrollbarModule,
    MatBadgeModule,
    SwitchModule
  ],
  declarations: [
    SupportDetailComponent,
    SupportFormComponent
  ],
  entryComponents: [
    SupportFormComponent
  ]
})
export class SupportModule { }
