import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import jspdf from 'jspdf';

import html2canvas from 'html2canvas';
import { LoadingDialogService, LoadingDialogState } from '../../../../core/services/loading-dialog.service';

@Component({
  selector: 'm-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss']
})
export class PdfDialogComponent {

  constructor(
    private service: LoadingDialogService,
    public dialogRef: MatDialogRef<PdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
    dialogRef.disableClose = this.config.loading;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  makePDF() {
    this.service.showLoading('Loading...');
    var elements = document.getElementsByClassName("new-page");

    console.log("ELS == ", elements);

    let pdf = new jspdf('l', 'px', 'a4'); // A4 size page of PDF  

    var promises: Promise<any>[] = [];

    for (var i = 0; i < elements.length; i++) {
      console.log("i == ", i);
      var el: Element = elements[i]; 

      promises.push(
        html2canvas(elements[i] as HTMLElement).then(canvas => {
          // Few necessary setting options  
          var imgHeight = el.getBoundingClientRect().height / 2;
          var imgWidth = imgHeight * 1.414;

          console.log("H == ", imgHeight, "   w == ", imgWidth);
          
          const contentDataURL = canvas.toDataURL('image/png')

          pdf.addImage(contentDataURL, 'PNG', 30, 30, imgWidth - 60, imgHeight - 60)

          if (i !== elements.length - 1) {
            pdf.addPage();
          }
          console.log(" i ======= ", i);
        }))
    }

    Promise.all(promises).then(() => {
      console.log("SAVING PDF");
      this.service.close.next();
      pdf.save('MYPdf.pdf'); // Generated PDF       
    })
  }

  savePdf(pdf) {
    console.log("SAVING PDF");
    pdf.save('MYPdf.pdf'); // Generated PDF   
  }
}
