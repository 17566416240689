import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from '@aws-amplify/core';
import amplify from './aws-exports';
Amplify.configure(amplify);

//Note: DT - 2022-07-28 - This check has been disabled as it was previously environment.name = 'test'
//                      - until such time that the resulting issues with Scoops can be corrected
//if (environment.name == 'test') { //NOTE: test is Mederev's production enviroment
	enableProdMode();
//}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
