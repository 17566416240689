import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, AfterViewInit, ChangeDetectorRef, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectComponent } from '../../../pages/components/material/formcontrols/select/select.component';
import { MatCheckbox, MatOption, MatSelect } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'b-form-select-field',
  templateUrl: './form-select-field.component.html',
  styleUrls: ['./form-select-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormSelectFieldComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSelect) matSelect: MatSelect;

  @Input() placeholder: string;
  @Input() hint: string;
  @Input() controlName: string;
  @Input() options: [any];
  @Input() formName: string;
  @Input() arrayName: string;
  @Input() translatePlaceholder;
  @Input() translateOptions;
  @Input() multiple = false;
  @Input() sort = false;
  @Input() disabled = false;
  @Input() inputId: string;
  @Input() panelClass: string;
  @Output() onBlur = new EventEmitter();
  @Input() fg: FormGroup; // form group will be preferred way going forward, facility form needs to be changed
  @Input() allValue: any;
  @Input() selectAll: any;
  @Input() disabledOptions; // per option disabled flag

  selectOptions: any[] = [];
  selectPlaceholder: string;

  control: AbstractControl;

  constructor(
    private formGroupDirective: FormGroupDirective,
    public translate: TranslateService) {
  }

  ngOnInit() {
    if (this.fg && this.fg.controls[this.controlName]) {
      this.control = this.fg.controls[this.controlName];
      this.selectOptions = this.getOptions();
      this.selectPlaceholder = this.getPlaceholder();
      if (this.control.disabled) {
        this.disabled = true;
      }

      if (this.multiple && this.allValue) {
        this.control.valueChanges.subscribe((nextValue: any[]) => {
          console.log('next === ', nextValue)
          const previousValue: any[] = this.fg.value[this.controlName];
          console.log('prev === ', previousValue)

          const wasAllSelected = previousValue.includes(this.allValue);
          console.log('wasall === ', wasAllSelected)

          if (!wasAllSelected && nextValue.includes(this.allValue)) {
            console.log('1');
            console.log('ops === ', this.options)
            this.control.setValue([this.allValue, ...this.getOptions().map(ps => ps.value)], { emitEvent: false });
          } else if (wasAllSelected && !nextValue.includes(this.allValue)) {
            console.log('2');
            this.control.setValue([], { emitEvent: false });
          } else if (wasAllSelected && nextValue.includes(this.allValue)) {
            console.log('3');
            this.control.setValue(nextValue.filter(val => val !== this.allValue), { emitEvent: false });
          }
        })
      }
    }
  }

  ngAfterViewInit() {
    this.matSelect._closedStream.subscribe(value => {
      this.blurred()
    })

    if (this.selectAll) {
      this.allSelectOption$ = this.allSelectOption.changes.subscribe(val => { if (val && val.first) val.first.checked = this.allAreSelected });
    }
  }

  ngOnDestroy() {
    if (this.allSelectOption$)
      this.allSelectOption$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectOptions = this.getOptions();
    this.selectPlaceholder = this.getPlaceholder();
    if (this.control && this.control.value) {
    }
    this.allAreSelected = this.control && this.control.value && this.control.value.length === this.selectOptions.length;
  }


  getPlaceholder() {
    if (this.translatePlaceholder) {
      return this.translate.instant('TEXT.' + this.translatePlaceholder);
    }
    if (this.placeholder) {
      return this.placeholder
    }
    return null;
  }

  getOptions(): any[] {
    if (this.translateOptions) {
      let opts = this.translate.instant('OPTIONS.' + this.translateOptions);

      if (this.sort) {
        opts = opts.sort((a, b) => a.name.localeCompare(b.name))
      }

      // If disabled options, map to options
      if (this.disabledOptions) {
        opts.forEach(option => {
          option.disabled = this.disabledOptions[option.name] ? this.disabledOptions[option.name] : false
        })
      }

      return opts;
    }
    if (this.options) {
      return this.options
    }
    return null;
  }

  getValue(val) {
    let foundOption = this.options.find((o: any) => o.value === val);

    if (foundOption) {
      return foundOption.name;
    } else {
      return "";
    }
  }

  blurred() {
    this.onBlur.emit();
  }

  getErrorMessage() {
    const errorType = Object.keys(this.control.errors)[0];
    return this.translate.instant('ERROR.' + errorType);
  }

  
  @ViewChildren('allSelectOption') allSelectOption: QueryList<MatCheckbox>;
  allSelectOption$: Subscription
  allAreSelected = false;

  onChange = ({ value }) => {
    if (!this.selectAll || !this.allSelectOption) return;
    if (!value.length) {
      this.allAreSelected = false;
      this.allSelectOption.first.checked = false;
    }
    else if (value.length == this.selectOptions.length) {
      this.allAreSelected = true;
      this.allSelectOption.first.checked = true;
    }
  }

  onAllToggled = (event) => {
    this.allAreSelected = !this.allAreSelected
    const values = this.allAreSelected ? this.selectOptions.map(o => o.value) : [];
    this.control.patchValue(values);
  }
}
