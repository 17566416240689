import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../../../core/services/user.service';

@Component({
  selector: 'm-user-idle-dialog',
  templateUrl: './user-idle-dialog.component.html',
  styleUrls: ['./user-idle-dialog.component.scss']
})
export class UserIdleDialogComponent implements OnInit, AfterViewInit {
  username: string;
  email: string;
  loading = false;
  loginForm: FormGroup;
  userService: UserService;
  success = false;
  errorMessage: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<UserIdleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
    this.username = config.username;
    this.userService = config.userService;
    this.email = config.email;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      password: new FormControl(null, Validators.required)
    })
  }

  ngAfterViewInit(): void {
    var passwordInput = document.getElementById('password') as HTMLElement;
    if (passwordInput) {
      passwordInput.focus()
    }
  }

  login() {
    this.errorMessage = null;
    if (this.loginForm.invalid) {
      this.loginForm.controls.password.markAsTouched();
      return;
    }

    this.loading = true;
    this.cdRef.detectChanges();
    this.userService.login(this.email, this.loginForm.value.password).subscribe(user => {
      localStorage.removeItem('requiresSignout');
      this.dialogRef.close();
    //   this.userService.initUserIdle();
    }, error => {
      this.errorMessage = error.message;
      this.loading = false;
      this.cdRef.detectChanges();
    })
  }

  switchAccounts() {
    this.userService.logout();
    this.dialogRef.close();
  }
}
