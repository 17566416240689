import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { config } from '../../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  public facilityMenuItemSelected: Subject<number> = new Subject();

  constructor(private http: HttpClient, private formBuilder: FormBuilder) { }

  initNew = () => this.http.get<any>(config.apiUrl + 'facility/view/create/initialise')

  initDashboard = () => this.http.get<any>(config.apiUrl + `facility/view/dashboard/initialise`)

  refreshResidents = () => this.http.get<any>(config.apiUrl + 'facility/view/resident/refresh')

  bulkUpdateResidents = (values) => this.http.post<any>(config.apiUrl + 'resident/view/edit/bulk/save', values)

  InitEdit = () => this.http.get<any>(config.apiUrl + `facility/view/edit/initialise`)

  clearUnreadInfoblasts = (doctorId) => this.http.post<any>(config.apiUrl + `infoblast/view/clear/${doctorId}`, {}).toPromise()

  getFacilities = () => this.http.get<any>(config.apiUrl + 'facility/view/list/grid')

  getUploads = () => this.http.get<any>(config.apiUrl + 'facility/view/dashboard/grid/upload')

  update = (values) => this.http.post<any>(config.apiUrl + 'facility/view/edit/save', values)

  create = (values) => this.http.post<any>(config.apiUrl + 'facility/view/create/save', values)

  delete = () => this.http.delete<any>(config.apiUrl + `facility/view/edit/delete`)

  initIntegrationFacilities = () => this.http.get<any>(config.apiUrl + `facility/view/integration/facilities`)

  initIntegrationRefresh = (facilityId) => this.http.get<any>(config.apiUrl + `facility/view/integration/refresh/${facilityId}`)

  initForm = () => new FormGroup({
    name: new FormControl(null, Validators.required),
    racfNumber: new FormControl(null, Validators.required),
	ppaRACFNumber: new FormControl(null),
    address: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    suburb: new FormControl(null, Validators.required),
    postcode: new FormControl(null, Validators.required),
    phone: new FormControl(null),
    fax: new FormControl(null),
    email: new FormControl(null),
    medegroupId: new FormControl(null, Validators.required),
    beds: new FormControl(null, Validators.required),
    pharmacistId: new FormControl(null, Validators.required),
    integrationId: new FormControl(null),
    integrationType: new FormControl(0),
    integrationKey: new FormControl(null),
    integrationUser: new FormControl(null),
    integrationPassword: new FormControl(null),
    integrationToken: new FormControl(null),
  });
}
