import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ValueTransformer } from '@angular/compiler/src/util';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../../../../core/services/user.service';
import { OuterSubscriber } from 'rxjs/internal/OuterSubscriber';
import { NoteService } from '../../../../core/services/note.service';
import { NoteFormComponent } from '../note-form/note-form.component';
import { MatDialog, PageEvent } from '@angular/material';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'b-notes-table',
  templateUrl: './notes-table.component.html',
  styleUrls: ['./notes-table.component.scss']
})
export class NotesTableComponent implements OnInit, OnDestroy {
  @Input() entityId;
  @Input() entityName;
  @Input() canFilter;

  noteDeleteSubject: Subscription;
  noteAddSubject: Subscription;

  notes: any[] = [];
  totalNotes = 0;
  page = 0;
  rows = 10;
  displayedColumns: string[] = ['insertDate', 'insertName', 'type', 'text', 'attachment', 'actions'];

  typeOptions: any[] = [];
  currentUserId: string;
  filter: any[] = [1];

  constructor(
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    private userService: UserService,
    private service: NoteService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // this.currentUserId = this.userService.getSession().currentUserId;
    this.typeOptions = [
      {name: 'All', value: -1},
      ...this.translate.instant('OPTIONS.NOTE_TYPES')
    ]

    this.noteAddSubject = this.service.onNoteUpdated.subscribe(() => {
      console.log("Got note update")
      this.getNotes();
    });
    this.noteDeleteSubject = this.service.onNoteDeleted.subscribe(() => this.getNotes());

    this.getNotes();
  }

  ngOnDestroy() {
    this.noteAddSubject.unsubscribe();
    this.noteDeleteSubject.unsubscribe();
  }

  deleteNote(noteId) {
    this.service.removeNote(noteId);
  }

  onFilterChange(value: any[]) {
    this.filter = value;
    console.log("now filter == ", this.filter)
    this.getNotes();
  }

  editNote(note) {
    let dialogRef = this.dialog.open(NoteFormComponent, {
      width: '500px',
      data: {
        note,
        entityName: this.entityName
      },
    });
  }

  getNotes() {
    this.service.findNotes({id: this.entityId, rows: this.rows, page: this.page, filter: this.filter}).subscribe(response => {
      this.totalNotes = response.total;
      this.notes = response.notes;
      this.cdRef.detectChanges();
    })
  }

  onViewAttachment(fileId) {
    Storage.get(fileId + '.pdf', { level: 'public' })
      .then ( (result) => {
        console.log("PDF ==== ", result)
        console.log(result);
        window.open(result.toString(), '_blank');

      })
      .catch( (err) => {
        console.log('odf err', err);
      });
  }

  public onPage(event?: PageEvent) {
    this.page = event.pageIndex;
    this.rows = event.pageSize;
    this.getNotes();
    return event;
  }

}
