import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgxPermissionsService } from 'ngx-permissions';
import { roles } from '../../../../../config/roles';

@Component({
  selector: 'm-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnInit {

  role;
  roles = [];

  constructor(
    public dialogRef: MatDialogRef<RoleSelectorComponent>,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {
    this.roles = Object.keys(roles);
    console.log('Current Role: ', this.permissionsService.getPermissions());
  }

  save = () => {
    console.log('saving perms', this.role);
    this.permissionsService.flushPermissions();
    if (this.role) {
      console.log(roles[this.role])
      this.permissionsService.loadPermissions(roles[this.role]);
    }
    console.log('Updated Perms: ', this.permissionsService.getPermissions());
    this.dialogRef.close({ changed: true })
  }

  cancel = () => {
    this.dialogRef.close()
  }
}
