import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config/app.config';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  InitEdit = (userId) => this.http.get<any>(config.apiUrl + `user/view/edit/initialise/${userId}`)

  InitCreate = () => this.http.get<any>(config.apiUrl + `user/view/create/initialise`)

  getUsers = () => this.http.get<any>(config.apiUrl + 'user/view/list/grid')

  update = (values) => this.http.post<any>(config.apiUrl + 'user/view/edit/save', values)

  create = (values) => this.http.post<any>(config.apiUrl + 'user/view/create/save', values)

  delete = (userId) => this.http.delete<any>(config.apiUrl + `user/view/edit/delete/${userId}`)

  checkExists = (email) => this.http.get<any>(config.apiUrl + `user/view/create/exists/${email}`)

  activate = (values) => this.http.post<any>(config.apiUrl + 'user/view/create/activate', values)

  globalSearch = (string) => this.http.get<any>(`${config.apiUrl}user/view/search/${string}`);

  getUserForm = () => new FormGroup({
    userId: new FormControl(null),
    typeId: new FormControl(null),
    statusId: new FormControl(1),
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)])),
    // roleIds: new FormControl([]),
    facilityIds: new FormControl([], Validators.required),
	ppaUserId: new FormControl([]),
	ppaApiKey:  new FormControl([]),
	mrnNumber: new FormControl([])
  })

}
