import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { config } from '../../config/app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  patientWaslinked = false;

  syncingPatients = false;
  syncingScripts = false;

  syncPatinetsChanged = new Subject<any[]>();
  syncScriptsChanged = new Subject<any>();

  syncStatusChanged = new Subject<String>();

  dispenseScriptListChanged = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getIntegrationPatients = (): void => {
    this.syncingPatients = true;
    this.syncStatusChanged.next("Syncing");

    this.http.get(config.apiUrl + 'integration/patients').subscribe((patients: any[]) => {
      var nextPatients = [];

      patients.map(p => {
        nextPatients.push({
          linked: p.linked,
          canLink: p.canLink,
          score: p.matchPercentage,
          suremedName: p.suremedPatient ? p.suremedPatient.firstName + ' ' + p.suremedPatient.lastName : '',
          suremedFacilityId: p.suremedPatient ? p.suremedPatient.facilityId: undefined,
          suremedDOB: p.suremedPatient ? p.suremedPatient.dateOfBirth : '',
          suremedAddress: p.suremedPatient ? p.suremedPatient.address : '',
          suremedMedicare: p.suremedPatient ? p.suremedPatient.medicareNumber : '',
          suremedFacility: p.suremedPatient ? p.suremedPatient.facilityName : '',
          dispenseName: p.dispensaryPatient ? p.dispensaryPatient.firstName + ' ' + p.dispensaryPatient.lastName : '',
          dispenseDOB: p.dispensaryPatient ? p.dispensaryPatient.dateOfBirth : '',
          dispenseAddress: p.dispensaryPatient ? p.dispensaryPatient.address : '',
          dispenseMedicare: p.dispensaryPatient ? p.dispensaryPatient.medicareNumber : '',
          dispenseFacility: p.dispensaryPatient ? p.dispensaryPatient.facilityName : '',
          link: p.matchPercentage > 99,
          matched: p.suremedPatient && p.dispensaryPatient,
          suremedPatientId: p.suremedPatient ? p.suremedPatient.suremedPatientId : null,
          dispensePatientId: p.dispensaryPatient ? p.dispensaryPatient.dispensePatientId : null,
          dispenseSyncPatientId: p.dispensaryPatient ? p.dispensaryPatient.dispenseSyncPatientId : null
        });
      })
      this.syncPatinetsChanged.next(nextPatients);
      this.syncingPatients = false;
      if (!this.syncingScripts) {
        this.syncStatusChanged.next(null);
      }
    })
  }

  getIntegrationScripts = () => {
    this.syncingScripts = true;
    this.syncStatusChanged.next("Syncing");
    this.syncScriptsChanged.next(null);
    this.http.get(config.apiUrl + 'integration/scripts').subscribe((scripts: any) => {
      if (!this.syncingPatients) {
        this.syncStatusChanged.next(null);
      }
      this.prepareScripts(scripts);
      this.syncingScripts = false;
    })
  }

  findScripts = values => {
    this.syncingScripts = true;
    this.syncStatusChanged.next("Syncing");
    this.syncScriptsChanged.next(null);
    this.http.post(config.apiUrl + 'integration/findScripts', values).subscribe((scripts: any) => {
      if (!this.syncingPatients) {
        this.syncStatusChanged.next(null);
      }
      this.prepareScripts(scripts);
      this.syncingScripts = false;
    })
  }

  prepareScripts(scripts) {
    scripts.scripts.map(script => {
      script.originalQuantity = parseInt(script.quantity);
      script.quantity = parseInt(script.quantity);
    })
    this.syncScriptsChanged.next(scripts)
  }

  linkPatient = (values) => this.http.post(config.apiUrl + 'integration/linkPatient', values)

  unlinkPatient = (values) => this.http.post(config.apiUrl + 'integration/unlinkPatient', values)

  unQueueScript = (values) => this.http.post(config.apiUrl + 'integration/unqueueScript', values)

  getUnmappedContainers = (vendorPatientId, scriptId) => this.http.get(config.apiUrl + 'integration/findUnmappedContainers/' + vendorPatientId + '/' + scriptId)

  findScriptsByPatient = (patientId, mppId, includeOwing) => this.http.get(config.apiUrl + 'integration/findScripts/' + patientId + '/' + mppId + '/' + includeOwing)

  processSyncSript = (values) => this.http.post(config.apiUrl + 'integration/processSyncSript', values)

  refreshScripts = () => this.http.get(config.apiUrl + 'integration/refreshscripts')

  findOwingScripts = (patientId, mppId) => this.http.get(config.apiUrl + 'integration/findScripts/' + patientId + '/' + mppId)

  // refreshScripts = () => {
  //   this.syncingScripts = true;
  //   this.syncStatusChanged.next("Syncing");
  //   this.syncScriptsChanged.next(null);
  //   this.http.get(config.apiUrl + 'integration/refreshscripts').subscribe((scripts: any) => {
  //     if (!this.syncingPatients) {
  //       this.syncStatusChanged.next(null);
  //     }
  //     this.prepareScripts(scripts);
  //     this.syncingScripts = false;
  //   })
  // }

}
