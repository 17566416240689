export const roles = {
  1: [  // Mederev admin
    'listFacility', 'editFacility', 'menuFacility',
    'listScoop', 'viewScoop', 'editScoop', 'viewFacilityScoop',
    'viewUser', 'editUser',
    'viewFacilityNote', 'editFacilityNote',
    'viewPractice', 'editPractice', 'listPractice',
    'viewDoctor', 'editDoctor',
    'viewResident', 'editResident',
    'viewConsent', 'uploadConsent',
    'viewRacfStaff', 'editRacfStaff',
    'viewTraining', 'editTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewInfoblast', 'editInfoblast',
    'viewRmmr', 'uploadRmmr', 'cancelRmmr',
    'viewRmmrNote', 'editRmmrNote', 'viewRmmrReport',
    'exportCsv','viewClaim'
  ],
  2: [  // Mederev pharmacist
    'listFacility', 'menuFacility',
    'viewScoop', 'viewFacilityScoop',
    'viewFacilityNote', 'editFacilityNote',
    'viewPractice', 'listPractice',
    'viewResident', 'editResident',
    'viewConsent', 'uploadConsent',
    'viewInfoblast',
    'viewTraining', 'viewTrainingReport',
    'viewRmmr', 'uploadRmmr', 'cancelRmmr',
    'viewRmmrNote', 'viewRmmrReport',
    'exportCsv',
    // PROBS WRONG BUT THEY ASKED FOR IT LOL
    'viewDoctor', 'viewRacfStaff',
    'viewHelp',
  ],
  3: [  // Mederev Super User
    'listFacility', 'editFacility', 'menuFacility',
    'listScoop', 'viewScoop', 'editScoop', 'viewFacilityScoop',
    'viewUser', 'editUser',
    'viewFacilityNote', 'editFacilityNote',
    'viewPractice', 'editPractice', 'listPractice',
    'viewDoctor', 'editDoctor',
    'viewResident', 'editResident',
    'viewConsent', 'uploadConsent',
    'viewRacfStaff', 'editRacfStaff',
    'viewTraining', 'assignTraining', 'editTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewRmmr', 'uploadRmmr', 'cancelRmmr', 'viewRmmrReport',
    'viewRmmrNote', 'editRmmrNote',
    'viewMedegroup', 'editMedegroup',
    'viewInfoblast', 'editInfoblast',
    'filterMedegroup',
    'exportCsv','viewClaim'
  ],
  10: [ // Facility Manager
    'listFacility', 'menuFacility',
    'viewFacilityNote', 'editFacilityNote',
    'viewScoop', 'viewFacilityScoop',
    // 'viewPractice',
    'viewResident',
    'viewConsent', 'uploadConsent',
    'viewRacfStaff', 'editRacfStaff',
    // permission to prevent certain users from being created / viewed
    'viewStaffLimited', 'editStaffLimited',
    'viewTraining', 'assignTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewInfoblast',
    'viewRmmr', 'cancelRmmr', 'viewRmmrReport',
    'viewRmmrNote',
    'exportCsv',
    'viewHelp',
  ],
  11: [ // Clinical Manager
    'listFacility', 'menuFacility',
    'viewFacilityNote', 'editFacilityNote',
    'viewScoop', 'viewFacilityScoop',
    // 'viewPractice',
    'viewResident',
    'viewConsent', 'uploadConsent',
    'viewRacfStaff', 'editRacfStaff',
    // permission to prevent certain users from being created / viewed
    'viewStaffLimited', 'editStaffLimited',
    'viewTraining', 'assignTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewInfoblast',
    'viewRmmr', 'cancelRmmr', 'viewRmmrReport',
    'viewRmmrNote',
    'exportCsv',
    'viewHelp',
  ],
  12: [ // RACF Staff
    'menuFacility',
    'viewFacilityNote', 'editFacilityNote',
    'viewTraining', 'viewPracticalCompetency',
    'viewInfoblast',
    'exportCsv',
    'viewHelp',
  ],
  13: [ // Facility Receptionist
    'menuFacility',
    // 'viewRacfStaff', 'editRacfStaff',
    // permission to prevent certain users from being created / viewed
    'viewStaffLimited', 'editStaffLimited',
    'exportCsv',
    'viewHelp',
  ],
  14: [ // Facility Clinical Educator/Quality User
    'listFacility', 'menuFacility',
    'viewRacfStaff', 'editRacfStaff',
    'viewTraining', 'assignTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewInfoblast',
    'exportCsv',
    'viewHelp',
  ],
  15: [ // Facility Admin (Referred to as Mederev Admin)
    'listFacility', 'editFacility', 'menuFacility',
    'listScoop', 'viewScoop', 'editScoop', 'viewFacilityScoop',
    'viewFacilityNote', 'editFacilityNote',
    'viewPractice', 'editPractice', 'listPractice',
    'viewDoctor', 'editDoctor',
    'viewResident', 'editResident',
    'viewConsent', 'uploadConsent',
    'viewRacfStaff', 'editRacfStaff',
    'viewTraining', 'assignTraining', 'viewTrainingReport', 'viewPracticalCompetency',
    'viewSelfTraining',
    'viewInfoblast',
    'viewRmmr', 'viewRmmrReport',
    'viewRmmrNote',
    'exportCsv',
  ],
  20: [ // Practice Manager
    'viewFacilityScoop',
    'editFacilityNote',
    'viewInfoblast',
    'viewHelp',
    'viewPractice'
  ],
  21: [ // Practice Receptionist
    'viewFacilityScoop',
    'editFacilityNote',
    'viewInfoblast',
    'viewHelp',
    'viewPractice',
  ],
  22: [ // Practice Doctor
    // 'listFacility',
    'viewFacilityScoop',
    'editFacilityNote',
    // 'viewResident',
    'viewInfoblast',
    'viewHelp',
  ],
}
