import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../../config/app.config';

@Injectable()
export class QuickSearchService {
	constructor(private http: HttpClient) {}

	search = (values): Observable<any> => {
		if(!values.text || values.text.length === 0) {
      return Observable.create(observer => {
				observer.next([]);
				observer.complete();
			});
		}
		else {
			return this.http.get(config.apiUrl + 'dashboard/find/' + values.text)

			// return this.http.get(config.apiUrl + 'dashboard/search/' + text)
		}
	}


}
