import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BImageComponent } from './b-image.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BImageComponent],
  exports: [BImageComponent]
})
export class BImageModule { }
