import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-input-header-cell',
  templateUrl: './input-header-cell.component.html',
  styleUrls: ['./input-header-cell.component.scss']
})
export class InputHeaderCellComponent implements OnInit {
  @Input() canSort = false;
  @Input() placeholder: string;
  @Input() matHeaderCellDef: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

  onValueChange(value) {
    this.valueChange.emit([value]);
  }

}
