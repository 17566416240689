import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ImageCropDialogComponent } from '../../dialog/image-crop-dialog/image-crop-dialog.component';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import jspdf from 'jspdf';
import { LoadingDialogService, LoadingDialogState } from '../../../../core/services/loading-dialog.service';
import { PDFDocument } from 'pdf-lib'
import { decode } from 'base64-arraybuffer';

@Component({
  selector: 'b-form-photo-input-field',
  templateUrl: './form-photo-input-field.component.html',
  styleUrls: ['./form-photo-input-field.component.scss']
})
export class FormPhotoInputFieldComponent implements OnInit {
  @Input() label;
  @Input() formControl: FormControl;
  @Input() value: any;
  @Input() type = "file"
  @Input() accept: string[] = ['.pdf', '.png', '.jpg', '.jpeg'];
  @Input() multiple = true;

  @Output() onPhotoChange: EventEmitter<any> = new EventEmitter();
  @Output() onRemovePhoto: EventEmitter<any> = new EventEmitter();

  @Output() onFilesChange: EventEmitter<any> = new EventEmitter();

  @Input() initialUrl: string;
  @Input() showIcon = true;

  @Input() disabled = false;

  file: any;
  @Input() files: any[] = [];

  loading = false;

  constructor(
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingDialogService
  ) { }

  ngOnInit() {
    if (this.initialUrl) {
      this.getBase64Image(this.initialUrl, function (data) {
        this.file = { base64: data };
        this.cdRef.detectChanges();
      });
    }
  }

  public reset() {
    this.onRemove();
  }

  dropped(event) {
    if (!this.fileDropValid(event)) {
      this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', 'Invalid file type selected. Please use the following file types: ' + this.accept.join(', '));
      return;
    }

    if (this.type === 'image') {
      const fileEntry = event[0].fileEntry as FileSystemFileEntry;
      const reader = new FileReader();

      fileEntry.file(file => {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.showEdit(reader.result.toString());
          this.cdRef.detectChanges();
        };
      });
    }
    else if (this.multiple === false) {
      this.prepareSingleFile(event[0]);
    }
    else if (this.type === 'file') {
      this.loading = true;
      this.createPdfFile(event);
    }
  }

  fileDropValid(files: NgxFileDropEntry[]) {
    var invalidFile = files.find(f => {
      return !this.accept.includes(('.' + f.fileEntry.name.split('.')[f.fileEntry.name.split('.').length - 1]).toLowerCase());
    });
    return !invalidFile;
  }

  fileUp(event) {
  }

  onEditClick() {
    this.showEdit(this.file.base64);
  }

  showEdit(image) {
    if (this.initialUrl) {

    }
    var dialogRef = this.dialog.open(ImageCropDialogComponent, {
      data: {
        imageSrc: image,
      },
      width: '500px',
    })
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.file = result.image;
          this.cdRef.detectChanges();
          this.onPhotoChange.emit(this.file);
        }
      })
  }

  fileOver(event) {
  }

  fileLeave(event) {
  }

  async removeFile(index) {
    this.files.splice(index, 1)
    this.cdRef.detectChanges();

    if (this.multiple) {
      if (this.files.length === 0) {
        this.loading = false;
        this.onFilesChange.next(null);
        return;
      }
      var base64 = await this.mergePdfs();
      this.loading = false;
      this.onFilesChange.next(this.convertBase64ToBlob('data:application/pdf;base64,' + base64));
    }
  }

  onRemove() {
    if (this.initialUrl) {
      this.onRemovePhoto.emit();
    } else {
      this.file = null;
      this.files = [];
      this.cdRef.detectChanges();
    }
  }

  public getBase64Image(imgUrl, callback) {

    var xhr = new XMLHttpRequest()
    xhr.open("GET", imgUrl);
    xhr.responseType = "blob";
    xhr.send();
    xhr.addEventListener("load", function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.addEventListener("loadend", function () {
      });
    });

  }


  createPdfFile(files) {
    if (files.length > 0) {
      var promises: Promise<any>[] = [];

      files.forEach(droppedFile => {
        promises.push(this.preparePDF(droppedFile))
      });
      Promise.all(promises).then(async files => {
          let types = [];
        [...this.files, ...files].map(f => types.indexOf(f.type) === -1 && types.push(f.type));
  
        if (types.length > 1) {
          this.loadingService.showCompletion(LoadingDialogState.Error, 'Error', 'OK', 'Image and Pdf file types cannot be merged...')
          this.loading = false;
  
        } else {
          this.files = [...this.files, ...files];
          if (this.files.length === 1 && files[0].type === 'pdf') {
            const pdf = this.convertBase64ToBlob(files[0].base64);
            this.loading = false;
            this.onFilesChange.next(pdf);
          } else if (this.files.length > 1 && files[0].type === 'pdf') {
            var g = await this.mergePdfs();
            this.loading = false;
            this.onFilesChange.next(this.convertBase64ToBlob('data:application/pdf;base64,' + g));
  
          } else {
            let pdf = this.generatePdf();
            this.loading = false;
            this.onFilesChange.next(pdf);
          }
        }
      })
    }
  }

  async mergePdfs() {
    return new Promise(async (resolve, reject) => {
      const pdfDoc = await PDFDocument.create();
      var pdfs = [];
      for (var i = 0; i < this.files.length; i++) {
        var file = this.files[i];
        console.log('b64 == ', file.base64)
        var pdfBytes = decode(file.base64);// this.base64ToArrayBuffer(f.base64);
        pdfs.push(await PDFDocument.load(pdfBytes, {ignoreEncryption: true}));
      }

      await Promise.all(pdfs.map(async (pdfToMerge) => {
        const copiedPagesA = await pdfDoc.copyPages(pdfToMerge, pdfToMerge.getPageIndices());
        copiedPagesA.forEach((page) => pdfDoc.addPage(page));
      }));

      resolve(pdfDoc.saveAsBase64());

    })
  }

  prepareSingleFile(drippedFile) {

    const fileEntry = drippedFile.fileEntry as FileSystemFileEntry;
    const reader = new FileReader();

    fileEntry.file(file => {
      var fileExt = file.name.split('.').pop();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var base64 = reader.result.toString();

        this.files = [
          {
            ...file,
            base64: base64,
            name: file.name,
            date: file.lastModified,
            type: fileExt,
            path: drippedFile.relativePath,
            blob: this.convertBase64ToBlob(base64),
            // path
          }
        ]

        this.onFilesChange.emit(this.files);
      };
    });
  }

  preparePDF(droppedFile) {
    return new Promise((res, rej) => {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      const reader = new FileReader();

      fileEntry.file(file => {
        var fileExt = file.name.split('.').pop();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var base64 = reader.result.toString();

          if (fileExt.toLowerCase() === 'pdf') {
            res({
              ...file,
              base64: base64,
              name: file.name,
              date: file.lastModified,
              type: 'pdf',
              path: droppedFile.relativePath
              // path
            })
          } else {
            this.getImageDimensions(base64).then((dim: any) => {
              res({
                base64: base64,
                name: file.name,
                date: file.lastModified,
                width: dim.w,
                height: dim.h,
                type: 'image',
                path: droppedFile.relativePath
              })
            })
          }
        };
      });
    })
  }

  generatePdf() {
    let pdf = new jspdf('p', 'px', 'a4'); // A4 size page of PDF 

    for (var i = 0; i < this.files.length; i++) {

      if (i !== 0) {
        pdf.addPage();
      }

      var file = this.files[i];
      if (file.type === 'pdf') {

      } else {
        var imgData = this.files[i].base64

        var pageWidth = 406.46;
        var pageHeight = 591.4175;

        var imgHeight = file.height;
        var imgWidth = file.width;

        if (imgHeight > pageHeight || imgWidth > pageWidth) {
          var ratio;

          if (pageHeight / imgHeight > pageWidth / imgWidth) {
            ratio = pageWidth / imgWidth
          } else {
            ratio = pageHeight / imgHeight;
          }

          imgWidth = ratio * imgWidth;
          imgHeight = ratio * imgHeight;

        }

        pdf.addImage(imgData, 'PNG', 20, 20, imgWidth, imgHeight);
        pdf.output('blob');
      }
    }

    var blobPdf = new Blob([pdf.output('blob')], { type: 'application/pdf' })
    return blobPdf;
  }

  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
