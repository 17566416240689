import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NoteService } from '../../../../core/services/note.service';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'm-view-note-dialog',
  templateUrl: './view-note-dialog.component.html',
  styleUrls: ['./view-note-dialog.component.scss']
})
export class ViewNoteDialogComponent implements OnInit {
  title = 'Note'
  loading = true;
  noteId;
  note: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ViewNoteDialogComponent>,
    public translate: TranslateService,
    private service: NoteService,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {
    this.noteId = config.noteId;
  }

  ngOnInit() {
    this.loading = true;

    this.service.getNote(this.noteId).subscribe(note => {
      this.note = note;
      this.loading = false;
      if (note.text.includes('* Delivery Note *')) {
        this.title = 'Delivery Note'
      }
      this.cdRef.detectChanges();
    })
  }

  viewAttachment() {
    Storage.get(this.note.fileId + '.pdf', { level: 'public' })
    .then((result) => {
      window.open(result.toString(), '_blank');

    })
    .catch((err) => {

    });

  }
}
