import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { RoleDetailComponent } from './pages/role-detail/role-detail.component';
import { RoleListComponent } from './pages/role-list/role-list.component';

const routes: Routes = [
  {
    path: "",
    component: RoleListComponent,
  },
  // {
  //   path: "new",
  //   component: RoleDetailComponent,
  // },
  // {
  //   path: ":id",
  //   component: RoleDetailComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }
