export const DrugClasses = [
  { value: 1, name: "Alimentary System" },
  { value: 2, name: "Cardiovascular System" },
  { value: 3, name: "Central Nervous System" },
  { value: 4, name: "Analgesia" },
  { value: 5, name: "Musculoskeletal System" },
  { value: 6, name: "Endocrine and Metabolic Disorders" },
  { value: 7, name: "Genitourinary System" },
  { value: 8, name: "Infections and Infestations" },
  { value: 9, name: "Neoplastic Disorders" },
  { value: 10, name: "Immunology" },
  { value: 11, name: "Respiratory System" },
  { value: 12, name: "Allergic Disorders" },
  { value: 13, name: "Ear, Nose and Oropharynx" },
  { value: 14, name: "Eye" },
  { value: 15, name: "Skin" },
  { value: 16, name: "Surgical Preparations" },
  { value: 17, name: "Diagnostic Agents" },
  { value: 18, name: "Contraceptive Agents" },
  { value: 19, name: "Nutrition" },
  { value: 20, name: "Poisoning, Toxicity and Drug Dependence" },
  { value: 21, name: "Vitamins and Minerals" },
  { value: 22, name: "Herbal and other complementary medicines" },
]