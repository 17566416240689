import {
  Component,
  OnInit,
  HostBinding,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { LayoutConfigService } from '../../../../core/services/layout-config.service';
import * as objectPath from 'object-path';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/services/user.service';

@Component({
  selector: 'm-topbar',
  templateUrl: './topbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit, AfterViewInit {
  @HostBinding('id') id = 'm_header_nav';
  @HostBinding('class')
  classes = 'm-stack__item m-stack__item--fluid m-header-head';

  @Input() searchType: any;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private userService: UserService
  ) {
    this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
      const config = model.config;
      this.searchType = objectPath.get(config, 'header.search.type');
    });
  }

  showSearch = false;
  ngOnInit(): void {
    this.showSearch = this.userService.getSession().currentUserTypeId === 3;
  }

  ngAfterViewInit(): void { }

  openGuide = () => {
    let guideType = ''
    const userType = this.userService.getUserType()
    if (userType) {
      switch (userType) {
        case 'Clinical Educator/Quality User':
          guideType = 'Clinical Educator Training'
          break
        case 'Facility Manager':
          guideType = 'CMFM Training'
          break
        case 'Facility Clinical Manager':
          guideType = 'CMFM Training'
          break
        case 'Practice Doctor':
          guideType = 'Doctor Training'
          break
        case 'Mederev Pharmacist':
          guideType = 'Pharmacist Training'
          break
        case 'Facility Staff':
          guideType = 'RACF Staff Training'
          break
        case 'Practice Receptionist':
          guideType = 'Receptionist Training'
          break
        case 'Facility Receptionist':
          guideType = 'Receptionist Training'
          break
        default:
          break
      }

      // Open guide in new tab
      if (guideType != '')
        window.open(`../../../../../assets/guide/${guideType}/story.html`, '_blank')
    }
  }
}
