export const strings = {
  DAYS: 'Days',
  ACTIVE: 'Active',
  FACILITY_NAME: 'Facility Name',
  SHORT_NAME: 'Short Name',
  FACILITY_TYPE: 'Facility Type',
  RAC_ID: 'RAC-ID',
  STREET: 'Street',
  SUBURB: 'Suburb',
  STATE: 'State',
  TYPE: 'Type',
  POST_CODE: 'Post Code',
  CODE: 'Code',
  PHONE: 'Phone',
  MOBILE: 'Mobile',
  FAX: 'Fax',
  EMAIL: 'Email',
  WEBSITE: 'Website',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  FILL_TYPE: 'Fill Type',
  CARD_FORMAT_MANUAL: 'Card Format',
  LABEL_FORMAT_MANUAL: 'Label Format',
  CARD_FORMAT_AUTO: 'Card Format-Automation',
  PACKING_RUN_ORDER: 'Packing Run Order',
  PACKING_CYCLE: 'Packing Cycle',
  PACK_START_DAY: 'Pack Start Day',
  STARTING_SLOT: 'Starting Slot',
  PACKING_DAY: 'Packing Day',
  DELIVERY_DAY: 'Delivery Day',
  ORDER_CUT_OFF_TIME: 'Order Cut-Off Time',
  PACK_FILLING_FEE: 'Pack Filling Fee',
  PACK_START_CARD: 'Pack Start Date At Top Of Card',
  ENABLE_VIRTUAL_PILL: 'Enable Virtual Pill Inventory',
  DATA_FILE_ONLY: 'Data File Only For Automation',
  ENABLE_REPORTING: 'Enable Reporting Consent',
  SLOT1: 'Slot 1',
  SLOT2: 'Slot 2',
  SLOT3: 'Slot 3',
  SLOT4: 'Slot 4',
  PACKING_MANIFEST_SORT_ORDER: 'Packing Manifest Sort Order',
  UNIT_NAME: 'Unit Name',
  DESCRIPTION: 'Description',
  BED_COUNT: 'Bed Count',
  BED_TYPE: 'Bed Type',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  DATE: 'Date',
  ATTACHMENT: 'Attachment',
  NOTE: 'Note',
  PROFILE_STATUS: 'Profile Status',
  SIMILAR_NAME_ALERT: 'Similar Name Alert',
  TITLE: 'Title',
  PREFERRED_NAME: 'Preferred Name',
  DATE_OF_BIRTH: 'Date Of Birth',
  SEX: 'Sex',
  HEIGHT: 'Height (cm)',
  WEIGHT: 'Weight (kg)',
  SWALLOWING_DIFFICULTIES: 'Swallowing Difficulties',
  CRUSH_MEDICINES: 'Crush Medicines',
  COGNITIVE_IMPAIRMENT: 'Cognitive Impairment',
  DEXTERITY_DIFFICULTIES: 'Dexterity Difficulties',
  RESISTIVE_TO_MEDICATIONS: 'Resistive To Medications',
  NILL_BY_MOUTH: 'Nill By Mouth',
  SELF_ADMINISTERS: 'Self Administers',
  OTHER: 'Other',
  MEDICARE_NUMBER: 'Medicare Number',
  MONTH: 'Month',
  YEAR: 'Year',
  DVA: 'DVA',
  SAFETY_NET_NUMBER: 'Safety Net Number',
  URN: 'URN',
  NDSS_NUMBER: 'NDSS Number',
  PATHOLOGY_REF: 'Pathology Ref',
  PATIENT_MAPPING: 'Dispense System Mapping',
  MEDICARE_EXPIRY_MONTH: 'Medicare Expiry (Month)',
  MEDICARE_EXPIRY_YEAR: 'Medicare Expiry (Year)',
  CONCESSION: 'Concession',
  CONCESSION_EXPIRY_YEAR: 'Concession Expiry (Year)',
  PATIENT_GROUP: 'Patient Group',
  UNIT: 'Unit',
  ROOM: 'Room',
  BED: 'Bed',
  PROFILE_ALERT: 'Profile Alert',
  NO_BRAND_SUBSTITUTION: 'No Brand Substitution',
  CONSENT_GIVEN: 'Medication Review Consent',
  DAA_ELIGIBLE: 'DAA Claim',
  BRAND_SUBSTITUTION: 'Brand Substitution',
  MEDICATION_REVEIW_CONSENT: 'Medication Review Consent',
  DAA_CLAIM: 'DAA - 7CPA Claim',
  ENTER_PRESCRIBER_NUMBER: 'Enter prescriber number',
  NAME: 'Name',
  PRESCRIBER_NUMBER: 'Prescriber Number',
  ALLERGIES: 'Allergies',
  MEDICAL_CONDITIONS: 'Medical Conditions',
  DRUG: 'Drug',
  REACTION: 'Reaction',
  REQUIREMENT: 'Requirement',
  PRINT_ON_LABEL: 'Print On Label',
  BREAKFAST: 'Bfast',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  PRN: 'PRN',
  ANTIBIOTICS: 'Antibiotics',
  S8: 'S8',
  COLOUR: 'Colour',
  NURSING_DIRECTOR: 'Nursing Director',
  DIRECTOR: 'Director',
  DETAILS: 'Details',
  ADDRESS: 'Address',
  CONTACT: 'Contact',
  ADD_NEW_NOTE: 'Add New Note',
  PACKING: 'Packing',
  STANDARD_DOSAGE_TIMES: 'Dosage Times',
  MEDICATION_TYPES: 'Medication Types',
  SPECIFIC_MEDICATIONS: 'Specific Medications',
  ADD_SPECIFIC_MEDICATIONS: 'Add Specific Medication',
  DRUG_NAME: 'Drug Name',
  NO_UNITS: 'There are no units...',
  ADD_NEW_UNIT: 'Add New Unit',
  UNITS: 'Units',
  UNIT_DOSE_CARD_COLOURS: 'Unit-Dose Card Colours',
  NOTES: 'Notes',
  EDIT_FACILITY: 'Edit Facility',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  FACILITIES: 'Facilities',
  ADD_NEW_FACILITY: 'Add New Facility',
  LAST_RUN: 'Last Run',
  NEXT_RUN: 'Next Run',
  PACK_ON: 'Pack On',
  DELIVER_ON: 'Deliver On',
  ORDER_CUT_OFF: 'Order Cut Off',
  ACTIONS: 'Actions',
  NEW_FACILTIY: 'New Facility',
  EDIT: 'Edit',
  ADD_NEW_PATIENT: 'Add New Patient',
  PHOTO: 'Photo',
  STATUS: 'Status',
  RESIDENT_NO_REVIEW_REASON: 'Reason for no RMMR*',
  GROUP: 'Group',
  LOCATION: 'Location',
  CREATED: 'Created',
  CYCLE: 'Cycle',
  START_DATE: 'Start Date',
  PATIENTS: 'Patients',
  TOTAL_PACKS: 'Total Packs',
  PACKS_FILLED: 'Packs Filled',
  MODE: 'Mode',
  ADD_NEW_PACK_RUN: 'Create a Pack Run',
  DRUGS: 'Drugs',
  GENDER: 'Gender',
  FACILITY_NOTE_TYPE: 'Document Type',
  BRAND: 'Brand',
  GENERIC: 'Generic',
  PACK_SIZE: 'Pack Size',
  ADD_OWN_DRUG: 'Add own drug or item',
  HEALTH_PROFESSIONALS: 'Doctors & Allied Health',
  ADD_NEW_HEALTH_PROFESSIONAL: 'Add New Doctor or Allied Health',
  NEW_DOCTOR_OR_ALLIED_HEALTH: 'New Doctor or Allied Health',
  HISTORY: 'History',
  LAST_PACKED: 'Last Packed',
  ERRORS: 'Errors',
  PACKS_CHECKED: 'Packs Checked',
  PHARMACY: 'Pharmacy',
  ADD_STAFF_MEMBER: 'Add Staff Member',
  STAFF: 'Staff',
  ITEMS: 'Items',
  REJECTED: 'Rejected',
  ORDERS: 'Orders',
  PATIENT: 'Patient',
  ACTIONED: 'Actioned',
  ACTIONED_BY: 'Actioned By',
  CHANGE_REQUESTS: 'Change Requests',
  PRESCRIPTION: 'Prescription',
  PRESCRIPTIONS: 'Prescriptions',
  PRESCRIPTION_LOG: 'Prescription Log',
  DISPENSE: 'Dispense',
  UPDATE_LOG: 'Update Log',
  VIRTUAL_PILL_INVENTORY: 'Virtual Pill Inventory',
  DISPENSE_DATE: 'Dispense Date',
  NUMBER_OF_RECORDS: 'Number Of Records',
  BALANCES_UPDATED: 'Balances Updated',
  DISPENSE_REPORT: 'Dispense Report',
  CREATION_DATE: 'Creation Date',
  DOCTOR: 'Doctor',
  NUMBER_OF_PATIENTS: 'Number Of Patients',
  NUMBER_OF_PRESCRIPTIONS: 'Number Of Prescriptions',
  REPORT: 'Report',
  GENERATE_LIST: 'Generate List',
  CREATE_REPORT: 'Create Report',
  SELECT_ALL: 'Select All',
  DESELECT_ALL: 'Deselect All',
  TRAY: 'Tray',
  DIRECTIONS: 'Directions',
  QUANTITY: 'Quantity',
  SCRIPT: 'Script',
  SUPPLY_DATE: 'Supply Date',
  REQUIRED: 'Required',
  PRINT: 'Print',
  NUMBER_OF_DAYS: 'Number Of Days',
  DISPENSE_TRIGGER: 'Dispense Trigger',
  DISPENSE_TRIGGER_DAYS: 'Dispense Trigger (days)',
  CATEGORY: 'Category',
  QTY_TO_DISPENSE: 'Qty To Dispense',
  CLEAR_DAYS: 'Clear Days',
  PILL_COUNT: 'Pill Count',
  QTY_RQD: 'Qty Rqd',
  BARCODE: 'Barcode',
  BARCODES: 'Barcodes',
  PROFILES: 'Profiles',
  CREATE_MEDICATION_ORDER: 'Create Medication Order',
  CREATE_GENERAL_ORDER: 'Create General Order',
  COMMUNITIES: 'Communities',
  DOCTOR_NAME_OR_NUMBER: 'Search by doctor name or prescriber number',
  ADD_EXISTING_DOCTOR: 'Add Existing Doctor',
  CREATE_NEW_DOCTOR: 'Add New Doctor',
  PRIMARY_CARER: 'Primary Carer',
  DOCTORS_AND_ALLIED_HEALTH: 'Doctors & Allied Health',
  ALLERGY_TYPE: 'Allergy Type',
  MEDICAL_CONDITION: 'Medical Condition',
  ADD_NEW_CONTACT: 'Add New Contact',
  ADD_NEW_REQUIREMENT: 'Add New Requirement',
  ENTER_CODE: 'Enter the code which was sent to your email',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  RELATIONSHIP: 'Relationship',
  PAYS_ACCOUNTS: 'Pays Accounts',
  MAIN_CONTACT: 'Main Contact',
  UPDATED: 'Updated',
  UPDATED_BY: 'Updated By',
  CAUSE: 'Cause',
  ROLES_AND_PERMISSIONS: 'Roles & Permissions',
  ADD_NEW_ROLE: 'Add New Role',
  CREATE_NEW_ROLE: 'Create New Role',
  ROLE_TEMPLATE: 'Role Template',
  PROFILE: 'Profile',
  ADD_DRUG: 'Add Drug',
  PROFILE_REPORT: 'Profile Report',
  PACKING_REPORT: 'Packing Report',
  PACK: 'Pack',
  EDIT_PATIENT: 'Edit Patient',
  END_DATE: 'End Date',
  SUSPENDED: 'Suspended',
  START: 'Start',
  DOSAGE: 'Dosage',
  STREAMLINE_AUTHORITY: 'Streamline Authority',
  MONDAY_SHORT: 'M',
  TUESDAY_SHORT: 'T',
  WEDNESDAY_SHORT: 'W',
  THURSDAY_SHORT: 'T',
  FRIDAY_SHORT: 'F',
  SATURDAY_SHORT: 'S',
  SUNDAY_SHORT: 'S',
  TRAY_CATEGORY: 'Tray / Category',
  END: 'End',
  PILL_BALANCE: 'Pill Balance',
  ROUTE: 'Route',
  SCRIPT_NUMBER: 'Script Number',
  PRN_DOSAGE: 'PRN Qty Per Blister',
  UNIT_OF_MEASURE: 'Unit Of Measure',
  QUANTITY_PACKED: 'Quantity Packed',
  MAX_DOSE_24_HOURS: 'Max Dose 24 Hours',
  FREQUENCY: 'Frequency',
  BFAST: 'Bfast',
  FIRST_DAY_OF_MONTH: 'First Day Of Month',
  EVERY_X_DAYS: 'Every X Days',
  DOSAGE_DAYS: 'Dosage Days',
  CUSTOM_PATTERN: 'Custom Pattern',
  CUSTOM_PATTERN_DESCRIPTION: 'Start entering your pattern from the first column. A blank box denotes the end of a pattern.',
  EVERY_X_MONTHS: 'Every X Months',
  DATE_OF_THE_MONTH: 'Date Of The Month',
  DISPENSE_MEDICATION: 'Dispense Medication',
  SUSPEND_DRUG_FROM_PACKING: 'Suspend Drug From Packing',
  SUBSTITUTION: 'Substitution',
  SUBSTITUTE: 'Substitute',
  SAVE: 'Save',
  SAVE_AND_ADD_NEW: 'Submit & New',
  TRAYS: 'Trays',
  SEPERATE_SACHET_ROLE: 'Seperate Sachet Role',
  TRAY_SUMMARY: 'Tray Summary',
  NUMBER_OF_MEDICATIONS: 'Number Of Medications',
  SLOT_1: 'Slot 1',
  SLOT_2: 'Slot 2',
  SLOT_3: 'Slot 3',
  SLOT_4: 'Slot 4',
  DOSAGES: 'Dosages',
  USER: 'User',
  DRUG_STATUS: 'Drug Status',
  UPDATED_ON: 'Updated On',
  BALANCE_BEFORE: 'Balance Before',
  BALANCE_AFTER: 'Balance After',
  SCRIPT_NO: 'Script No',
  PACK_RUN: 'Pack Run',
  ACTION: 'Action',
  VPI: 'VPI',
  PACK_HISTORY: 'Pack History',
  PACK_START: 'Pack Start',
  PACK_END: 'Pack End',
  PACKING_TYPE: 'Packing Type',
  LABEL_BARCODE: 'Label Barcode',
  PACK_STATUS: 'Pack Status',
  FILLED_BY: 'Filled By',
  CHECKED_BY: 'Checked By',
  DELIVERED_BY: 'Delivered By',
  PACK_TRACK: 'Pack Track',
  DELIVERY_BARCODE: 'Delivery Barcode',
  PACK_NUMBER: 'Pack Number',
  TOTE_NAME: 'Tote Name',
  DISPATCHED: 'Dispatched',
  DELIVERED: 'Delivered',
  RECEIVED: 'Received',
  RECEIVED_BY: 'Reveived By',
  LAST_SUPPLIED: 'Last Supplied',
  ORDERED_BY: 'Ordered By',
  APPROVED_BY: 'Approved By',
  ITEM: 'Item',
  ACCESS: 'Access',
  ROLES: 'Roles',
  AVAILABILITY: 'Availability',
  APPROVE_CHANGES: 'Approve Edit',
  APPROVE_CREATE: 'Approve Add',
  APPROVE_DELETE: 'Approve Delete',
  SUBMIT_CREATION: 'Submit Creation',
  SUBMIT_EDITS: 'Submit Edit',
  SUBMIT_CHANGES: 'Submit Edit',
  SUBMIT_CREATION_REQUEST: 'Submit Add Request',
  SUBMIT_EDIT_REQUEST: 'Submit Edit Request',
  EDIT_DRUG: 'Edit Drug',
  DENY_CHANGES: 'Deny Changes',
  DENY_CREATE: 'Deny Create',
  DENY_DELETE: 'Deny Delete',
  NEW_DRUG: 'New Drug',
  ADDRESS_AND_CONTACT: 'Address & Contact',
  PRIVILEGES: 'Privileges',
  ADD_USER: 'Add User',
  USERNAME: 'Username',
  ADD_NEW_COMMUNITY: 'Add New Community',
  BRAND_NAME: 'Brand Name',
  GENERIC_NAME: 'Generic Name',
  MANUFACTURER: 'Manufacturer',
  DRUG_CLASS: 'Drug Class',
  DRUG_SUBCLASS: 'Drug Subclass',
  PACKING_OPTIONS: 'Packing Options',
  CAN_BE_PACKED: 'Can Be Packed',
  IS_PACKED_ALONE: 'Is Packed Alone',
  DEBLISTER_ORIENTATION: 'Deblisterer Orientation',
  DEBLISTER_TENSION: 'Deblisterer Tension',
  PREFERRED_BRAND: 'Preferred Brand',
  BRAND_NAME_HINT: 'Name, Strength, Form, Pack Size and Pack Type ',
  SHORT_NAME_HINT: 'Brand name, strength, form ',
  STRENGTH: 'Strength',
  FORM: 'Form',
  I_CARE: 'I Care',
  CREATE_PACK_RUN: 'Create Pack Run',
  REGULAR: 'Regular',
  SHORT_TERM: 'Short Term',
  S11: 'S11',
  VARIABLE_DOSE: 'Variable Dose',
  MACHINE: 'Machine',
  PACK_START_DATE: 'Pack Start Date',
  PRINT_ORDER: 'Print Order',
  CREATE_RUN: 'Create Run',
  MEDICATION: 'Medication',
  PACKING_MANIFEST: 'Packing Manifest',
  BILLING_REPORT: 'Billing Report',
  PACK_FROM: 'Pack From',
  PACK_TO: 'Pack To',
  PACK_CYCLE: 'Pack Cycle',
  MANUAL_AUTOMATED: 'Manual/Automated',
  MEDICATION_CATEGORIES: 'Medication Categories',
  CARD_FORMATS: 'Card Formats',
  FILL_TYPES: 'Fill Types',
  NUMBER_OF_PATIENTS_PACKED: 'Number Of Patients Packed',
  NUMBER_OF_CARDS: 'Number Of Cards',
  DELIVERY_STATUS: 'Delivery Status',
  REPORT_TYPE: 'Report Type',
  REPORTS: 'Reports',
  NO_FURTHER_ACTION: 'Carer notified',
  CARER_NOTIFIED: 'Doctor notified',
  DOCTOR_NOTIFIED: 'Facility manager notified',
  FACILITY_MANAGER_NOTIFIED: 'Patient notified',
  PATIENT_NOTIFIED: 'Relative notified',
  RELATIVE_NOTIFIED: 'No further action',
  INCIDENT: 'Incident',
  REPORTED_BY: 'Reported By',
  INITIAL_ACTION: 'Initial Action',
  INCIDENT_CAUSE: 'Incident Cause',
  INCIDENT_TYPE: 'Incident Type',
  INVESTIGATION_NOTES: 'Investigation Notes',
  REVIEWED_BY: 'Reviewed By',
  OUTCOMES_AND_RECOMMENDATIONS: 'Outcomes And Recommendations',
  GENERAL_ORDER: 'General Order',
  MEDICATION_ORDER: 'Medication Order',
  APPROVE_ALL: 'Approve All',
  PROCESS: 'Process',
  VOID: 'Void',
  CREATE: 'Create',
  ITEM_TYPE: 'Item Type',
  ROUTE_OF_ADMINISTRATION: 'Route Of Administration',
  ORDER: 'Order',
  GUIDED_FILL: 'Guided Fill',
  DRUG_USAGE: 'Drug Usage',
  CONSUMABLES: 'Consumables',
  PACKS: 'Packs',
  PRODUCT_CODE: 'Product Code',
  PACKS_REQUIRED: 'Packs Required',
  QUANTITY_REQUIRED: 'Quantity Required',
  QUANTITY_REMAINING: 'Quantity Remaining',
  NUMBER: 'Number',
  DATES: 'Dates',
  SEQUENCE: 'Sequence',
  SCAN_DRUG: 'Scan a Drug',
  SCAN_LABEL: 'Scan a Label',
  IMAGE: 'Image',
  PACK_QTY: 'Pack Qty',
  TOTAL_QTY: 'Total Qty',
  FILL_CHECK: 'Fill/Check',
  MARK_AS_PACKED: 'Mark As Packed',
  MARK_AS_CHECKED: 'Mark As Checked',
  PARK: 'Park',
  EXIT: 'Exit',
  RESET: 'Reset',
  UNPACK: 'Un-pack',
  CARD_FORMAT: 'Card Format',
  BLISTER: 'Blister',
  REQUIRED_FOR_NEXT_PACK: 'Required for next pack',
  NO_LONGER_REQUIRED: 'No longer required',
  TOP_DRUG: 'Fast Mover',
  CHECK: 'Check',
  GUIDED_CHECK: 'Guided Check',
  ERROR: 'Reason',
  REASON: 'Reason',
  PACK_DRUG: 'Pack/Drug',
  FLIP_VIEW: 'Flip View',
  REQUIRED_NEXT: 'Next',
  REQUIRED_LATER: 'Later',
  FINISHED: 'Put Away',
  QTY: 'Qty',
  OMNICELL_CODE: 'Omnicell Code',
  BOX_QTY: 'Box Qty',
  UNIT_PRICE: 'Unit Price',
  EXTENDED_PRICE: 'Extended Price',
  PURCHASE_ORDER: 'Purchase Order',
  SUBMIT_ORDER: 'Submit Order',
  PHONE_NUMBER: 'Phone Number',
  DRUG_TYPES: 'Drug Types',
  CURRENTLY_PACKING: 'Currently Packing',
  PACK_DAYS: 'Pack Days',
  PROFILE_CHANGES: 'Profile Changes',
  CREATE_NEW_CONTACT: 'Add New Contact',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  CUSTOM_PATTERN_CYCLE: 'Custom Pattern Cycle',
  SUPPLY_METHOD: 'Supply Method',
  SEARCH_ALLERGIES: 'Search for allergies',
  SEARCH_MEDICAL_CONDITIONS: 'Search for medical conditions',
  PACKED_UNTIL: 'Packed Until',
  SUB_CATEGORY: 'Sub Category',
  DOSAGE_TYPE: 'Dosage Type',
  NUMBER_OF_ADMIN_TIMES: 'Enter number of administration times',
  ADMIN_TIME: 'Admin Time',
  IDENTIFIERS: 'Identifiers',
  SELECT_PHARMACY: 'Select a Pharmacy',
  SPECIAL_INSTRUCTIONS: 'Special Instructions',
  PROFILE_CHANGE_NOTE: 'Profile Change Note',
  INDICATION: 'Indication',
  AUTOMATED_BLISTER: 'Automated Blister',
  MANUAL_BLISTER: 'Manual Blister',
  MANUAL_CARD_FORMAT: 'Manual Card Format',
  AUTOMATED_CARD_FORMAT: 'Automated Card Format',
  EDIT_TRAY: 'Edit Tray',
  ENTER_A_NOTE: 'Enter a note',
  NOTE_ENTITY_PLACEHOLDER: 'Leave blank or enter a community, doctor or patient',
  MONTHLY_SET_DATES: 'Dates of Month',
  DATE_OF_MONTH: 'Date of Month',
  LABEL_FORMAT: 'Label Format',
  PATIENT_COUNT: 'Patient Count',
  ADD_SPECIFIC_REQUIREMENT: 'Add Specific Requirement',
  SEARCH_REQUIREMENTS: 'Search for requirements',
  SELECT: 'Select',
  OPTIONS: 'Options',
  CATEGORIES: 'Categories',
  LAST_PACK_START_END: 'Last full pack start / end dates',
  PACK_EXPIRY_DATE: 'Pack Expiry Date',
  SLOT: 'Slot',
  SUB_CATEGORIES: 'Sub Categories',
  SUBMIT_ADD_REQUEST: 'Submit Add Request',
  CONFIRM_EDIT: 'Confirm Edit',
  CONFIRM_DELETE: 'Confirm Delete',
  CANCEL_DELETE: 'Cancel Delete',
  CONFIRM_ADD: 'Confirm Add',
  VIEW_PROFILE: 'View Profile',
  DELETE_DRUG: 'Delete Drug',
  PENDING_EDIT: 'Pending Edit',
  PENDING_CREATE: 'Pending Add',
  PENDING_DELETE: 'Pending Delete',
  APPROVE_ADD: 'Approve Add',
  APPROVE_EDIT: 'Approve Edit',
  CHECKING: 'Checking',
  EDIT_COMMUNITY: 'Edit Community',
  NEW_COMMUNITY: 'New Community',
  UNIT_WARD: 'Unit/Ward',
  NUMBER_OF_BEDS: 'Number Of Beds',
  PACK_AT_HUB: 'Pack At Hub',
  ADD_CHANGE_REQUEST: 'Add Change Request',
  CONTAINS: 'Contains',
  SUB_PACK_SIZE: 'Sub-Pack Size',
  SHORT_DESCRIPTION: 'Short Description',
  CLASS: 'Class',
  SUB_CLASS: 'Sub Class',
  DANGEROUS_DRUG: 'Dangerous Drug',
  IMPREST: 'Imprest',
  CANNOT_BE_PACKED: 'Cannot Be Packed',
  SEDATION_WARNING: 'Sedation Warning',
  DO_NOT_CRUSH: 'Do Not Crush',
  IMPREST_DRUG: 'Imprest Drug',
  DELIVER_PACKS: 'Deliver Packs',
  MARK_AS_DELIVERED: 'Mark As Delivered',
  SCAN_A_LABEL: 'Scan A Label',
  RETURN: 'Return',
  PACK_SIZE_FOR_PACKING: 'Pack Size For Packing',
  PACK_ALONE: 'Pack Alone',
  LAST_UPDATED: 'Last Updated',
  FORMULARY: 'Formulary',
  GENERIC_SUBSTITUTION: 'Generic Substitution',
  SELECT_PATIENT_GROUPS: 'Select Patient Groups',
  SUBSTITUTE_TO: 'Substitute To',
  SUBSTITUTE_FROM: 'Substitute From',
  SUBSCRIPTION: 'Subscription',
  PHARMACY_NAME: 'Pharmacy Name',
  PBS_APPROVAL_NUMBER: 'PBS Approval Number',
  DEFAULT_PACKING_MODE: 'Default Packing Mode',
  PACK_EXPIRY_DATE_DAYS: 'Pack Expiry Date (Days)',
  PRN_EXPIRY_DATE_DAYS: 'PRN Expiry Date (Days)',
  VPI_DISPENSE_TRIGGER_DAYS: 'VPI Dispense Trigger Days',
  LABEL_FOIL_ALIGNMENT: 'Label/Foil Alignment',
  TOP: 'Top',
  LEFT: 'Left',
  ENABLE_FOIL_PRINTING: 'Enable Foil Printing',
  PACK_START_TIME: 'Pack Start Time',
  DRUG_START_TIME: 'Drug Start Time',
  DOB: 'DOB',
  SCORE: 'Score',
  MEDICARE: 'Medicare',
  DISPENSE_SYSTEM_PATIENT_LINKING: 'Dispense System Patient Linking',
  LINK: 'Link',
  HUB: 'Hub',
  BILLING: 'Billing',
  PRICING: 'Pricing',
  MY_PATIENT_GROUPS: 'My Patient Groups',
  AVATAR: 'Avatar',
  RESET_PASSWORD: 'Reset Password',
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD: 'New Password',
  VALIDATION_REPORT: 'Validation Report',
  DISPENSE_LOG: 'Dispense Log',
  MANUAL_SCRIPTS: 'Manual Scripts',
  SENT_TO_DISPENSE: 'Sent to Dispense',
  DISPENSED: 'Dispensed',
  PRE_DISPENSE_REPORT: 'Pre-Dispense Report',
  RX_DRUG: 'Rx Drug',
  RX_NUMBER: 'Rx Number',
  RX_PRESCRIBED_DATE: 'Rx Prescribed Date',
  RX_DISPENSED_DATE: 'Rx Dispensed Date',
  RX_QUANTITY: 'Rx Quantity',
  REPEATS: 'Repeats',
  REPORT_ORDER: 'Report Order',
  '7CPA': '7CPA',
  REFERRAL_DELIVERY_METHOD: 'Referral Delivery Method',
  RMMR_DELIVERY_METHOD: 'RMMR Delivery Method',
  REFERRAL_LETTER_TEMPLATE: 'Referral Letter Template',
  COMMUNITY: 'Community',
  LAST_PROFILE_CHANGE: 'Last Profile Change',
  PATIENT_NAME: 'Patient Name',
  UNIT_ROOM_BED: 'Unit Room Bed',
  FACILITY: 'Facility',
  DIRECTION: 'Direction',
  SUPPORT: 'Support',
  PREFERRED_CONTACT_NUMBER: 'Preferred Contact Number',
  SEVERITY: 'Severity',
  ADD_EXISTING_USER: 'Add Existing User',
  ADD_NEW_USER: 'Add New User',
  USERS: 'Users',
  ROOM_BED: 'Room/Bed',
  RUN: 'Run',
  EMAIL_OR_MOBILE: 'Email / Mobile',
  CUSTOM_PATTERN_TIP: 'The custom pattern is used to capture a repeating dosage sequence. Start entering your pattern from the first column. A blank box denotes the end of a pattern. The pattern will commence from the start date of the drug.',
  NEW_USER: 'New User',
  ALERTS: 'Alerts',
  EVENTS: 'Events',
  PACK_RUNS: 'Pack Runs',
  PRINT_OPTIONS: 'Print Options',
  DRUG_CATEGORY: 'Drug Category',
  INCIDENTS: 'Incidents',
  REPORT_INCIDENT: 'Report Incident',
  TOP_DRUGS: 'Top Drugs',
  TOP_MARGIN: 'Top Margin',
  LEFT_MARGIN: 'Left Margin',
  EXPIRY_DATE_CALCULATION: 'Expiry Date Calculation',
  SECTIONS: 'Sections',
  ACTIVE_PATIENTS_ONLY: 'Active Patients Only',
  FROM_DATE: 'From Date',
  TO_DATE: 'To Date',
  SEARCH: 'Search',
  SEARCH_BY: 'Search By',
  DRUG_USAGE_DAYS: 'Drug Usage Days',
  DISPENSE_SYSTEM_INTEGRATION: 'Dispense System Integration',
  PILLS: 'Pills',
  PILL_TOTAL: 'Pill Total',
  SCRIPTS: 'Scripts',
  RX_NO: 'Rx No',
  ORIGINAL_RX: 'Original Rx',
  RX_STATUS: 'Rx Status',
  RX_DATE: 'Rx Date',
  MODIFIED: 'Modified',
  RX_PATIENT: 'Rx Patient',
  REPEATS_LEFT: 'Repeats Left',
  DEFERRED: 'Deferred',
  OWING: 'Owing',
  QUEUED_DERIVED: 'Queued Derived',
  VPI_STATUS: 'VPI Status',
  PACK_EXPIRY_DAYS: 'Pack Expiry Days',
  UNLINK: 'Unlink',
  DISPENSE_SYSTEM: 'Dispense System',
  RX_QTY: 'Rx Qty',
  SOFTWARE: 'Software',
  SUREMED: 'Mederev',
  CONCESSION_DVA_CARD: 'Concession / DVA Card',
  NUMBER_OF_MEDS: 'Number of Meds',
  VPI_ADJ: 'VPI Adj',
  VIEW: 'View',
  PRESCRIBED: 'Prescribed',
  PBS: 'PBS',
  AUTHORITY: 'Authority',
  QUEUED_SCRIPTS: 'Queued Scripts',
  QTY_DISPENSED: 'Qty Dispensed',
  BALANCE: 'Balance',
  LAST_DISPENSE_DATE: 'Last Dispense Date',
  QUEUED: 'Queued',
  EXPORT: 'Export',
  UN_QUEUE: 'Un Queue',
  DOSE: 'Dose',
  DOSING_REGIMEN: 'Dosing Regimen',
  MEDICARE_OR_DVA: 'Medicare or DVA File Number',
  SUSPENDED_DATE: 'Suspended Date',
  RX_REPEAT: 'Rx Repeat',
  SUPPLIED: 'Supplied',
  DRUG_DESCRIPTION_HINT: 'Physical description of the drug eg white round',
  SELECT_A_DOCTOR: 'Select A Doctor',
  MEDICATIONS: 'Medications',
  PRN_QTY: 'PRN Qty',
  WEEK: 'Week',
  DATE_RANGE: 'Date Range',
  PATIENT_GROUPS: 'Patient Groups',
  NURSE_FACILITIES: 'Nurse Facilities',
  REFRESH_SCRIPTS: 'Refresh Scripts',
  ADD_NOTE: 'Add Note',
  PACKED_COUNT: 'Packed Count',
  PRN_COUNT: 'PRN Count',
  NON_PACKED_COUNT: 'Non Packed Count',
  TOTAL: 'Total',
  QTY_PACKED: 'Qty Packed',
  PACKS_USED: 'Packs Used',
  COST_PER_PILL: 'Cost Per Pill',
  SOH: 'SOH',
  NUMBER_OF_PACKS: 'Number Of Packs',
  PACKING_COST: 'Packing Cost',
  MEDICATION_TOTAL: 'Medication Total',
  PACK_RUN_TOTAL: 'Pack Run Total',
  DRUG_TYPE: 'Drug Type',
  UNIT_COST: 'Unit Cost',
  BILLING_NAME: 'Billing Name',
  BILLING_ABN: 'Billing ABN',
  SACHET_COST_PER_PATIENT: 'Sachet Cost Per Patient',
  TIME_ZONE: 'Time-Zone',
  IMPORT_DRUG_UNIT_COSTS: 'Import Drug Unit Costs',
  UPLOAD_FILE: 'Upload File',
  Quantity: 'Quantity',
  COST_PER_CARD: 'Cost Per Card',
  INDICATIONS: 'Indications',
  SERVICE_FEES: 'Service Fees',
  DRUG_PRICING: 'Drug Pricing',
  FILLED: 'Filled',
  CHECKED: 'Checked',
  SCRIPT_REPORT: 'Script Report',
  TOTAL_REPEATS: 'Total Repeats',
  REPORT_BY: 'Report By',
  DEFAULT_MACHINE: 'Default Machine',
  DEFAULT_MODE: 'Default Mode',
  PACKING_MODE: 'Packing Mode',
  FORMULARY_DRUG: 'Formulary Drug',
  PACKABLE: 'Packable',
  PHARMACIST_TO_INSERT: 'Pharmacist To Insert',
  DRUG_EXPIRY_DAYS: 'Drug Expiry Days',
  FORMULARY_GROUPS: 'Formulary Groups',
  ADD_FORMULARY_GROUP: 'Add Formulary Group',
  FORMULARY_GROUP: 'Formulary Group',
  SPOKE_PHARMACIES: 'Spoke Pharmacies',
  COMBINE_S8: 'Combine S8',
  CTPP: 'CTPP'
}