import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { config } from '../../config/app.config';
import { SubheaderService } from './layout/subheader.service';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  onNoteDeleted = new Subject<String>();
  onNoteUpdated = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  uploadFileToUrl(file, fileName): Observable<any> {
    let headers = new HttpHeaders();        
    const formData = new FormData();

    formData.append(fileName, file)

    return this.http.post(config.apiUrl + 'note/uploadNote', formData, {headers:headers});
  }

  mergePdfs = (files, fileName) => {
    let headers = new HttpHeaders();        
    const formData = new FormData();

    for(var i = 0; i < files.length; i ++) {
      console.log("appending == ", files[i]);
      formData.append('file_' + i + '.pdf', files[i]);
    }

    return this.http.post(config.apiUrl + 'note/mergePdf', formData, {headers:headers});

  }

  saveNote = (values) => this.http.post(config.apiUrl + 'note/saveNote', values);

  removeNote = (id) => {
    this.http.delete(config.apiUrl + 'note/remove/' + id).subscribe(() => {
      this.onNoteDeleted.next(id)
    })
  };

  getNotes = (values: any): Observable<any> => this.http.post(config.apiUrl + 'note/find', values);

  getNote = (noteId: string): Observable<any> => this.http.get(config.apiUrl + 'note/find/' + noteId);

  findNotes = (values): Observable<any> => this.http.post(config.apiUrl + 'note/findNotes', values);
}