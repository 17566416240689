import { Component, OnInit, Input } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-form-textarea-field',
  templateUrl: './form-textarea-field.component.html',
  styleUrls: ['./form-textarea-field.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class FormTextareaFieldComponent implements OnInit {
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() controlName: string;
  @Input() formName: string;
  @Input() arrayName: string;
  @Input() type = 'text';
  @Input() translatePlaceholder;
  @Input() readonly = false;

  @Input() fg: FormGroup; // form group will be preferred way going forward, facility form needs to be changed

  constructor(
    private formGroupDirective: FormGroupDirective,
    public translate: TranslateService) { 
  }

  ngOnInit() {
  }

  getPlaceholder(){
    if (this.translatePlaceholder){
      return this.translate.instant('TEXT.' + this.translatePlaceholder);
    }
    if (this.placeholder) {
      return this.placeholder
    }
    return null;
  }
}
