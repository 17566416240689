import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-subtitile-cell',
  templateUrl: './subtitile-cell.component.html',
  styleUrls: ['./subtitile-cell.component.scss']
})
export class SubtitileCellComponent implements OnInit {

  @Input() header: string;
  @Input() subtitle: string;
  @Input() translateHeader: string;
  @Input() translateSubtitle: string;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.setHeader();
    this.setSubtitle();
  }

  setHeader(){
    if (this.translateHeader){
      this.header = this.translate.instant('TEXT.' + this.translateHeader);
    }
  }

  setSubtitle(){
    if (this.translateSubtitle){
      this.subtitle = this.translate.instant('TEXT.' + this.translateSubtitle);
    }
  }


}
