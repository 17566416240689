import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'b-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  @Input() checked = false;
  @Input() label;
  @Input() disabled = false;
  //@Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChange(value: boolean) {
    console.log("swith = ", value)
    //this.change.emit(value)
  }
}
