import { ErrorHandler, Injectable, NgZone, ChangeDetectorRef, Injector, ApplicationRef } from "@angular/core";
import Elmahio from '../../../node_modules/elmah.io.javascript/dist/elmahio';
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../content/shared/dialog/confirm-dialog/confirm-dialog.component";
import { LoadingDialogState } from "./services/loading-dialog.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import pkg from '../../../package.json'
import { UserService } from "./services/user.service";

@Injectable({
  providedIn: 'root'
})

export class SuremedErrorHandler implements ErrorHandler {
  logger: any;
  errors: string[] = [];
  errorModalShowing = false;

  constructor(
    public dialog: MatDialog,
    private zone: NgZone,
    private injector: Injector,
  ) {
    //   this.logger = new Elmahio({
    //     apiKey: '7eeb8e0eb1904ad595b093a3b1af241c',
    //     logId: environment.elmah,
    //   }).on('message',(msg) => {
    //     msg.version = pkg.version;
    //     msg.data = [];

    //     const userService = this.injector.get(UserService);
    //     var userSession = userService.getSession();

    //     if (userSession) {
    //       msg.user = userSession.currentUserName;
    //       msg.source = userSession.pharmacyName;

    //       Object.keys(userSession).sort((a,b) => a.localeCompare(b)).map(sessionKey => {
    //         msg.data.push({key: sessionKey, value: userSession[sessionKey] ? userSession[sessionKey].toString() : 'unknown'});
    //       })
    //     }
    // });
  }

  handleError(error) {
    console.log("ERR = ", error);
    // if (typeof error === 'string') {
    //   this.logger.error('String error - ' + error, new Error());
    // }
    // else if (error.name === "HttpErrorResponse") {
    //   try {
    //     this.logger.error('Http - ' + error.status + ' - ' + error.error.target.__zone_symbol__xhrURL, new Error());
    //   } catch (cError) {
    //     this.logger.error('Http - ' + error.status, new Error());
    //   }
    // }
    // else {
    //   if (error && error.message) {
    //     try {
    //       this.logger.error(error.message, error);
    //     } catch (cError) {
    //       this.logger.error(error.message, new Error());
    //     }
    //   } else {
    //     try {
    //       this.logger.error('Error in application', error);
    //     } catch (cError) {
    //       this.logger.error('Error in application', new Error());
    //     }
    //   }
    // }

    this.dialog.closeAll();

    if (window.location.href.includes('guided')) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Something has gone wrong.',
          state: LoadingDialogState.Warning,
          message: 'A problem has occurred and the page will refresh when you click OK.  Please submit a support form if the problem continues after the refresh.',
          confirmButtonText: "OK",
          confirmButtonColour: "primary",
        },
        width: '500px',
      })

      dialogRef.updatePosition();

      dialogRef.afterClosed().subscribe(result => {
        window.location.reload();
      });
    }


    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Problem',
          state: LoadingDialogState.Warning,
          message: 'The error may be minor and not impact the system, however logging a support request will help us to determine the cause.',
          confirmButtonText: "Ok",
          confirmButtonColour: "primary",
        },
        width: '500px',
      })

      // dialogRef.afterClosed().subscribe(result => {
      //   if (result === 'confirm' && !window.location.href.includes('support') && !window.location.href.includes('login')) {
      //     const router = this.injector.get(Router);
      //     router.navigate(['/support'])  
      //   }
      // }); 
    }

  }
}
