import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  constructor(private http: HttpClient) { }

  deleteBatch = (batchId) => this.http.post(config.apiUrl + 'batch/delete/' + batchId, {})

  getBatches = () => this.http.get(config.apiUrl + 'batch/list/')

}
