import { Component, OnInit, Input, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'm-snack-bar-alert',
  templateUrl: './snack-bar-alert.component.html',
  styleUrls: ['./snack-bar-alert.component.scss']
})
export class SnackBarAlertComponent implements OnInit {
  @Input() messages: string[];
  public onClose = new Subject<any>()

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { 
    this.messages = data.messages
  }

  onCloseMessage(index: number) {
    this.messages.splice(index, 1)
    this.messages.length === 0 && this.onClose.next();
  }

  ngOnInit() {
    console.log("MESSAGES == ", this.messages)
  }

}
