import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-data-table-header',
  templateUrl: './data-table-header.component.html',
  styleUrls: ['./data-table-header.component.scss']
})
export class DataTableHeaderComponent implements OnInit {
  @Input() translateTitle: string;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
