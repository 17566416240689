export interface Role {
	roleId: string;
	name: string;
	updateDate: Date;
	insertDate: Date;
}

export const roleTableColumns = [
	'name'
]


