import {
	Component,
	OnInit,
	HostBinding,
	OnDestroy,
	ElementRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LayoutConfigService } from '../../../../core/services/layout-config.service';
import * as objectPath from 'object-path';
import { Router } from '@angular/router';
import { UserService } from '../../../pages/user/user.service';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'm-header-search',
	templateUrl: './header-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
	onLayoutConfigUpdated: Subscription;
	@HostBinding('class') classes = '';
	@HostBinding('attr.m-quicksearch-mode') attrQuickSearchMode = 'default';

	results: any[] = [];
	searchTerm: string = '';

	constructor(
		private layoutConfigService: LayoutConfigService,
		private el: ElementRef,
		private router: Router,
		private userService: UserService,
		private cdRef: ChangeDetectorRef
	) {
		this.onLayoutConfigUpdated = this.layoutConfigService.onLayoutConfigUpdated$.subscribe(
			model => {
				const config = model.config;
				this.classes =
					// tslint:disable-next-line:max-line-length
					'm-stack__item m-stack__item--middle m-dropdown m-dropdown--arrow m-dropdown--large m-dropdown--mobile-full-width m-dropdown--align-right m-dropdown--skin-light m-header-search m-header-search--expandable m-header-search--skin-';
			}
		);
	}

	debouncer: NodeJS.Timeout = null;
	onInput$: Subject<void> = new Subject();

	onInput = (e) => {
		if (this.debouncer != null) {
			clearTimeout(this.debouncer);
			this.debouncer = null;
		}

		if (!e.length) {
			this.results = [];
			this.cdRef.markForCheck();
			return;
		}

		this.debouncer = setTimeout(() => {
			this.onInput$.next(e.toLowerCase());
		}, 300);
	}

	onClick = (item) => {
		this.results = [];
		this.searchTerm = '';

		const userTypes = {
			1: 'user',
			2: 'user',
			3: 'user',
			10: 'facility-staff',
			11: 'facility-staff',
			12: 'facility-staff',
			13: 'facility-staff',
			14: 'facility-staff',
			15: 'facility-staff',
			20: 'practice-staff',
			21: 'practice-staff',
			22: 'doctor',
		};

		this.router.navigate([userTypes[item.typeId], item.userId]);
	}

	ngOnInit(): void {
		this.onInput$.pipe(switchMap(this.userService.globalSearch)).subscribe(res => {
			this.results = res;
			this.cdRef.markForCheck();
		});
	}

	ngOnDestroy() {
		this.onLayoutConfigUpdated.unsubscribe();
		this.onInput$.unsubscribe();
	}
}
