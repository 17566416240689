import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-form-password-validator',
  templateUrl: './form-password-validator.component.html',
  styleUrls: ['./form-password-validator.component.scss']
})
export class FormPasswordValidatorComponent {
  @Input() errors: any;
  @Input() errorTypes: any;

  constructor( private cdRef: ChangeDetectorRef, public translate: TranslateService) { }

  getErrorMessage(errorString) {
    return this.translate.instant('ERROR.' + errorString);

  }
}
