import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
@Pipe({
  name: 'dateConvert'
})
export class DateConvertPipe implements PipeTransform {
  transform(dateString: string, returnType: string, applyOffset: boolean = true): string {
    if (dateString == null) {
      return '';
    }
    
    const offsetSuffix = applyOffset ? 'Z' : '';
   
    const date: Date = new Date(dateString + offsetSuffix);
    if (returnType === 'date') {
      let formatted_date = moment(date).format('DD/MM/YYYY')     
      return formatted_date;
    } else if (returnType === 'dateTime') {
      let formatted_date = moment(date).format('DD/MM/YYYY hh:mma')
      return formatted_date;
    } else if (returnType === 'time') {
      let formatted_date = moment(date).format('hh:mma')
      return formatted_date;
    }

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var minutesString = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutesString + ' ' + ampm;

    let formatted_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + ' - ' + strTime
    return formatted_date;
  }
}