import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, TemplateRef, ViewChild, } from '@angular/core';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ClassInitService } from './core/services/class-init.service';
import { TranslationService } from './core/services/translation.service';
import * as objectPath from 'object-path';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageConfigService } from './core/services/page-config.service';
import { filter, take } from 'rxjs/operators';
import { SplashScreenService } from './core/services/splash-screen.service';

// language list
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';
import Auth from '@aws-amplify/auth';
import { LoadingDialogService, LoadingDialogState } from './core/services/loading-dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoadingDialogComponent } from './content/shared/dialog/loading-dialog/loading-dialog.component';
import { UserService } from './core/services/user.service';
import { DashboardService } from './core/services/dashboard.service';
import pkg from '../../package.json';
import { ConfirmDialogComponent } from './content/shared/dialog/confirm-dialog/confirm-dialog.component';
import { UserIdleService } from 'angular-user-idle';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthState } from 'aws-amplify-angular/dist/src/providers';
import { AuthService } from './content/pages/auth/auth.service';
import { RoleSelectorComponent } from './content/pages/role/pages/role-selector/role-selector.component';

// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

declare let gtag: Function;

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[m-root]',
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit {
	title = 'Mederev';
	currentVersion = pkg.version;
	hasShownRefreshAlert = false;

	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', { read: ElementRef })
	splashScreen: ElementRef;
	splashScreenImage: string;
	private dialogRef: MatDialogRef<LoadingDialogComponent>;

	constructor(
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private sanitizer: DomSanitizer,
		private translationService: TranslationService,
		private router: Router,
		private pageConfigService: PageConfigService,
		private splashScreenService: SplashScreenService,
		private loadingService: LoadingDialogService,
		public dialog: MatDialog,
		private userService: UserService,
		private dashboardService: DashboardService,
		private userIdle: UserIdleService,
		private amplifyService: AmplifyService,
		private auth: AuthService,
	) {
		// this.router.navigate(['/login'])
		this.amplifyService.authStateChange$.subscribe((authState: AuthState) => {
			console.log("Auth State in app:", authState)
			this.auth.amplifyStateSubject$.next(authState)
		})

		// userService.validateCurrentSession();

		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			// get body class array, join as string classes and pass to host binding class
			setTimeout(() => this.classes = classes.body.join(' '));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.classInitService.setConfig(model);

			this.style = '';
			if (objectPath.get(model.config, 'self.layout') === 'boxed') {
				const backgroundImage = objectPath.get(model.config, 'self.background');
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
				}
			}

			// splash screen image
			this.splashScreenImage = objectPath.get(model.config, 'loader.image');
		});

		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

		// override config by router change from pages config
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.layoutConfigService.setModel({ page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config') }, true);
			});

		this.loadingService.loadingChanged.subscribe(data => this.showLoadingDialog(data));
		this.loadingService.progressChanged.subscribe(progress => this.updateLoadingDialogProgress(progress))
		this.loadingService.completionChanged.subscribe(data => this.showCompletionDialog(data));
		this.loadingService.close.subscribe(() => {
			if (this.dialogRef) {
				this.dialogRef.close()
			}
		});
	}

	ngOnInit(): void {
		this.initAnalytics();
		this.dashboardService.menuStatsUpdated.subscribe(values => values.uiVersion && this.handleVersionChange(values.uiVersion));
	}

	ngAfterViewInit(): void {
		if (this.splashScreen) {
			this.splashScreenService.init(this.splashScreen.nativeElement);
		}
	}

	handleVersionChange(requiredVersion) {
		const currentVersionComponents = this.currentVersion.split('.').map(n => Number(n));
		const requiredVersionComponents = requiredVersion.split('.').map(n => Number(n));

		if (currentVersionComponents[0] < requiredVersionComponents[0] || currentVersionComponents[1] < requiredVersionComponents[1] || currentVersionComponents[2] < requiredVersionComponents[2]) {
			this.hasShownRefreshAlert = true;

			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: {
					title: 'Attention',
					state: LoadingDialogState.Warning,
					message: 'You are using an older version of Mederev, a browser refresh is required',
					confirmButtonText: "OK",
					confirmButtonColour: "primary",
				},
				width: '500px',
			})

			dialogRef.afterClosed().subscribe(result => {
				location.reload();
			});
		}
	}

	showLoadingDialog(data: any) {
		if (this.dialogRef && this.dialogRef.componentInstance) {
			this.dialogRef.componentInstance.updateConfig({
				title: data.title,
				message: data.message ? data.message : undefined,
				loading: true,
				progress: null
			});
		} else {
			this.dialogRef = this.dialog.open(LoadingDialogComponent, {
				data: {
					title: data.title,
					message: data.message ? data.message : undefined,
					loading: true,
					progress: null
				},
				width: '500px',
			})
			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(() => { })
		}
	}

	initAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-6CM7NKDMDY', {
          page_path: event.urlAfterRedirects
        });
      });
  }

	updateLoadingDialogProgress(progress: number) {
		if (this.dialogRef && this.dialogRef.componentInstance) {
			this.dialogRef.componentInstance.updateConfig({
				...this.dialogRef.componentInstance.config,
				progress: (progress * 100).toString(),
			});
		}
	}

	showCompletionDialog(data: any) {
		if (this.dialogRef && this.dialogRef.componentInstance) {
			this.dialogRef.componentInstance.updateConfig({
				state: data.state,
				title: data.title,
				icon: data.icon ? data.icon : undefined,
				message: data.message ? data.message : undefined,
				loading: false,
				confirmButtonText: data.confirmButtonText
			});

		} else {
			this.dialogRef = this.dialog.open(LoadingDialogComponent, {
				data: {
					state: data.state,
					title: data.title,
					icon: data.icon ? data.icon : undefined,
					message: data.message ? data.message : undefined,
					loading: false,
					confirmButtonText: data.confirmButtonText
				},
				width: '500px',
			})
			this.dialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(() => { })

		}
	}

	selectRoles = () => {
		console.log('selecting roles')
		this.dialog.open(RoleSelectorComponent)
	}

	onLoginToSuremed(values) {
		// if (values.error) {
		// 	return;
		// }
		// if (values.hasMultiplePharmacies) {
		// 	this.loadingService.close.next();

		// 	if(values.session && values.session.currentPharmacyId === '00000000-0000-0000-0000-000000000000') {
		// 		this.permissionsService.flushPermissions();
		// 		this.router.navigate(['pharmacies']);
		// 	} else {
		// 		if(this.router.url === '/login') {
		// 			this.router.navigate(['dashboard']);
		// 		} else {
		// 		}
		// 	}
		// 	return;
		// } else {
		// 	if(this.router.url === '/login') {
		// 		this.router.navigate(['/dashboard']);
		// 	} else {
		// 		//this.router.navigate(['/login']);
		// 	}

		// 	this.loadingService.close.next();
		// 	// this.router.navigate(['dashboard']);
		// }
	}
}
