import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config/app.config';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EducationService {
  educationMenuItemSelected: Subject<number> = new Subject();

  selectEducationMenuItem = route => {
    const routeMap = {
      'policy-procedures': 0,
      'compulsory-competencies': 1,
      'practical-compentencies': 2,
      'clinical-training': 3,
    };
    this.location.replaceState(`education/${route}`)
    this.educationMenuItemSelected.next(routeMap[route]);
  }

  constructor(private http: HttpClient, private formBuilder: FormBuilder, public location: Location) { }

  InitDashboard = () => this.http.get<any>(config.apiUrl + `training/view/dashboard/initialise/`)

  create = (values) => this.http.post<any>(config.apiUrl + 'training/view/create/save', values)

  update = (values) => this.http.post<any>(config.apiUrl + 'training/view/edit/save', values)

  InitEdit = (trainingId) => this.http.get<any>(config.apiUrl + `training/view/education/edit/initialise/${trainingId}`);

  getTrainingForm = () => this.formBuilder.group({
    trainingId: [],
    statusId: [],
    title: [null, Validators.required],
    notes: [null, Validators.required],
    fileId: [null, Validators.required],
    fileId2: [],
    typeId: [],
    medegroupIds: [[]],
    facilityIds: [[]]
  })

  getMultiAssignForm = () => this.formBuilder.group({
    userIds: [],
    trainingIds: []
  })
}
