import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { DaterangepickerComponent, DaterangepickerDirective } from "ngx-daterangepicker-material";

@Component({
	selector: "b-form-daterange-field",
	templateUrl: "./form-daterange-field.component.html",
	styleUrls: ["./form-daterange-field.component.scss"],
})
export class FormDaterangeFieldComponent implements OnInit {
	@Input() placeholder: string;
	@Input() hint: string;
	@Input() controlName: string;
	@Input() translatePlaceholder;
	@Input() required = false;
	@Input() fg: FormGroup;
	@Input() disabled = false;

	// @ViewChild(DaterangepickerComponent)
	// datePicker: DaterangepickerComponent;
	// @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;
	// picker: DaterangepickerComponent;

	errorMessage: string;

	formControl;

	ranges = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'This Week': [moment().startOf('week'), moment().endOf('week')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 14 Days': [moment().subtract(13, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	  }

	constructor(
		private form: FormGroupDirective,
		public translate: TranslateService) {
	  }

	  ngOnInit() {
			this.formControl = this.fg.controls[this.controlName];
			// this.picker = this.pickerDirective.picker;
	  }

	  getPlaceholder() {
		if (this.translatePlaceholder) {
		  return this.translate.instant('TEXT.' + this.translatePlaceholder);
		}
		if (this.placeholder) {
		  return this.placeholder
		}
		return null;
	  }

	  getErrorMessage() {
		  return '';
	  }
}
