import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, ErrorHandler, ChangeDetectorRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';

import { config } from './config/app.config';
import 'hammerjs';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeApiService } from './fake-api/fake-api.service';

import { FacilityService } from './content/pages/facility/facility.service';

import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { CoreModule } from './core/core.module';
import { LayoutConfigService } from './core/services/layout-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { PageConfigService } from './core/services/page-config.service';
import { UserService } from './core/services/user.service';
import { CsvService } from './core/services/csv.service';
import { UtilsService } from './core/services/utils.service';
import { ClassInitService } from './core/services/class-init.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { ClipboardService } from './core/services/clipboard.sevice';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LogsService } from './core/services/logs.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { DataTableService } from './core/services/datatable.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import Interactions from '@aws-amplify/interactions';
import Storage from '@aws-amplify/storage';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';

import {
  MatInputModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatTooltipModule,
  GestureConfig,
  MatDialogModule,
  MAT_DATE_LOCALE,
  DateAdapter, MAT_DATE_FORMATS, MatMenuModule, MatChipsModule
} from '@angular/material';
import { ConfirmDialogComponent } from './content/shared/dialog/confirm-dialog/confirm-dialog.component';
import { LoadingDialogComponent } from './content/shared/dialog/loading-dialog/loading-dialog.component';
import { DataTableModule } from './content/shared/dataTable/data-table.module';
import { PdfDialogComponent } from './content/shared/dialog/pdf-dialog/pdf-dialog.component';

import { NgxPrintModule } from 'ngx-print';
import { ImageCropDialogComponent } from './content/shared/dialog/image-crop-dialog/image-crop-dialog.component';

import { NgxCurrencyModule } from "ngx-currency";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  minScrollbarLength: 30,
  // suppressScrollX: true
};

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormDialogComponent } from './content/shared/dialog/form-dialog/form-dialog.component';
import { SwitchModule } from './content/shared/switch/switch.module';
import { NotesModule } from './content/shared/notes/notes.module';
import { SnackBarAlertModule } from './content/shared/snack-bar-alert/snack-bar-alert.module';
import { SnackBarAlertComponent } from './content/shared/snack-bar-alert/snack-bar-alert.component';

// export var options: Partial<IConfig> | (() => Partial<IConfig>);

import { GoogleChartsModule } from 'angular-google-charts';
import { BImageModule } from './content/shared/b-image/b-image.module';
import { PackingValidationDialogComponent } from './content/shared/dialog/packing-validation-dialog/packing-validation-dialog.component';
import { ScriptSelectDialogComponent } from './content/shared/dialog/script-select-dialog/script-select-dialog.component';
import { ViewNoteDialogComponent } from './content/shared/dialog/view-note-dialog/view-note-dialog.component';
import { TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';
import { SuremedErrorHandler } from './core/suremed-error-handler';
import { UserIdleModule } from 'angular-user-idle';
import { UserIdleDialogComponent } from './content/shared/dialog/user-idle-dialog/user-idle-dialog.component';
import { FormComponentModule } from './content/shared/form/form-component.module';
import { UserIdleDialogModule } from './content/shared/dialog/user-idle-dialog/user-idle-dialog.module';
import { CardComponent } from './content/shared/card/card.component';
import { OptionValuePipe } from './core/pipes/option-value.pipe';
import { DateFormatPipe } from 'ngx-moment';
import { AuthService } from './content/pages/auth/auth.service';
import { RoleModule } from './content/pages/role/role.module';
import { RoleSelectorComponent } from './content/pages/role/pages/role-selector/role-selector.component';
import { SupportModule } from './content/pages/support/support.module'
import { DateConvertPipe } from './core/pipes/date-convert.pipe';


@NgModule({
  declarations: [
    AppComponent,
    // ConfirmDialogComponent,
    LoadingDialogComponent,
    PdfDialogComponent,
    ImageCropDialogComponent,
    FormDialogComponent,
    PackingValidationDialogComponent,
    ScriptSelectDialogComponent,
    ViewNoteDialogComponent,
    ConfirmDialogComponent,
    CardComponent,
  ],
  imports: [
    NgxDaterangepickerMd.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    NgxPermissionsModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AmplifyAngularModule,
    DataTableModule,
    NgxPrintModule,
    PerfectScrollbarModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(),
    SwitchModule,
    NotesModule,
    SnackBarAlertModule,
    GoogleChartsModule.forRoot(),
    NgxCurrencyModule,
    BImageModule,
    MatMenuModule,
    MatChipsModule,
    RoleModule,
    SupportModule,
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
    UserIdleModule.forRoot({ idle: 7200, timeout: 28800 }), //2hrs / 8hrs
    UserIdleDialogModule
  ],
  providers: [
    LayoutConfigService,
    LayoutConfigStorageService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    UserService,
    CsvService,
    AuthService,
    OptionValuePipe,
    DateFormatPipe,
    DateConvertPipe,
    UtilsService,
    ClassInitService,
    ClipboardService,
    LogsService,
    QuickSearchService,
    DataTableService,
    FormComponentModule,
    FacilityService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },

    // template services
    SubheaderService,
    HeaderService,
    MenuHorizontalService,
    MenuAsideService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        });
      }
    },
    // {
    // 	provide: APP_INITIALIZER,
    // 	useFactory: (ps: NgxPermissionsService) => function () { return ps.loadPermissions()},
    // 	deps: [LoadService, NgxPermissionsService],
    // 	multi: true
    // }
    { provide: ErrorHandler, useClass: SuremedErrorHandler }
  ],
  exports: [
    TranslateModule,
    DataTableModule,
    SwitchModule,
    CoreModule,
    SnackBarAlertModule,
    NgxCurrencyModule,
    NgxPrintModule,
    BImageModule,
    ViewNoteDialogComponent,
    TimeagoModule,
    FormsModule,
    FormComponentModule
  ],
  entryComponents: [
    LoadingDialogComponent,
    PdfDialogComponent,
    ImageCropDialogComponent,
    SnackBarAlertComponent,
    PackingValidationDialogComponent,
    ScriptSelectDialogComponent,
    ViewNoteDialogComponent,
    ConfirmDialogComponent,
    UserIdleDialogComponent,
    RoleSelectorComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
