import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum LoadingDialogState {
  Success = 0,
  Error,
  Warning,
  Info
}
@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {
  loadingChanged = new Subject<any>();
  progressChanged = new Subject<any>();
  completionChanged = new Subject<any>();
  close = new Subject();

  onConfirmClick = new Subject<any>();
  onCloseClick = new Subject<any>();
  constructor() { }

  showLoading(title: string, message?: string) {
    this.loadingChanged.next({title, message: message ? message : undefined});
  }

  updateProgress(progress: number) {
    this.progressChanged.next(progress);
  }

  showCompletion(state: LoadingDialogState, title: string, confirmButtonText: string, message?: string, icon?: string) {
    this.completionChanged.next({state, title, confirmButtonText, message: message ? message : undefined, icon: icon ? icon : undefined})
  }
}
