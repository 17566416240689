import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[profileItemDosage]'
})
export class ProfileItemDosageDirective {

  regexStr = '^[0-9]\d*$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    console.log("key press event === ", event)
    console.log("native el === ", this.el.nativeElement)

    return (new RegExp(this.regexStr).test(event.key)) || this.el.nativeElement.value.indexOf('.') === -1 && event.key === '.';
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    console.log("paste === ", event)

    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {

      console.log("got event === ", event)
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
      event.preventDefault();

    }, 100)
  }

}
