import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'optionValue'
})
export class OptionValuePipe implements PipeTransform {
  constructor(public translate: TranslateService) {

  }

  transform(optionType: string, value: number): any {
    const options = this.translate.instant('OPTIONS.' + optionType);
    let foundValue = options.find(opt => opt.value === value);
    if (foundValue === undefined) {
      return 'Unknown';
    } else {
      return foundValue.name;
    }
  }

}
