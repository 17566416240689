import {
  Component,
  OnInit,
  HostBinding,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as objectPath from 'object-path';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LayoutConfigService } from '../../../../core/services/layout-config.service';
import { QuickSearchService } from '../../../../core/services/quick-search.service';
import { LoadingDialogService, LoadingDialogState } from '../../../../core/services/loading-dialog.service';
import { QuickSearchDirective } from '../../../../core/directives/quick-search.directive';
@Component({
  selector: 'b-form-search-dropdown',
  templateUrl: './form-search-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSearchDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Input() search: Function;

  active = false;
  onSearch: Subscription;
  onLayoutConfigUpdated: Subscription;
  loading = false;
  @HostBinding('class') classes = '';
  @HostBinding('id') id = 'm_quicksearch';
  @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';
  @HostBinding('attr.m-dropdown-persistent') attrDropdownPersistent = '1';
  @HostBinding('attr.m-quicksearch-mode') attrQuicksearchMode = 'dropdown';
  /**
   * Hack way to call directive programatically for the host
   * https://stackoverflow.com/questions/41298168/how-to-dynamically-add-a-directive
   * The official feature support is still pending
   * https://github.com/angular/angular/issues/8785
   */
  @HostBinding('attr.mQuickSearch')
  mQuickSearchDirective: QuickSearchDirective;
  @ViewChild('searchWrapper') searchWrapper: ElementRef;
  searchText: string;
  results = [];
  constructor(
    private renderer: Renderer2,
    private layoutConfigService: LayoutConfigService,
    private el: ElementRef,
    private quickSearchService: QuickSearchService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private loadingService: LoadingDialogService
  ) {
    this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
      const config = model.config;
      this.classes =
        // tslint:disable-next-line:max-line-length
        'm-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light m-list-search m-list-search--skin-light';
      this.classes +=
        ' m-dropdown--skin-' +
        objectPath.get(config, 'header.search.dropdown.skin');
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.active) {
        let element = e.target;
        if (!this.searchWrapper.nativeElement.contains(element)) {
          if (this.active) {
            document.getElementById('m_quicksearch_close').click();
            this.active = false
            this.cdRef.detectChanges();
            // this.toggleActive();
          }
        } else {
        }
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.onSearch.unsubscribe();
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.mQuickSearchDirective = new QuickSearchDirective(this.el);
      this.mQuickSearchDirective.ngAfterViewInit();
      // listen to search event
      this.onSearch = this.mQuickSearchDirective.onSearch$.subscribe(
        (mQuickSearch: any) => {
          this.loading = true;
          mQuickSearch.showProgress();
          this.quickSearchService.search({
            text: this.searchText,
            patientOnly: false
          }).subscribe(result => {
            this.loading = false;
            this.results = result;
            mQuickSearch.hideProgress();
            this.cdRef.detectChanges();
          });
        }
      );
    });
  }

  onFind() {
    console.log('find')
  }

  onResultClicked(result) {
    this.active = false;
    this.select.emit(result);
    this.results = [];
    this.searchText = '';
    document.getElementById('m_quicksearch_close').click();
  }
  
  onOpen() {
    setTimeout(() => {
      console.log('setting active')
      this.loading = true;
      this.active = true;
      this.results = [];
      this.searchText = ''; 
    }, 100);
  }

  onCloseClick() {
    this.loading = true;
    this.results = [];
    this.searchText = '';
    this.active = false;
  }
}