import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataTableComponent } from '../../../../shared/dataTable/data-table/data-table.component';
import { RoleService } from '../../role.service';
import { roleTableColumns } from '../../models/role.model';

@Component({
  selector: 'm-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent extends DataTableComponent implements OnInit {
  facilities: any[] = [];
  displayedColumns: string[] = roleTableColumns;

  constructor(
    private service: RoleService,
    cdRef: ChangeDetectorRef,
  ) {
    super(cdRef);
  }

  ngOnInit() {
    this.getRoles();
    this.sortKey = 'name';
  }

  getRoles() {
    this.loading = true;
    this.cdRef.detectChanges();
    this.service.getRoles().subscribe(response => {
      this.facilities = response;
      this.allData = response;
      this.sortData(null);
    })
  }
}
