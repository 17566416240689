import { ChangeDetectorRef, Inject, Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, from, Observable, Subject, Subscription } from "rxjs";
import { config } from "../../config/app.config";
import { NgxPermissionsService } from "ngx-permissions";
import { AuthService } from "ngx-auth";
import {
  LoadingDialogService,
  LoadingDialogState,
} from "./loading-dialog.service";
import { roles } from "../../config/roles";
import { Router } from "@angular/router";
import { Auth } from "aws-amplify";
import { Credential } from "../auth/credential";
import { UserIdleService } from "angular-user-idle";
import { MatDialog } from "@angular/material";
import { UserIdleDialogComponent } from "../../content/shared/dialog/user-idle-dialog/user-idle-dialog.component";
import { finalize } from "rxjs/operators";
import { LayoutConfigService } from "./layout-config.service";
import { OptionValuePipe } from "../pipes/option-value.pipe";

@Injectable({
  providedIn: "root",
})
export class UserService {
  sessionChanged: BehaviorSubject<any> = new BehaviorSubject(this.getSession());
  private session: any;
  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService,
    private loadingService: LoadingDialogService,
    private router: Router,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    public layoutService: LayoutConfigService,
    private optionValuePipe: OptionValuePipe,
  ) { }

  // validateCurrentSession() {
  // 	console.log("validating sesh");

  // 	var initialUrl = window.location.href.replace(/^.*\/\/[^\/]+/, "");
  // 	if (localStorage.getItem("requiresSignout")) {
  // 		this.logout(true);
  // 		localStorage.removeItem("requiresSignout");
  // 		return;
  // 	}

  // 	Auth.currentAuthenticatedUser()
  // 		.then((user) => {
  // 			console.log("authed user === ", user);
  // 			var session = this.getSession();
  // 			console.log("sesh === ", user);

  // 			if (user.attributes.email_verified === false) {
  // 				console.log("invalid email");
  // 				this.logout();
  // 				return;
  // 			}
  // 			 else if (!this.getSession()) {
  // 				console.log("no sesh");
  // 				this.loginToMederev(user.attributes.email);
  // 				return;
  // 			} else if(this.getSession()) {
  // 				this.router.navigate([""]);
  // 			}
  // 		})
  // 		.catch((err) => {
  // 			console.log("unauth user === ", err);

  // 			// this.router.navigate(["/login"]);
  // 		});
  // }

  loginToMederev = (email) => {
    this.http
      .get<any>(config.apiUrl + "user/login/" + email)
      .subscribe((session: any) => {
        console.log('got user == ', session)
        if (session) {
          this.setSession(session);
          this.redirectUser()
          this.loadPermissions();
          this.loadingService.close.next();
        }
        else {
          this.loadingService.showCompletion(LoadingDialogState.Warning, 'Account Pending', 'OK', 'This account has not yet been activated by a Mederev representative.');
        }
      });
  };

  register = async (user) => {
    const cogResponse = await Auth.signUp({
      username: user.email.toLowerCase(),
      password: user.password,
      attributes: {
        email: user.email.toLowerCase()
      }
    });
    console.log('Cog response:', cogResponse);
    return this.http.post<any>(config.apiUrl + `user/signup`, { email: user.email }).toPromise();
  }

  confirmSignup = async (verifyForm) => {
      const cogResponse = await Auth.confirmSignUp( verifyForm.username, verifyForm.code );
      console.log('Cog response:', cogResponse);
      return this.http.post<any>(config.apiUrl + `user/signup/confirm`, { email: verifyForm.username }).toPromise();
  }

  resendVerificationCode = async (email) => {
    return Auth.resendSignUp(email);
  }

  //   createRoles(values, reRoute = false) {
  //     Object.keys(values).map(key => {
  //       if (values[key]) {
  //         this.permissionsService.addPermission(key);
  //       }
  //     })

  //     Object.keys(this.session).map(key => {
  //       if (this.session[key] && key !== 'currentPrivileges') {
  //         this.permissionsService.addPermission(key);
  //       }
  //     })

  //     this.roleService.addRole(sidebarRoles.sideBarCheck, [sidebarRoles.isPharmacist, sidebarRoles.hasGuidedFill])
  //     this.roleService.addRole(sidebarRoles.sideBarVPC, [sidebarRoles.editMedicationsInProfile, sidebarRoles.isVPCEnabled])

  //     this.roleService.addRole(sidebarRoles.sideBarOrders, [sidebarRoles.ordersMenu, sidebarRoles.isAgedCareOrdersEnabled]);
  //     this.roleService.addRole(sidebarRoles.sideBarChangeRequests, [sidebarRoles.changesMenu, sidebarRoles.isAgedCareOrdersEnabled]);

  //     this.roleService.addRole(sidebarRoles.sideBarCommunities, [sidebarRoles.communityMenuList, sidebarRoles.isCommunityPackingEnabled]);
  //     this.roleService.addRole(sidebarRoles.sideBarFacilities, [sidebarRoles.communityMenuList, sidebarRoles.isFacilityPackingEnabled]);

  //     if (this.getSession().isPharmacist) {
  //       this.permissionsService.addPermission('IS_PHARMACIST');
  //     }

  //     var permissions = this.permissionsService.getPermissions();
  //     var nextUrl;

  //     if (permissions.dashboard) {
  //       nextUrl = '/dashboard';
  //     } else if (permissions.ordersMenu) {
  //       nextUrl = '/orders';
  //     } else if (permissions.deliverReturnPacks) {
  //       nextUrl = '/deliver';
  //     } else {
  //       nextUrl = '/support';
  //     }
  //     this.router.navigate([nextUrl]);

  //     if (reRoute) {
  //       this.logoutChannel.postMessage({ type: 'login', url: nextUrl });
  //     }
  //   }

  submitSupportRequest = (values) =>
    this.http.post<any[]>(config.apiUrl + "home/supportRequest/", values);

  public login = (email, password) => from(Auth.signIn(email, password));

  public logout(postMessage = true): void {
    localStorage.setItem("session", null);
    localStorage.removeItem("requiresSignout");
    this.permissionsService.flushPermissions();

    Auth.signOut()
      .then((data) => {
        this.permissionsService.flushPermissions();
        this.router.navigate(["/login"]);
        if (postMessage) {
          //   this.logoutChannel.postMessage({ type: 'logout' });
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log("logout error = ", err);
      });
  }

  public requestPassword(email): Promise<any> {
    return Auth.forgotPassword(email);
  }

  checkMaintenance = () =>
    this.http.get<any[]>(config.apiUrl + "maintenance/check");

  getSession() {
    this.session = JSON.parse(localStorage.getItem("session"));
    if (this.session) {
      return this.session;
    } else {
      console.log("no session");
    }
  }

  setSession(session) {
    console.log(session.currentFacilityId)
    console.log('set session == ', session)
    localStorage.setItem("session", JSON.stringify(session));
    this.session = session;
    this.sessionChanged.next(this.session);
  }

  redirectUser = () => {
    const session = this.getSession()
    if (session) {
      // const type = this.optionValuePipe.transform('ALL_USER_TYPES', session.currentUserTypeId)
      // console.log("User Type:", type)
      switch (session.currentUserTypeId) {
        case 20: //'Practice Manager':
          this.http.get<any>(config.apiUrl + "practicestaff/view/login/find/" + session.currentUserId).subscribe((user: any) => {
            this.router.navigate([`/practice/${user.practiceId}`])
          });
          break
        case 21: //'Practice Receptionist':
          this.http.get<any>(config.apiUrl + "practicestaff/view/login/find/" + session.currentUserId).subscribe((user: any) => {
            this.router.navigate([`/practice/${user.practiceId}`])
          });
          break;
        case 22: //'Practice Doctor':
          this.router.navigate([`/doctor/${session.currentUserId}`])
          break
        case 2: //'Mederev Pharmacist':
          this.router.navigate([`/facility/${session.currentFacilityId}/dashboard`])
          break
        case 12: //'Facility Staff':
          this.router.navigate(['/facility-staff', session.currentUserId])
          break;
        case 13: //'Facility Receptionist':
          this.router.navigate(['/facility-staff', session.currentUserId])
          break;
        case 10: //'Facility Manager':
          this.router.navigate(['/facility-staff', session.currentUserId])
          break
        case 11: //'Clinical Facility Manager':
          this.router.navigate(['/facility-staff', session.currentUserId])
          break
        case 14: //'Clinical Educator/Quality User':
          this.router.navigate(['/report/training'])
          break
        case 1: //'Mederev Admin':
          this.router.navigate(['/scoop'])
          break
        case 3: //'Super User':
          this.router.navigate(['/scoop'])
          break
        default:
          this.router.navigate(['/facility', session.currentFacilityId, 'dashboard'])
          break
      }
    }
  }

  getUserType = () => {
    const session = this.getSession()
    if (session) {
      return this.optionValuePipe.transform('ALL_USER_TYPES', session.currentUserTypeId)
    }
    return null
  }

  loadPermissions = () => {
    console.log('loading permissions')
    const session = this.getSession();
    if (session) {
      this.permissionsService.loadPermissions(roles[session.currentUserTypeId]);
      console.log('Perms', this.permissionsService.getPermissions());
    }
  }
}
