import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-header-subtitle',
  templateUrl: './header-subtitle.component.html',
  styleUrls: ['./header-subtitle.component.scss']
})
export class HeaderSubtitleComponent implements OnInit {
  @Input() header: string;
  @Input() subtitle: string;
  @Input() translateHeader: string;
  @Input() translateSubtitle: string;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

  getHeader(){
    if (this.translateHeader){
      return this.translate.instant('TEXT.' + this.translateHeader);
    }
    if (this.header) {
      return this.header
    }
    return null;
  }

  getSubtitle(){
    if (this.translateSubtitle){
      return this.translate.instant('TEXT.' + this.translateSubtitle);
    }
    if (this.subtitle) {
      return this.subtitle
    }
    return null;
  }


}
