import {
	Component,
	OnInit,
	HostBinding,
	OnDestroy,
	ElementRef,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Renderer2,
	ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LayoutConfigService } from '../../../../../core/services/layout-config.service';
import * as objectPath from 'object-path';
import { Subscription } from 'rxjs';
import { QuickSearchDirective } from '../../../../../core/directives/quick-search.directive';
import { QuickSearchService } from '../../../../../core/services/quick-search.service';
import { DashboardService } from '../../../../../core/services/dashboard.service';
import { HeaderSubtitleComponent } from '../../../../shared/form/header-subtitle/header-subtitle.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoadingDialogService, LoadingDialogState } from '../../../../../core/services/loading-dialog.service';

@Component({
	selector: 'm-search-dropdown',
	templateUrl: './search-dropdown.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
	active = false;
	onSearch: Subscription;
	onLayoutConfigUpdated: Subscription;
	loading = false;

	@HostBinding('class') classes = '';
	@HostBinding('id') id = 'm_quicksearch';
	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';
	@HostBinding('attr.m-dropdown-persistent') attrDropdownPersistent = '1';
	@HostBinding('attr.m-quicksearch-mode') attrQuicksearchMode = 'dropdown';

	/**
	 * Hack way to call directive programatically for the host
	 * https://stackoverflow.com/questions/41298168/how-to-dynamically-add-a-directive
	 * The official feature support is still pending
	 * https://github.com/angular/angular/issues/8785
	 */
	@HostBinding('attr.mQuickSearch')
	mQuickSearchDirective: QuickSearchDirective;

	@ViewChild('searchWrapper') searchWrapper: ElementRef;

	searchText: string;

	results = [];

	constructor(
		private renderer: Renderer2,
		private layoutConfigService: LayoutConfigService,
		private el: ElementRef,
		private quickSearchService: QuickSearchService,
		private service: DashboardService,
		public translate: TranslateService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private loadingService: LoadingDialogService
	) {
		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			const config = model.config;

			this.classes =
				// tslint:disable-next-line:max-line-length
				'm-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light m-list-search m-list-search--skin-light';

			this.classes +=
				' m-dropdown--skin-' +
				objectPath.get(config, 'header.search.dropdown.skin');
		});

		this.renderer.listen('window', 'click', (e: Event) => {
			if (this.active) {
				let element = e.target;
				if (!this.searchWrapper.nativeElement.contains(element)) {
					if (this.active) {
						var closeButton = document.getElementById('m_quicksearch_close');
						if (closeButton) {
							closeButton.click();
						}
						this.active = false
						this.cdRef.detectChanges();
						// this.toggleActive();
					}
				} else {
				}
			}
		});

	}

	ngOnInit(): void { }

	ngOnDestroy() {
		this.onSearch.unsubscribe();
	}

	ngAfterViewInit(): void {
		Promise.resolve(null).then(() => {
			this.mQuickSearchDirective = new QuickSearchDirective(this.el);
			this.mQuickSearchDirective.ngAfterViewInit();

			// listen to search event
			this.onSearch = this.mQuickSearchDirective.onSearch$.subscribe(
				(mQuickSearch: any) => {
					this.loading = true;
					mQuickSearch.showProgress();

					this.quickSearchService.search({
						text: this.searchText,
						patientOnly: false
					}).subscribe(result => {
						this.loading = false;
						this.results = result;
						mQuickSearch.hideProgress();
						this.cdRef.detectChanges();
					});
				}
			);
		});
	}

	onResultClicked(result) {
		this.active = false;
		this.router.navigateByUrl('/', { queryParams: { skipStats: true, skipLocationChange: true }, }).then(() => {
			this.router.navigate([result.url]);
		});

		// if(result.url.includes('Facility')) {
		// 	this.router.navigate(['facilities/' + result.entityId])
		// }
		// if(result.url.includes('Profile')) {
		// 	this.router.navigate(['profile/' + result.entityId])
		// }
		// if(result.url.includes('Community')) {
		// 	this.router.navigate(['communities/' + result.entityId])
		// }
		// if(result.url.includes('HealthProfessional')) {
		// 	this.router.navigate(['healthProfessionals/' + result.entityId])
		// }


		this.results = [];
		this.searchText = '';
		// this.onCloseClick();
		var closeButton = document.getElementById('m_quicksearch_close');
		if (closeButton) {
			closeButton.click();
		}
	}

	onFind() {
		if (this.searchText && this.searchText.length > 0) {
			this.loadingService.showLoading('Loading...');
			this.service.find(this.searchText).subscribe(result => {
				this.searchText = '';
				if (result) {
					if (result.url) {
						this.router.navigate([result.url]);
					}
					else if (result.name) {
						this.loadingService.showCompletion(LoadingDialogState.Error, result.name, 'OK');
					} else {
						this.loadingService.close.next();
					}
				}
				else {
					this.loadingService.showCompletion(LoadingDialogState.Error, 'No result found', 'OK');
				}
			}, error => {

			})
		}
	}

	onOpen() {
		setTimeout(() => {
			console.log('setting active')
			this.loading = true;
			this.active = true;
			this.results = [];
			this.searchText = '';
		}, 100);
	}

	onCloseClick() {
		this.loading = true;
		this.results = [];
		this.searchText = '';
		this.active = false;
	}
}
