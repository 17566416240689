import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, from, throwError, of } from "rxjs";
import { UserService } from "./services/user.service";
import { Auth } from "aws-amplify";
import { switchMap, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoadingDialogState } from './services/loading-dialog.service';
import { ConfirmDialogComponent } from "../content/shared/dialog/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private router: Router,
	public dialog: MatDialog,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.includes('maintenance') || request.url.includes('signup')) {
      return next.handle(request);
    }
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        switchMap(user => {

          let session: string = this.userService.getSession() ? JSON.stringify(this.userService.getSession()) : '';

          var newRequest = request.clone({
            headers: new HttpHeaders({
              "session": session,
              'Authorization': `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            })
          });
          return next.handle(newRequest);
        })
        , catchError(error => {
          if(error.url.includes('CheckClaims')){
             this.showErrorMessage(error.error.message); //show popup here
          }
          if (typeof error === 'string' && error === 'not authenticated') {
            this.router.navigate(['/login']);
            return of();
          }

          if (error.error && error.error.message === 'MAINTENANCE_MODE') {
            this.userService.logout();
            return of();
          }

          throw new Error(error.message);
        })
      );
  }

  showErrorMessage(message){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: 'Attention',
				state: LoadingDialogState.Warning,
				message: message,
				cancelButtonText: "Cancel",
        		cancelButtonColour: "warn",
			},
			width: '500px',
		})

		dialogRef.afterClosed().subscribe(result => {

		});
  }
}
