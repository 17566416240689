import { Component, OnInit, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PackingValidationDialogComponent } from '../packing-validation-dialog/packing-validation-dialog.component';
import { IntegrationService } from '../../../../core/services/integration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'm-script-select-dialog',
  templateUrl: './script-select-dialog.component.html',
  styleUrls: ['./script-select-dialog.component.scss']
})
export class ScriptSelectDialogComponent implements OnInit {
  loading = false;
  script: any;
  dispenseScripts: any[];
  columns = ['dispenseDate', 'description', 'scriptStatus', 'scriptNumber', 'prescribedDate', 'quantity', 'repeats', 'isPBS', 'authorityNumber', 'action'];
  mappedScriptIds: string[] = [];
  hideSelection = true;
  includeOwing = false;

  @Output() onScriptSelect = new EventEmitter(); 

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ScriptSelectDialogComponent>,
    private integrationService: IntegrationService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {

    this.script = config.script;
    this.mappedScriptIds = config.mappedScriptIds;
    console.log('mapped scripts === ', this.mappedScriptIds)
    this.hideSelection = config.hideSelection;
    this.includeOwing = config.includeOwing;

    if (this.hideSelection) {
      this.columns.pop();
      this.getDispenseScripts();
    } else if (this.script.dispenseScripts.length === 0) {
      this.getDispenseScripts();
    } else {
      this.dispenseScripts = this.script.dispenseScripts.filter(s => !this.mappedScriptIds.includes(s.id))
    }
  }

  getDispenseScripts() {
    this.loading = true;
    this.integrationService.findScriptsByPatient(this.script.vendorPatientId, this.script.amtMedicinalProductPackId, this.includeOwing ? 1 : 0).subscribe((dispenseScripts: any[]) => {
      console.log("SCRIPT VALS === ", dispenseScripts);
      this.dispenseScripts = dispenseScripts.filter(s => !this.mappedScriptIds.includes(s.id))
      this.dispenseScripts.map(ds => {
        ds.repeatNumber = parseInt(ds.repeatNumber);
        ds.quantity = parseInt(ds.quantity);
      });
      this.cdRef.detectChanges();
    })
  }

  mapScript(dispenseScript) {
    if (this.includeOwing) {
      // var scripts = [dispenseScript, ...this.dispenseScripts.filter(ds => ds.mppSnomed === dispenseScript.mppSnomed && ds.scriptNumber !== dispenseScript.scriptNumber)];
      this.onScriptSelect.emit([dispenseScript]);
    } else {
      this.onScriptSelect.emit(dispenseScript);
    }
  }

  ngOnInit() {
  }

}
