export const errors = {
  numeric: 'Value must be numeric',
  required: 'Value is required',
  minlength: 'Value must be at least {{value}} characters long.',
  stringLength10: 'Value must be 10 characters',
  stringLength4: 'Value must be 4 characters long',
  url: 'Value must be a valid url',
  pattern: 'Please check email is valid and free of spaces.',
  faciityNameExists: 'The facility name already exists',
  faciityShortNameExists: 'The facility short-name already exists',
  email: 'Invalid Email',
  password: 'Passwords must contain an upper-case, lower-case, symbol and number',
  hasNumber: 'At least 1 number is required',
  hasUppercase: '1 Uppercase character',
  hasLowercase: '1 lowercase character',
  hasSpecialCharacter: '1 special character',
  passwordMatch: 'The passwords must match',
  minLength8: 'At least 8 characters',
  prescriberNumberExists: 'This prescriber number already exists',
  stringLength7: 'Value must be 7 characters',
  stringLength14: 'Value must be 14 characters',
  barcodeExists: 'The barcode already exists',
  medicare: 'The medicare number is invalid',
  prnDosageLess: 'The Quantity Packed must be greater than or equal to the PRN dosage',
  prnDosageMultiple: 'The Quantity Packed must be a multiple of the PRN dosage',
  prnDosageMore: 'The Quantity Packed must be less than or equal to the PRN dosage x 28',
  maxLength8: 'Max of 8 characters',
  monthlySetDates: 'At least one date is required',
  nonPackedFrequency: 'Must be greater than 0 and less than or equal to 12. Cannot include decimals.',
  noDecimal: 'Cannot include decimals',
  packRunExpiryDate: 'Pack run expiry date must be greater than pack run date',
  slotHeadings: 'Slot headings must be unique',
  maxLength365: 'Must be less than 365',
  maxLength100: 'Must be less than 100',
  stringLength6: 'Value must be 6 characters',
  reportEndDate: 'End date must be greater than start date',
  // matDatepickerParse: 'Invalid date',
  emailOrMobile: 'Invalid email or mobile',
  mobile: 'Invalid mobile - must 10 characters and start with 0',
  packingCreatePatients: 'At least 1 patient is required',
  minArray1: 'At least 1 option is required',
  slotQuantityGreaterThan: 'Must be less than 100',
  slotQuantityLessThan: 'Cannot be negative',
  slotQuantityDecimal: '0.25 increments only',
  greaterThanFiveHundred: 'Must be less than 500',
  lessThanZero: 'Cannot be negative',
  wholePacks: 'Whole packs only',
  wholeNumber: 'Must be whole number',
  medicareNumberExists: 'This medicare number already exists for another patient',
  endingSlot: 'End time must be later than the start time',
  endDate: 'Must be before start date',
  notBeforeStartDate: 'Cannot be before start date',
  notBeforeToday: 'Cannot be before todays date',
  matDatepickerParse: 'Invalid date, use format DD/MM/YYYY',
  dosageDays: 'Must be between 1 and 356',
  dosageMonths: 'Must be between 1 and 12',
  maxDecimalPlaces2: 'Maximum of 2 decimal places',
  invalidAbn: 'Invalid ABN',
  greaterThanPricing1: "Must be > tier 1 'To'",
  greaterThanPricing2: "Must be > tier 2 'To'",
  greaterThanPricing3: "Must be > tier 3 'To'",
  greaterThanZero: 'Must be > 0',
  slotQuantityNoDecimal: 'Must be whole number'
}